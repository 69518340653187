export class Golfwork {
    id: number;
    golf_id: number;
    name: string;
    name_short: string;
    type_cd: string;
    remarks: string;
    active: string;
    modified: string;
    constructor(){
        this.id = 0;
        this.golf_id = 0;
        this.name = '';
        this.name_short = '';
        this.type_cd = '10';
        this.remarks = '';
        this.active = '1';
        this.modified = '';
    }
}
