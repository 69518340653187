export class Request {
    id: number;
    golfwork_id: number;
    yyyymm: string;
    dd: string;
    cnt: number;
    remarks: string;
    active: string;
    modified: string;
    type_cd: string;
    constructor(){
        this.id = 0;
        this.golfwork_id = 0;
        this.yyyymm = '';
        this.dd = '';
        this.cnt = 1;
        this.remarks = '1';
        this.active = '1';
        this.modified = '';
    }
}
