import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UserService } from '../service/user.service';
import { AuthenticationService } from '../service/authentication.service';
import { User } from '../class/user';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { RequestViewComponent } from '../request-view/request-view.component';

@Component({
  selector: 'app-passchg',
  templateUrl: './passchg.component.html',
  styleUrls: ['./passchg.component.css'],
  providers: [UserService]
})
export class PasschgComponent implements OnInit {
  password = '';
  pwconfirm = '';

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'passchg');
    this.authenticationService.setDispName('パスワード変更');

    /*
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.userService.initUserList().then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.employees = result['data']['employees'];
        this.getUserList();
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
    */
  }
  update() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    user.password = this.password;
    this.userService.updatepass(user).then(result => {
      console.log(result);
      sessionStorage.setItem('user', JSON.stringify(user));
      this.alertService.success(SysMsg.getMsg('com000_007'), true);
      mw.close();
    }).catch(error => {
      mw.close();
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });
  }
}
