import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { AttendanceService } from '../service/attendance.service';
import { UserService } from '../service/user.service';
import { Attendance } from '../class/attendance';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attendance-mod',
  templateUrl: './attendance-mod.component.html',
  styleUrls: ['./attendance-mod.component.css'],
  providers: [AttendanceService]
})
export class AttendanceModComponent implements OnInit {
  data: wjcCore.CollectionView;
  currentItem = new Attendance();
  public actives: any;
  public actives_cb = [];
  public work_kbns: any;
  public work_kbns_cb = [];
  public user_flgs_cb = [{cd: '0', name: '無'}, {cd: '1', name: '有'},];
  user = [];

  constructor(
    private attendanceService: AttendanceService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private userService: UserService,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('screen_id', 'attendance-mod');
    this.currentItem.active = '1';
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.calc();
  }
/*
  async regist() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    let continued = true;
    if (this.currentItem.user_flg == '1') {
      // role 4　はゴルフ場
      await this.userService.add(this.currentItem.username, this.currentItem.password, 4).then(result => {
        console.log(result);
        this.currentItem.user_id = result['id'];
      }).catch(error => {
        // TODO message を表示
        console.log(error.status);
        continued = false;
      });
    }
    if(!continued) {
      mw.close();
      return;
    }
    this.attendanceService.add(this.currentItem).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.currentItem.id = result.id;
        this.currentItem.userinfo_id = result['userinfo_id'];
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });
  }
  */
  async update() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    let continued = true;
    console.log(this.currentItem);
    this.calc();
    this.attendanceService.update(this.currentItem).then(result => {
      if (result.msgcd=='S') {
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        // メッセージ送信
        let message = this.getMessageUpdated(this.currentItem);
        this.userService.sendMessageLine4Employee(this.currentItem.employee_id, message);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });

  }
  calc() {
    let diff = this.currentItem.ended.getTime() - this.currentItem.started.getTime()
    let hour = diff / (1000 * 60 * 60);
    let retVal = hour - this.currentItem.rest;
    this.currentItem.base = retVal;

  }
  getMessageUpdated(attendance: Attendance) {
    let ymd = attendance.yyyymm.slice(0,4) + '/' + attendance.yyyymm.slice(-2) + '/' + ('00' + attendance.dd).slice(-2);
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + '勤怠を更新';
  }

}
