import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { Request } from '../class/request';
import { Capacity } from '../class/capacity';
import { UtilityService } from './utility.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  initRequestList(golf_id): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('golf_id', golf_id)
    .set('screen_id', this.screen_id);

    /*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        requests: [
          {id: 1, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '01', cnt: 2, remarks: '', modified: '2020/11/01', active: '1'}, 
          {id: 2, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '02', cnt: 2, remarks: '', modified: '2020/11/05', active: '1'}, 
          {id: 3, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '03', cnt: 2, remarks: '', modified: '2020/11/06', active: '1'}, 
          {id: 4, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '03', cnt: 2, remarks: '', modified: '2020/11/08', active: '1'}, 
          {id: 5, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '01', cnt: 1, remarks: '', modified: '2020/11/01', active: '1'}, 
          {id: 6, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '05', cnt: 1, remarks: '', modified: '2020/11/05', active: '1'}, 
          {id: 7, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '06', cnt: 1, remarks: '', modified: '2020/11/06', active: '1'}, 
        ],
        actives: {'1': '1:活動', '0': '0:非活動'},
        type_cds: {'10': 'キャディ', '20': '作業'}
      };
      return resolve(result);
    });
    */
    return this.http.post(this.api_url + 'capi/request/initRequestList/' + golf_id, params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  getRequestList(golfwork_id, target_ym): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('target_ym', target_ym)
    .set('golfwork_id', golfwork_id)
    .set('screen_id', this.screen_id);

    /*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        requests: [
          {id: 1, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '01', cnt: 2, remarks: '', modified: '2020/11/01', active: '1'}, 
          {id: 2, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '02', cnt: 2, remarks: '', modified: '2020/11/05', active: '1'}, 
          {id: 3, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '03', cnt: 2, remarks: '', modified: '2020/11/06', active: '1'}, 
          {id: 4, golfwork_id: 1, type_cd: '10', yyyymm: '202012', dd: '03', cnt: 2, remarks: '', modified: '2020/11/08', active: '1'}, 
          {id: 5, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '01', cnt: 1, remarks: '', modified: '2020/11/01', active: '1'}, 
          {id: 6, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '05', cnt: 1, remarks: '', modified: '2020/11/05', active: '1'}, 
          {id: 7, golfwork_id: 1, type_cd: '20', yyyymm: '202012', dd: '06', cnt: 1, remarks: '', modified: '2020/11/06', active: '1'}, 
        ],
        actives: {'1': '1:活動', '0': '0:非活動'},
        type_cds: {'10': 'キャディ', '20': '作業'}
      };
      return resolve(result);
    });
    */
    return this.http.post(this.api_url + 'capi/request/getRequestList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  getSlectedAttendanceList(golf_id, target_ym): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('target_ym', target_ym)
    .set('golf_id', golf_id)
    .set('screen_id', this.screen_id);

    return this.http.post(this.api_url + 'capi/request/getSlectedAttendanceList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initRequestMod(golfwork_id): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('golfwork_id', golfwork_id)
    .set('screen_id', this.screen_id)
    .set('token', this.token);

    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
      };
      return resolve(result);
    });
    return this.http.post(this.api_url + 'requests/initRequestMod/' + golfwork_id, params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(request:Request): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('yyyymm', request.yyyymm)
      .set('dd', request.dd)
      .set('type_cd', request.type_cd)
      .set('golfwork_id', String(request.golfwork_id))
      .set('cnt', String(request.cnt))
      .set('remarks', request.remarks)
      .set('active', request.active)
      .set('login_user_id', this.user.id)
      .set('notificate_flg', request['notificate_flg'])
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/request/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
/*
  update(request:Request): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(request.id))
      .set('yyyymm', request.yyyymm)
      .set('dd', request.dd)
      .set('golfwork_id', String(request.golfwork_id))
      .set('active', request.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', request.modified)
      .set('screen_id', this.screen_id)
      .set('token', this.token);

      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = request.id;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
      return this.http.post(this.api_url + 'requests/updateforng/'+ String(request.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  */
  delete(request:Request): Promise<Result> {
    this.before();
    let params = new HttpParams()
      .set('id', String(request.id))
      .set('last_modified', request.modified)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = request.id;
        result.message = '削除しました。'
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/request/deleteforng/' + String(request.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
  addCapa(capacity: Capacity): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('golfwork_id', String(capacity.golfwork_id))
      .set('yyyymm', capacity.yyyymm)
      .set('dd', ('00' + capacity.dd).slice(-2))
      .set('capa_all', String(capacity.capa_all))
      .set('capa_caddy', String(capacity.capa_caddy))
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/request/addCapaforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  updateCapa(capacity: Capacity): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(capacity.id))
      .set('golfwork_id', String(capacity.golfwork_id))
      .set('yyyymm', capacity.yyyymm)
      .set('dd', capacity.dd)
      .set('capa_all', String(capacity.capa_all))
      .set('capa_caddy', String(capacity.capa_caddy))
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', capacity.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/request/updateCapaforng/' + String(capacity.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  addGolfEmplyee(golf_id:number, employee_id:number): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('golf_id', String(golf_id))
      .set('employee_id', String(employee_id))
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/golfEmployee/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  deleteGolfEmployee(id:number): Promise<Result> {
    this.before();
    let params = new HttpParams()
      .set('id', String(id))
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/golfEmployee/deleteforng/' + String(id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
}