import { Component, OnInit, Input } from '@angular/core';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-confirm-component',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {
  @Input() title;
  @Input() message;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
