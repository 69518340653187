export class Attendance {
    id: number;
    golfwork_id: number;
    employee_id: number;
    yyyymm: string;
    dd: string;
    available_flg: string;
    commit_flg: string;
    started: Date;
    ended: Date;
    other: number;
    base: number;
    rest: number;
    round: number;
    remarks: string;
    active: string;
    modified: string;
    golf_name: string;
        constructor(){
            this.id = 0;
            this.golfwork_id = 0;
            this.employee_id = 0;
            this.yyyymm = '';
            this.dd = '';
            this.available_flg = '1';
            this.commit_flg = '0';
            this.started = null;
            this.ended = null;
            this.round = 0;
            this.other = 0;
            this.base = 0;
            this.rest = 0;
            this.remarks = '';
            this.active = '1';
            this.modified = '';
            this.golf_name = '';
    }
}
