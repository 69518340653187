export class Memo {
    id: number;
    yyyymm: string;
    dd: string;
    detail: string;
    modified: string;
    constructor() {
        this.id = 0;
        this.yyyymm = '';
        this.dd = '';
        this.detail = '';
        this.modified = '';
    }
}
