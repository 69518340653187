<!-- a dialog for editing item details -->
<div class="modal-header">
    <h4 class="modal-title">出勤情報の<label>{{currentItem.id!=0?'編集':'登録'}}</label></h4>
  </div>
  <div class="modal-body">
    <fieldset>
      <form  #f="ngForm">
        <div class="form-group row">
            <label class="col-form-label col-12 lbl-bc">{{currentItem.dd}}日 {{currentItem.golf_name}}</label>
        </div>
        <div class="form-group row">
          <label for="vbase" class="col-form-label col-5 lbl-bc">労働時間</label>
          <wj-input-number [(value)]="currentItem.base" id="vbase" format="n2" [min]="0" [isReadOnly]="true"></wj-input-number>
        </div>
        <div class="form-group row">
          <label for="vstarted" class="col-form-label col-5 lbl-bc">出勤時間</label>
          <wj-input-time id="vstarted" [(value)]="currentItem.started" [format]="'t'" [step]="15" [isEditable]="true" [isRequired]="false" [min]="'06:30'" [max]="'10:00'" (valueChanged)="calc()"></wj-input-time>
        </div>
        <div class="form-group row">
            <label for="vended" class="col-form-label col-5 lbl-bc">退勤時間</label>
            <wj-input-time id="vended" [(value)]="currentItem.ended" [format]="'t'" [step]="15" [isEditable]="true" [isRequired]="false" [min]="'14:00'" (valueChanged)="calc()"></wj-input-time>
        </div>
        <div class="form-group row">
          <label for="vround" class="col-form-label col-5 lbl-bc">ラウンド数</label>
          <wj-input-number id="vround" [(value)]="currentItem.round" [format]="'n1'" [step]="0.5" [isRequired]="true" [min]="0" [max]="2.0"></wj-input-number>
        </div>
        <div class="form-group row">
          <label for="vother" class="col-form-label col-5 lbl-bc">所定外時間</label>
          <wj-input-number [(value)]="currentItem.other" id="vother" format="n2" [min]="0" [step]="0.25" (valueChanged)="calc()"></wj-input-number>
        </div>
        <div class="form-group row">
          <label for="vrest" class="col-form-label col-5 lbl-bc">休憩時間</label>
          <wj-input-number [(value)]="currentItem.rest" id="vrest" format="n2" [min]="0" [step]="0.25" (valueChanged)="calc()"></wj-input-number>
        </div>
        <div class="form-group row" [ngClass]="{'has-danger':vremarks.errors && vremarks.dirty}">
            <label for="vremarks" class="col-form-label col-5 lbl-bc">備考</label>
            <div class="col-12 col-xl-7 row">
              <input type="text" class="form-control" id="vremarks" name="vremarks" [(ngModel)]="currentItem.remarks" #vremarks="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="50"/>
            </div>
        </div>
      </form>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button *ngIf="currentItem.id==0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="regist()"><span class="oi oi-cloud-upload"></span>登録</button>
    <button *ngIf="currentItem.id!=0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="update()"><span class="oi oi-cloud-upload"></span>更新</button>
    <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('Cross click')"><span class="oi oi-action-undo"></span>キャンセル</button>
  </div>
