import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { GolfService } from '../service/golf.service';
import { AuthenticationService } from '../service/authentication.service';
import { Golf, GolfUser } from '../class/golf';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { GolfModComponent } from '../golf-mod/golf-mod.component';
import { GolfworkModComponent } from '../golfwork-mod/golfwork-mod.component';
import { isConstructorDeclaration } from 'typescript';


@Component({
  selector: 'app-golf-list',
  templateUrl: './golf-list.component.html',
  styleUrls: ['./golf-list.component.css']
})
export class GolfListComponent implements OnInit {
  public data: wjcCore.CollectionView;
  includeColumnHeader = true;
  currentItem: any;

  private actives: any;
  private actives_cb = [];
  public work_kbns: any;
  public work_kbns_cb = [];
  
  @ViewChild('filter') filter: wjcGridFilter.FlexGridFilter;
  // references FlexSheet named 'flexSheetIntro' in the view
  @ViewChild('flexGrid') flexGrid: wjcGrid.FlexGrid;
  @ViewChild('modalWait') modalWait: any;
  
  constructor(
    private authenticationService: AuthenticationService,
    private golfService: GolfService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone,
  ) {
    this.currentItem = new Golf();
  }

  ngOnInit() {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'golf-list');
    this.authenticationService.setDispName('ゴルフ場情報管理');
//    const mw = this.modalService.open(this.modalWait, {backdrop: 'static', keyboard: false});
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.golfService.initGolfList().then(result => {
      console.log(result);
      if (result.msgcd=='S') {
//        console.log(result['data']['golfs'] as Golf[]);
        this.actives = result['data']['actives'];
        for (var x in this.actives) {
          this.actives_cb.push({cd:String(x), name:this.actives[x]});
        }
        this.work_kbns = result['data']['work_kbns'];
        for (var x in this.work_kbns) {
          this.work_kbns_cb.push({cd:String(x), name:this.work_kbns[x]});
        }
        result['data']['datas'].forEach(element => {
          element['work_kbn_name'] = this.work_kbns[element.work_kbn];
          element['active_name'] = this.actives[element.active];
        });
        this.data = new wjcCore.CollectionView(result['data']['datas'] as GolfUser[]);
        this.currentItem = this.data.currentItem;
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  add() {
    this.data.addNew();
    this.currentItem = this.data.currentItem;
    this.currentItem.id = 0;
    this.currentItem.userinfo_id = 0;
    this.currentItem.user_id = '0';
    this.currentItem.work_kbn = '0';
    this.currentItem.active = '1';
    this.currentItem.user_flg = '0';
    let modalRef = this.modalService.open(GolfModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as GolfModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.work_kbns = this.work_kbns;
    component.work_kbns_cb = this.work_kbns_cb
    component.currentItem = this.currentItem;
    component.data = this.data;
    modalRef.result.then((result) => {
      console.log(result);
      this.currentItem = result;
      this.currentItem['work_kbn_name'] = this.work_kbns[this.currentItem.work_kbn];
      this.currentItem['active_name'] = this.actives[this.currentItem.active];

      this.data.commitNew();
      sessionStorage.setItem('screen_id', 'golf-list');
    }, (reason) => {
      this.data.cancelNew();
      sessionStorage.setItem('screen_id', 'golf-list');
    });
  }
  edit() {
    this.currentItem = this.data.currentItem;
    this.data.editItem(this.currentItem);
    let modalRef = this.modalService.open(GolfModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as GolfModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.work_kbns = this.work_kbns;
    component.work_kbns_cb = this.work_kbns_cb
    component.currentItem = this.currentItem;
    component.data = this.data;
    modalRef.result.then((result) => {
      this.currentItem = result;
      this.currentItem['work_kbn_name'] = this.work_kbns[this.currentItem.work_kbn];
      this.currentItem['active_name'] = this.actives[this.currentItem.active];
      this.data.commitEdit();
      sessionStorage.setItem('screen_id', 'golf-list');
    }, (reason) => {
      this.data.cancelEdit();
      sessionStorage.setItem('screen_id', 'golf-list');
    });
  }
  delete(item: any) {
    let ms = this.modalService.open(ModalConfirmComponent);
    ms.componentInstance.title = '確認';
    ms.componentInstance.message = SysMsg.getMsg('com000_002');
    ms.result.then((result) => {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.golfService.delete(item).then(result => {
        if (result.msgcd == 'S') {
          this.alertService.success(result.message, false);
          this.data.remove(item);
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    }, (reason) => {
    });
  }
  openGolfworkMod (){
    this.currentItem = this.data.currentItem;
    let modalRef = this.modalService.open(GolfworkModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as GolfworkModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.currentGolf = this.currentItem;
//    component.data = this.data;
    modalRef.result.then((result) => {
      sessionStorage.setItem('screen_id', 'golf-list');
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'golf-list');
    });
  }
  navLinkTo(id) {
    sessionStorage.setItem('golfListGolfId', String(id));
    this.ngZone.run(() => {
      this.router.navigate(['/request-list', id]);
    });
  }

  exportExcel() {
    wjcGridXlsx.FlexGridXlsxConverter.save(this.flexGrid, {
        includeColumnHeaders: this.includeColumnHeader,
        formatItem: function (args) {
        },
        includeCellStyles: false,
    }, 'ゴルフ場情報.xlsx');
  }
}
  