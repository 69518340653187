<!-- a dialog for editing item details -->
<div class="modal-header">
    <h4 class="modal-title">ゴルフ場情報の<label>{{currentItem.id!=0?'編集':'登録'}}</label></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <fieldset>
      <form  #f="ngForm">
        <div class="form-group row" [ngClass]="{'has-danger':vname.errors && vname.dirty}">
          <label for="vname" class="col-form-label col-3 lbl-bc">ゴルフ場名</label>
          <div class="col-8">
            <input type="text" class="form-control col-8" id="vname" name="vname" [(ngModel)]="currentItem.name" #vname="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="20" required/>
            <span style="color:red" [hidden]="!vname.dirty || (!vname.errors?.maxlength)">20文字以内で入力してください</span>
          </div>
        </div>
        <div class="form-group row" [ngClass]="{'has-danger':vname_short.errors && vname_short.dirty}">
          <label for="vname_short" class="col-form-label col-3 lbl-bc">ゴルフ場名（略称）</label>
          <div class="col-8">
            <input type="text" class="form-control col-8" id="vname_short" name="vname_short" [(ngModel)]="currentItem.name_short" #vname_short="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="20" required/>
            <span style="color:red" [hidden]="!vname_short.dirty || (!vname_short.errors?.maxlength)">20文字以内で入力してください</span>
          </div>
        </div>
        <div class="form-group row d-flex align-items-center pull-left">
          <label for="vname_short" class="col-form-label col-3" [style.background]="theColorPicker.value">背景色</label>
          <wj-color-picker #theColorPicker [(value)]="currentItem.bgcolor" [showAlphaChannel]="true" [showColorString]="true"></wj-color-picker>
        </div>
        <div class="form-group row">
          <label for="vwork_kbn" class="col-form-label col-3 lbl-bc">作業区分</label>
          <div class="btn-group btn-group-toggle col-3" ngbRadioGroup name="radioWorkKbn" [(ngModel)]="currentItem.work_kbn">
            <label ngbButtonLabel [ngClass]="{'btn-primary':currentItem.work_kbn==a.cd,'btn-outline-primary':currentItem.work_kbn!=a.cd}" *ngFor="let a of work_kbns_cb">
              <input ngbButton type="radio" [value]="a.cd"> {{a.name}}
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label for="vactive" class="col-form-label col-3 lbl-bc">活動区分</label>
          <div class="btn-group btn-group-toggle col-3" ngbRadioGroup name="radioBasic" [(ngModel)]="currentItem.active">
            <label ngbButtonLabel [ngClass]="{'btn-primary':currentItem.active==a.cd,'btn-outline-primary':currentItem.active!=a.cd}" *ngFor="let a of actives_cb">
              <input ngbButton type="radio" [value]="a.cd"> {{a.name}}
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label for="vactive" class="col-form-label col-3 lbl-bc">ユーザー情報</label>
          <div class="btn-group btn-group-toggle col-3" ngbRadioGroup name="radioUserFlg" [(ngModel)]="currentItem.user_flg">
            <label ngbButtonLabel [ngClass]="{'btn-primary':currentItem.user_flg==a.cd,'btn-outline-primary':currentItem.user_flg!=a.cd}" *ngFor="let a of user_flgs_cb">
              <input ngbButton type="radio" [value]="a.cd"> {{a.name}}
            </label>
          </div>
        </div>
        <div *ngIf="currentItem.user_flg=='1'" class="form-group row" [ngClass]="{'has-danger':vusername.errors && vusername.dirty}">
            <label for="vusername" class="col-form-label col-3 lbl-bc">ユーザー名</label>
            <div class="col-9">
              <input type="text" class="form-control col-8" id="vusername" name="vusername" [(ngModel)]="currentItem.username" #vusername="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="50" [disabled]="currentItem.user_id>0" required/>
              <span style="color:red" [hidden]="!vusername.dirty || (!vusername.errors?.pattern)">xxx@xxx.xxx形式で入力してください</span>
            </div>
        </div>
        <div *ngIf="currentItem.user_flg=='1' && currentItem.user_id==0" class="form-group row" [ngClass]="{'has-danger':vpassword.errors && vpassword.dirty}">
            <label for="vpassword" class="col-form-label col-3 lbl-bc">パスワード</label>
            <div class="col-9">
              <input type="password" class="form-control col-8" id="vpassword" name="vpassword" [(ngModel)]="currentItem.password" #vpassword="ngModel" (ngModelOptions)="{updateOn: 'blur'}" minlength="5"  pattern="[A-Za-z0-9]+" required/>
              <span style="color:red" [hidden]="!vpassword.dirty || (!vpassword.errors?.minlength)">5文字以上で入力してください</span>
              <span style="color:red" [hidden]="!vpassword.dirty || (!vpassword.errors?.pattern)">半角英数字で入力してください</span>
            </div>
        </div>
        <div *ngIf="currentItem.user_flg=='1'" class="form-group row" [ngClass]="{'has-danger':vemail.errors && vemail.dirty}">
            <label for="vemail" class="col-form-label col-3 lbl-bc">メールアドレス</label>
            <div class="col-9">
              <input type="text" class="form-control col-8" id="vemail" name="vemail" [(ngModel)]="currentItem.email" #vemail="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="50"/>
              <span style="color:red" [hidden]="!vemail.dirty || (!vemail.errors?.pattern)">xxx@xxx.xxx形式で入力してください</span>
            </div>
        </div>
        <div *ngIf="currentItem.user_flg=='1'" class="form-group row" [ngClass]="{'has-danger':vtoken.errors && vtoken.dirty}">
            <label for="vtoken" class="col-form-label col-3 lbl-bc">LINEトークン</label>
            <div class="col-9">
              <input type="text" class="form-control col-8" id="vtoken" name="vtoken" [(ngModel)]="currentItem.token" #vtoken="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="50"/>
            </div>
        </div>
      </form>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button *ngIf="currentItem.id==0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="regist()"><span class="oi oi-cloud-upload"></span>登録</button>
    <button *ngIf="currentItem.id!=0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="update()"><span class="oi oi-cloud-upload"></span>更新</button>
    <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('Cross click')"><span class="oi oi-action-undo"></span>キャンセル</button>
  </div>
