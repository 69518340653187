<div class="row">
    <div class="col-12">
        <div style="text-align: center;">
            <button class="btn btn-primary" style="float: left;" (click)="prevYm()"><span class="oi oi-arrow-left"></span></button>
            <label class="" style="font-size: xx-large;">{{dispTargetYm}}</label>
            <button class="btn btn-primary" style="float: right;" (click)="nextYm()"><span class="oi oi-arrow-right"></span></button>
        </div>
        <div class="table-responsive-lg">
<!--            <wj-combo-box id="vgolfwork_id" name="vgolfwork_id" [displayMemberPath]="'name'" [selectedValuePath]="'id'"  [(ngModel)]="golfwork_id" #vgolfwork_id="ngModel" [itemsSource]="golfworks" [isEditable]="false" (selectedIndexChanged)="changeGolfwork()">
            </wj-combo-box>-->
            {{golfwork_name}}
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col"  [class.btn-primary]="week.week_cd=='Sat'" [class.btn-danger]="week.week_cd=='Sun'" *ngFor="let week of weeks">{{week.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let targets of calendar">
                        <td style="border-color: black;text-align: center;background-color: black;" *ngFor="let target of targets">
                            <div class="w-100" [class.btn-secondary]="weeks2[(target.ymd | date:'d')]!=6&&weeks2[(target.ymd | date:'d')]!=0" [class.btn-primary]="weeks2[(target.ymd | date:'d') - 1]==6" [class.btn-danger]="weeks2[(target.ymd | date:'d') - 1]==0">{{target.ymd | date:'dd'}}</div>
                            <div class="btn-light w-100" *ngIf="capacity_sums[(target.ymd | date:'d')]">全<input style="padding-left: 10px;" class="col-7" [class.bg-warning]="capacity_sums[(target.d)]['capa_all']>=30" [class.bg-danger]="capacity_sums[(target.d)]['capa_all']>=40" type="text" [(ngModel)]="capacity_sums[(target.d)]['capa_all']">組</div>
                            <div class="btn-light w-100" *ngIf="capacity_sums[(target.ymd | date:'d')]">キ<input style="padding-left: 10px;" class="col-7" [class.bg-warning]="capacity_sums[(target.d)]['capa_caddy']>=30" [class.bg-danger]="capacity_sums[(target.d)]['capa_caddy']>=40" type="text" [(ngModel)]="capacity_sums[(target.d)]['capa_caddy']">組</div>
                            <div 
                             class="w-100"
                             [class.btn-danger]="request_sums[(target.ymd | date:'d')]['cnt']>=5" 
                             [class.btn-warning]="request_sums[(target.ymd | date:'d')]['cnt']==3||request_sums[(target.ymd | date:'d')]['cnt']==4" 
                             [class.btn-primary]="request_sums[(target.ymd | date:'d')]['cnt']==1||request_sums[(target.ymd | date:'d')]['cnt']==2" 
                             [class.btn-secondary]="request_sums[(target.ymd | date:'d')]['cnt']==0" 
                             *ngIf="request_sums[(target.ymd | date:'d')]"
                             >ｵﾌｧｰ　{{request_sums[(target.ymd | date:'d')]['cnt']}}
                            </div>
                            <div 
                             class="w-100"
                             [class.btn-danger]="work_sums[(target.ymd | date:'d')]['cnt']>=5" 
                             [class.btn-warning]="work_sums[(target.ymd | date:'d')]['cnt']==3||work_sums[(target.ymd | date:'d')]['cnt']==4" 
                             [class.btn-primary]="work_sums[(target.ymd | date:'d')]['cnt']==1||work_sums[(target.ymd | date:'d')]['cnt']==2" 
                             [class.btn-secondary]="work_sums[(target.ymd | date:'d')]['cnt']==0" 
                             *ngIf="work_sums[(target.ymd | date:'d')]"
                             >作業　{{work_sums[(target.ymd | date:'d')]['cnt']}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12">
        <div class="table-responsive-lg">
            <label>絞込【依頼日】</label><wj-combo-box id="vpDd" name="vpDd" [(ngModel)]="pDd" #vpDd="ngModel" [itemsSource]="dds" [isEditable]="false"></wj-combo-box>
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-info" colspan="9" style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" >依頼一覧</th>
                    </tr>
                    <tr>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            受付日
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            依頼日
                        </th>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                            種類
                        </th>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                            依頼数
                        </th>
                        <th class="btn-secondary" style="width: 20%;vertical-align: middle;border-color: black;text-align: center;">
                            備考
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let target of requests | filter:'dd': pDd;">
                        <td style="border-color: black;text-align: center;">
                            {{target.modified}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.yyyymm}}/{{target.dd}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{type_cds[target.type_cd]}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.cnt}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.remarks}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
