import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AclGuard } from './guard/acl.guard';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasschgComponent } from './passchg/passchg.component';

import { GolfListComponent } from './golf-list/golf-list.component';
import { GolfModComponent } from './golf-mod/golf-mod.component';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { AttendanceDetailComponent } from './attendance-detail/attendance-detail.component';
import { RequestListComponent } from './request-list/request-list.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeModComponent } from './employee-mod/employee-mod.component';

const routes: Routes = [
  {path: '', redirectTo: '/golf-list', pathMatch: 'full', canActivate: [AclGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'passchg', component: PasschgComponent},
  {path: 'golf-list', component: GolfListComponent, canActivate: [AclGuard]},
  {path: 'employee-list', component: EmployeeListComponent, canActivate: [AclGuard]},
  {path: 'attendance-list', component: AttendanceListComponent, canActivate: [AclGuard]},
  {path: 'attendance-detail/:id', component: AttendanceDetailComponent, canActivate: [AclGuard]},
  {path: 'request-list/:id', component: RequestListComponent, canActivate: [AclGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
