import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Screen } from '../class/screen'

@Injectable()
export class AclGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (sessionStorage.getItem('screens')) {
        let screens = JSON.parse(sessionStorage.getItem('screens')) as Screen[];
        let filtered = screens.filter(function (value,index,items) {
          return (state.url.indexOf(value.screen_id)>-1)
        });

        if (filtered.length>0) {
          sessionStorage.setItem('stateUrl', state.url)
          return true;
        }
      }
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url}})
      return false;
  }
}
