import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/map'
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { Result } from '../class/result';

@Injectable()
export class AuthenticationService {
  private menu = new Subject<any>();
  private user = new Subject<any>();
  private dispName = new Subject<any>();
  
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(private http: HttpClient, @Inject('ApiUrl') private api_url: string, private alertService: AlertService) {   }
  login(username: string, password: string) {
    let params = new HttpParams()
      .set('username', username)
      .set('password', password);
    let param = {
      username: username,
      password: password
    };
    let headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('Content-Type', 'application/json');
    return this.http.post(this.api_url + 'api/auth', JSON.stringify(param), {headers:headers})
      .toPromise()
      .then(async (response: Response) => {
        console.log(response);
        let token = response['token'];
        let headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', 'Bearer ' + response['token']);
        return await this.http.post(this.api_url + 'capi/user/getUserInfo', params, {headers:headers})
          .toPromise()
          .then((response: Response) =>{
            console.log(response);
            if (response) {
              const msgcd = response['msgcd'];
              if (msgcd === 'SUC') {
                response['user']['password'] = password;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('screens', JSON.stringify(response['screens']));
                sessionStorage.setItem('menu', JSON.stringify(response['menu']));
                sessionStorage.setItem('user', JSON.stringify(response['user']));
                this.menu.next({'menu': response['menu']});
                this.user.next({'user': response['user']});
              } else {
                this.alertService.error('ユーザー名またはパスワードが間違っています', true);
              }
              return response;
            }
        });
      })
    .catch(this.handleError);
  }
  loginAgain(): Promise<Result> {
    let user = JSON.parse(sessionStorage.getItem('user'));

    let params = new HttpParams()
      .set('username', user.username)
      .set('password', user.password);
    let param = {
      username: user.username,
      password: user.password
    };
    let headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('Content-Type', 'application/json');
    return this.http.post(this.api_url + 'api/auth', JSON.stringify(param), {headers:headers})
      .toPromise()
      .then(async (response: Response) => {
        let token = response['token'];
        sessionStorage.setItem('token', token);
        console.log('after set');
        return response;
/*
        let headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', 'Bearer ' + response['token']);
        await this.http.post(this.api_url + 'capi/user/getUserInfo', params, {headers:headers})
          .toPromise()
          .then((response: Response) =>{
            console.log(response);
            if (response) {
              const msgcd = response['msgcd'];
              if (msgcd === 'SUC') {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('screens', JSON.stringify(response['screens']));
                sessionStorage.setItem('menu', JSON.stringify(response['menu']));
                sessionStorage.setItem('user', JSON.stringify(response['user']));
                this.menu.next({'menu': response['menu']});
                this.user.next({'user': response['user']});
              } else {
                this.alertService.error('ユーザー名またはパスワードが間違っています', true);
              }
            }
        });
*/
      })
    .catch(this.handleError);
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('menu');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('screens');
    this.menu.next({'menu': []});
    this.user.next({'user': null});
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }
  getMenu(): Observable<any> {
    return this.menu.asObservable();
  }
  getUser(): Observable<any> {
    return this.user.asObservable();
  }
  setDispName(dispName:string) {
    this.dispName.next({'dispName': dispName});
  }
  getDispName(): Observable<any> {
    return this.dispName.asObservable();
  }

}
