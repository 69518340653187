import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { GolfworkService } from '../service/golfwork.service';
import { AuthenticationService } from '../service/authentication.service';
import { Golfwork } from '../class/golfwork';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'

@Component({
  selector: 'app-golfwork-mod',
  templateUrl: './golfwork-mod.component.html',
  styleUrls: ['./golfwork-mod.component.css']
})
export class GolfworkModComponent implements OnInit {
  public data: wjcCore.CollectionView = new wjcCore.CollectionView();
  includeColumnHeader = true;
  currentGolf: any;
  currentItem: any;
  newItem = new Golfwork();
  public actives: any;
  public actives_cb = [];
  loading = false;
  
  @ViewChild('filter') filter: wjcGridFilter.FlexGridFilter;
  // references FlexSheet named 'flexSheetIntro' in the view
  @ViewChild('flexGrid') flexGrid: wjcGrid.FlexGrid;
  @ViewChild('modalWait') modalWait: any;
  
  constructor(
    private authenticationService: AuthenticationService,
    private golfworkService: GolfworkService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    this.currentItem = new Golfwork();
  }

  ngOnInit() {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'golfwork-mod');
    this.authenticationService.setDispName('ゴルフ場別コース管理');
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.golfworkService.initGolfworkMod(this.currentGolf.id).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        console.log(this.actives_cb);
        let golfworks = result['data']['datas'] as Golfwork[];
        golfworks.forEach(element => {
          element['active_name'] = this.actives[element['active']];
        });
        this.data = new wjcCore.CollectionView(golfworks);
        this.currentItem = this.data.currentItem;
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        });        
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      }
    });
  }
  add() {
    this.data.addNew();
    this.currentItem = this.data.currentItem;
    this.currentItem.id = 0;
    this.currentItem.active = '1';
    this.currentItem.name = this.newItem.name;
    this.currentItem.type_cd = '10';
    this.currentItem.golf_id = this.currentGolf.id;
    this.currentItem.remarks = this.newItem.remarks;
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.golfworkService.add(this.currentItem as Golfwork).then(result => {
      if (result.msgcd == 'S') {
        this.alertService.success(result.message, false);
        this.currentItem.id = result['id'];
        this.currentItem.active_name = this.actives[this.currentItem.active];
        this.currentItem.modified = result['modified'];
        this.data.commitNew;
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  edit(content) {
    this.currentItem = this.data.currentItem;
    this.data.editItem(this.currentItem);
    this.modalService.open(content, {size: 'lg'}).result.then((result) => {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.golfworkService.update(this.currentItem as Golfwork).then(result => {
        if (result.msgcd == 'S') {
          this.alertService.success(result.message, false);
          this.currentItem.active_name = this.actives[this.currentItem.active];
//          this.currentItem.modified = result['modified'];
          this.data.commitEdit;
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    }, (reason) => {
    });
  }
  delete(item: any) {
    let ms = this.modalService.open(ModalConfirmComponent);
    ms.componentInstance.title = '確認';
    ms.componentInstance.message = SysMsg.getMsg('com000_002');
    ms.result.then((result) => {
      this.loading = true;
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.golfworkService.delete(item as Golfwork).then(result => {
        if (result.msgcd == 'S') {
          this.alertService.success(result.message, false);
          this.data.remove(item);
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    }, (reason) => {
    });
  }
  exportExcel() {
    wjcGridXlsx.FlexGridXlsxConverter.save(this.flexGrid, {
        includeColumnHeaders: this.includeColumnHeader,
        formatItem: function (args) {
        },
        includeCellStyles: false,
    }, 'ゴルフ場情報.xlsx');
  }
}
  