<div style="text-align: center;">
    <button class="btn btn-primary" style="float: left;" (click)="prevYm()"><span class="oi oi-arrow-left"></span></button>
    <label class="" style="font-size: xx-large;">{{dispTargetYm}}</label>
    <button class="btn btn-primary" style="float: right;" (click)="nextYm()"><span class="oi oi-arrow-right"></span></button>
</div>
<div cdkDropListGroup class="sticky_table_wrapper">
    <table class="table-bordered sticky_table">
        <thead>
            <tr>
                <th style="width: 10%;"></th>
                <th style="width: 45px;" [class.day]="weeks[day]<6" [class.bg-primary]="weeks[day]==6" [class.bg-danger]="weeks[day]==0" style="width: 45px;text-align: center;" *ngFor="let day of days">{{day}}</th>
            </tr>
            <tr>
                <td>
                </td>
                <td *ngFor="let day of days">
                    <div
                        class="w-100"
                        [class.btn-secondary]="memos[day]?.id>0" 
                        style="text-align: center;"
                        (click)="editMemo(memos[day])">
                        <span class="oi oi-pencil"></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div
                        class="w-100"
                        [class.btn-danger]="request_sum_all>attendance_sum_all" 
                        [class.btn-secondary]="request_sum_all==attendance_sum_all" 
                        style="text-align: center;">
                        {{attendance_sum_all}}
                    </div>
                    <div
                        class="w-100"
                        [class.btn-danger]="request_sum_all>=5" 
                        [class.btn-warning]="request_sum_all==3||request_sum_all==4" 
                        [class.btn-primary]="request_sum_all==1||request_sum_all==2" 
                        [class.btn-secondary]="request_sum_all==0" 
                        style="text-align: center;">
                        {{request_sum_all}}
                    </div>
                </td>
                <td *ngFor="let day of days">
                    <div
                        class="w-100"
                        [class.btn-danger]="sumByDay[day]?.request>sumByDay[day]?.attendance" 
                        [class.btn-secondary]="sumByDay[day]?.request==sumByDay[day]?.attendance" 
                        style="text-align: center;">
                        {{sumByDay[day]?.attendance}}
                    </div>
                    <div
                        class="w-100"
                        [class.btn-danger]="sumByDay[day]?.request>=1" 
                        [class.btn-secondary]="sumByDay[day]?.request==0" 
                        style="text-align: center;">
                        {{sumByDay[day]?.request}}
                    </div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let golfwork of golfworks">
                <th [style.background-color]="golfwork.bgcolor">
                    <div>{{golfwork.golf_name_short}}{{golfwork.name}}</div>
                        <div
                        class="w-100"
                        [class.btn-danger]="golfwork.request_sum>golfwork.attendance_sum" 
                        [class.btn-secondary]="golfwork.request_sum==golfwork.attendance_sum" 
                        style="text-align: center;">
                        {{golfwork.attendance_sum}}
                    </div>
                    <div
                        class="w-100"
                        [class.btn-danger]="golfwork.request_sum>=5" 
                        [class.btn-warning]="golfwork.request_sum==3||golfwork.request_sum==4" 
                        [class.btn-primary]="golfwork.request_sum==1||golfwork.request_sum==2" 
                        [class.btn-secondary]="golfwork.request_sum==0" 
                        style="text-align: center;"
                        (click)="openRequest(golfwork, day)">
                        {{golfwork.request_sum}}
                    </div>
                </th>
                <td *ngFor="let day of days" style="vertical-align: bottom;" [style.background-color]="golfwork.bgcolor">
                    <div
                    cdkDropList
                    [cdkDropListData]="attendances[golfwork.id][day]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event, golfwork.id, day)">
                        <div class="example-box" *ngFor="let item of attendances[golfwork.id][day]"
                        data-toggle="tooltip" data-placement="top" title="{{item.name + '\n' + item.remarks}}"
                        [class.commit]="item.commit_flg=='1'" 
                        [class.no-commit]="item.commit_flg=='0'" 
                        [class.remarks]="item.remarks!=''"
                        (dblclick)="openEmployee(item)"
                        >
                            {{item.name_short}}<span style="background-color: brown;color: white;font-weight: bold;padding: 0 3px 0 3px;border-radius: 5px;" ><p style="margin-bottom: 0;" (click)="delAttendance(item, golfwork.id, day)">×</p></span>
                        </div>
                    </div>
                    <div
                    class="w-100"
                    [class.btn-danger]="requests[golfwork.id]?.day>attendances[golfwork.id][day]?.length"
                    [class.btn-secondary]="requests[golfwork.id]?.day==attendances[golfwork.id][day]?.length" 
                    style="text-align: center;"
                    (click)="openEmployeeSelect(attendances[golfwork.id][day], golfwork, requests[golfwork.id][day], day)">
                        {{attendances[golfwork.id][day]?.length}}
                    </div>
                    <div
                    class="w-100"
                    [class.btn-danger]="requests[golfwork.id][day]>=1" 
                    [class.btn-secondary]="requests[golfwork.id][day]==0" 
                    style="text-align: center;"
                    (click)="openRequest(golfwork, day)">
                        {{requests[golfwork.id][day]}}
                    </div>
                    <div
                    *ngIf="capacitys[golfwork.id]"
                    class="w-100 commit"
                    style="text-align: center;">
                        {{capacitys[golfwork.id][day]}}
                    </div>
                </td>
            </tr>
            <tr></tr>
            <tr *ngFor="let e_attendance of e_attendances">
                <th>
                    <div>{{e_attendance.name}}</div>
                        <div
                        class="w-100"
                        style="text-align: center;">
                        {{e_attendance.cnt}}
                    </div>
                </th>
                <td *ngFor="let day of days" style="vertical-align: middle;" [class.btn-danger]="e_attendance[day]&&e_attendance[day]['available_flg']=='0'">
                    <div
                        [style.background-color]="getGolfColor(e_attendance[day]['golfwork_id'])"
                        class="w-100"
                        style="text-align: center;" *ngIf="e_attendance[day]">
                        {{getGolfName(e_attendance[day]['golfwork_id'])}}
                    </div>
                </td>
            </tr>

        </tbody>
    </table>
    <div class="example-container">
        <div
         cdkDropList
         [cdkDropListData]="employees"
         cdkDropListSortingDisabled
         class="example-list">
            <div class="example-box" style="color: white;" *ngFor="let item of employees"
             data-toggle="tooltip" data-placement="top" title="{{item.name}}"
             [class.bg-success]="item" 
             (dblclick)="openEmployee(item)"
             cdkDrag>{{item.name_short}}</div>
        </div>
    </div>
</div>