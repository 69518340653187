import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertService } from '../service/alert.service';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    message: IAlert = {type: '', text: ''};

    constructor(
        private alertService: AlertService
        ,private changeDetectorRef: ChangeDetectorRef
    ) {
        this.message = {type: '', text: ''};
    }

    ngOnInit() {
        this.alertService.getMessage().subscribe(message => { this.message = message;});
    }
    closeAlert() {
        this.message = undefined;
        this.changeDetectorRef.detectChanges();
    }

}
export interface IAlert {
    type: string;
    text: string;
}
