import { UtilityService } from '../service/utility.service';

export class Wareki {
    g: string;
    g_name: string;
    yy: string;
    mm: string;
    dd: string;
    constructor() {
        this.dd = '';
        this.mm = '';
        this.g = '';
        this.g_name = '';
        this.yy = '';
    }
}
