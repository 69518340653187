import { Component, ViewChild } from '@angular/core';
import { Router }    from '@angular/router';
import * as wjcNav from '@grapecity/wijmo.nav';
import * as wjCore from '@grapecity/wijmo';
import { WjNavModule, WjTreeView } from '@grapecity/wijmo.angular2legacy.nav';

import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'g1japan';
  dispName = '';
  items = [];
  user = null;
  chkSidebarMenu = false;

  @ViewChild('theTree') theTree: WjTreeView;

  constructor(
    private router: Router, 
    private authenticationService: AuthenticationService
  ) {
    let menu = sessionStorage.getItem('menu');
    if (menu) {
      this.items = JSON.parse(sessionStorage.getItem('menu'));
      console.log(this.items);
    }
    this.user = JSON.parse(sessionStorage.getItem('user'));
/*    this.items = [
      { header: 'トップ', link:'/dashboard' },
      { header: 'サーバー一覧', link:'/server-list' },
      {header: 'ユーザー管理', items: [
        { header: 'ユーザー登録', link:'/usr101' },
        { header: 'ユーザー一覧', link:'/usr102' },
      ]},
    ];*/
  }
  ngOnInit() {
    this.authenticationService.getMenu().subscribe(
      result => {
         this.items = result.menu;
        }
      );
    this.authenticationService.getUser().subscribe(
      result => {
          this.user = result.user;
        }
      );
    this.authenticationService.getDispName().subscribe(
      result => {
        setTimeout(() => {
          this.dispName = result.dispName;
        });
      }
    )
    let checked = true;
//    wjCore.toggleClass(this.theTree.hostElement, 'accordion');

  }
  navTo(treeView: wjcNav.TreeView) {
    let link = [treeView.selectedItem.link];
    if (link[0]=='') {
      return;
    }
    this.router.navigate(link);
    this.chkSidebarMenu = false;
  }
  navLinkTo(link:any) {
    console.log(this.chkSidebarMenu);
    this.router.navigate([link]);
    this.chkSidebarMenu = false;

  }
}
