import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { Employee, EmployeeUser } from '../class/employee';
import { User } from '../class/user';
import { UtilityService } from './utility.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  initEmployeeList(): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('screen_id', this.screen_id);

    /*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        employees: [
          {id: 1, name: '井上　圭', position_cd: '10', user_id: 0, active: 1, modified: '2020/11/22 18:34:00'},
          {id: 2, name: '山田　太郎', position_cd: '10', user_id: 0, active: 1, modified: '2020/11/23 18:34:00'},
          {id: 3, name: '佐藤　次郎', position_cd: '20', user_id: 0, active: 1, modified: '2020/11/23 18:34:00'},
          {id: 4, name: '鈴木　三郎', position_cd: '30', user_id: 0, active: 1, modified: '2020/11/23 18:34:00'},
        ],
        actives: {'1': '1:活動', '0': '0:非活動'}
      };
      return resolve(result);
    });
    */
    return this.http.post(this.api_url + 'capi/employee/initEmployeeList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(employee:EmployeeUser): Promise<Result> {
    this.before();

    let modified = new Date();
    let params = new HttpParams()
      .set('name', employee.name)
      .set('name_short', employee.name_short)
      .set('user_id', String(employee.user_id))
      .set('userinfo_id', String(employee.userinfo_id))
      .set('user_flg', employee.user_flg)
      .set('email', employee.email)
      .set('token', employee.token)
      .set('active', employee.active)
//      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = 6;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/employee/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(employee:EmployeeUser): Promise<Result> {
    this.before();

    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(employee.id))
      .set('name', employee.name)
      .set('name_short', employee.name_short)
      .set('user_id', String(employee.user_id))
      .set('user_flg', employee.user_flg)
      .set('userinfo_id', String(employee.userinfo_id))
      .set('email', employee.email)
      .set('token', employee.token)
      .set('active', employee.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', employee.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = employee.id;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/employee/updateforng/'+ String(employee.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  delete(employee:EmployeeUser): Promise<Result> {
    let params = new HttpParams()
      .set('id', String(employee.id))
      .set('user_id', String(employee.user_id))
      .set('userinfo_id', String(employee.userinfo_id))
      .set('last_modified', employee.modified)
      .set('screen_id', this.screen_id)
      .set('token', this.token);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = employee.id;
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/employee/deleteforng/' + String(employee.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }

}