<div class="modal-header">
    <h4>{{dispTargetYm}}</h4>
    <h4>{{golfwork['golf_name']}}{{golfwork.name}}</h4>　<label>依頼数：{{request}}</label>
  </div>
  <div class="modal-body">
    <fieldset>
        <div class="form-group row">
            <ng-container *ngFor="let item of employees">
            <label class="cbstyle" [ngClass]="{'btn-success':!item['checked'], 'btn-danger':!item['checked']&&item['available_flg']=='0', 'commit':item['checked']&&item['commit_flg']=='1', 'no-commit':item['checked']&&item['commit_flg']!='1', 'no-select': item['golfwork_id']>0}">
                <input type="checkbox" style="display: none;" [(ngModel)]="item['checked']" (change)="chkMax(item)">{{item.name}}
            </label>
            </ng-container>
        </div>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="regist()"><span class="oi oi-cloud-upload"></span>閉じる</button>
  </div>
