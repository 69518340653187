<div class="row">
    <div class="col-12 col-xl-4">
        <div style="text-align: center;">
            <button class="btn btn-primary" style="float: left;" (click)="prevYm()"><span class="oi oi-arrow-left"></span></button>
            <label class="" style="font-size: xx-large;">{{dispTargetYm}}</label>
            <button class="btn btn-primary" style="float: right;" (click)="nextYm()"><span class="oi oi-arrow-right"></span></button>
        </div>
        <div class="table-responsive-lg">
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th style="text-align: center;" class="bg-secondary">
                            シフト表
                        </th>
                        <th style="text-align: center;" class="bg-secondary">
                            {{employee.name}}
                        </th>
                    </tr>
                </thead>
            </table>
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col"  [class.btn-primary]="week.week_cd=='Sat'" [class.btn-danger]="week.week_cd=='Sun'" *ngFor="let week of weeks">{{week.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let targets of calendar">
                        <td style="border-color: black;text-align: center;background-color: black;" *ngFor="let target of targets">
                            <div class="w-100" [class.btn-light]="weeks2[target.d - 1]!=6&&weeks2[target.d - 1]!=0" [class.btn-primary]="weeks2[target.d - 1]==6" [class.btn-danger]="weeks2[target.d - 1]==0">{{target.ymd | date:'dd'}}</div>
                            <div class="w-100" 
                              [class.btn-secondary]="attendances[target.d - 1]['golfwork_id']==0"
                              [class.no-commit]="attendances[target.d - 1]['golfwork_id']>0 && attendances[target.d - 1]['commit_flg']==0"
                              [class.commit]="attendances[target.d - 1]['golfwork_id']>0 && attendances[target.d - 1]['commit_flg']==1"
                              *ngIf="attendances[target.d - 1]" (click)="changeCommitFlg(attendances[target.d - 1])" >{{attendances[target.d - 1]['golf_name_short']}}</div>
                            <button class="w-100"
                              [class.btn-danger]="attendances[target.d - 1]['available_flg']==0"
                              [class.btn-info]="attendances[target.d - 1]['available_flg']==1"
                              *ngIf="attendances[target.d - 1]" (click)="changeAvailableFlg(attendances[target.d - 1], $event)">{{attendances[target.d - 1]['available_flg']=='1'?'可':'不可'}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 col-xl-6">
        <div class="table-responsive-lg">
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-info" colspan="9" style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" >出　勤　簿</th>
                    </tr>
                    <tr>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                            日付
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            場所
                        </th>
<!--
                        <th class="btn-secondary" style="width: 15%;vertical-align: middle;border-color: black;text-align: center;">
                            場所詳細
                        </th>
-->
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            出勤時間
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            退勤時間
                        </th>
                        <th class="btn-secondary" style="width: 6%;vertical-align: middle;border-color: black;text-align: center;">
                            Ｒ数
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            所定外時間
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            労働時間
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            休憩時間
                        </th>
                        <th class="btn-secondary" style="width: 20%;vertical-align: middle;border-color: black;text-align: center;">
                            備考
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let target of attendances;index as i;" (click)="openAttendanceMod(target)">
                        <td [class.bg-primary]="weeks2[i]==6" [class.bg-danger]="weeks2[i]==0" style="border-color: black;text-align: center;">
                            {{i + 1}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.golf_name}}
                        </td>
<!--
                        <td style="border-color: black;text-align: center;">
                            {{target.type_name}}
                        </td>
-->
                        <td style="border-color: black;text-align: center;">
                            {{target.started | date:"HH:mm"}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.ended | date:"HH:mm"}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.round}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.other}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.base}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.rest}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.remarks}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 col-xl-2">
        <div class="table-responsive-lg">
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-info" colspan="9" style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" >出勤簿集計</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let target of golfworks;index as i;">
                        <td style="border-color: black;text-align: center;">
                            {{target.golf_name}}{{target.name}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{cntGolfworks[target.id]}}
                        </td>
                    </tr>
                    <tr style="height: 26px;">
                        <td style="border-color: black;text-align: center;">　</td>
                        <td style="border-color: black;text-align: center;">　</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">合計キャディR数</td>
                        <td style="border-color: black;text-align: center;">{{sumRound}}</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">合計作業日数</td>
                        <td style="border-color: black;text-align: center;">{{cntWork}}</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">労働時間</td>
                        <td style="border-color: black;text-align: center;">{{sumBase}}</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">所定外時間</td>
                        <td style="border-color: black;text-align: center;">{{sumOuter}}</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">出勤日数</td>
                        <td style="border-color: black;text-align: center;">{{cntAttend}}</td>
                    </tr>
                    <tr>
                        <td style="border-color: black;text-align: center;">休日</td>
                        <td style="border-color: black;text-align: center;">{{cntRest}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
