import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { MemoService } from '../service/memo.service';
import { Memo } from '../class/memo';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-memo-mod',
  templateUrl: './memo-mod.component.html',
  styleUrls: ['./memo-mod.component.css'],
  providers: [MemoService]

})
export class MemoModComponent implements OnInit {
  data: wjcCore.CollectionView;
  currentItem = new Memo();
  dispTargetYmd = '';
  constructor(
    private memoService: MemoService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('screen_id', 'memo-mod');

  }
  async regist() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.memoService.add(this.currentItem).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.currentItem.id = result.id;
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });
  }
  async update() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});

    this.memoService.update(this.currentItem).then(result => {
      if (result.msgcd=='S') {
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });

  }
}
