<button (click)="add()" class="btn btn-sm btn-primary"><span class="oi oi-plus"></span>新規追加
</button>
<label style="float:right">検索結果：{{data?.items.length | number}}　件</label>
<wj-flex-grid class="grid" #flexGrid [itemsSource]="data" [selectionMode]="'Row'" [isReadOnly]="true" style="height:660px;" >
<!--      <wj-flex-grid-filter #filter [filterColumns]="['name', 'name_short']"></wj-flex-grid-filter>-->
    <wj-flex-grid-column [isReadOnly]="true" [width]="70">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button class="btn btn-sm btn-success" (click)="edit()"><span class="oi oi-pencil"></span>詳細</button>
        </ng-template> 
    </wj-flex-grid-column>
    <wj-flex-grid-column [isReadOnly]="true" [width]="100">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button class="btn btn-sm btn-success" (click)="openGolfworkMod()"><span class="oi oi-list"></span>コース</button>
        </ng-template> 
    </wj-flex-grid-column>
    <wj-flex-grid-column [isReadOnly]="true" [width]="70">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button class="btn btn-sm btn-success" (click)="navLinkTo(cell.item.id)"><span class="oi oi-share"></span>依頼</button>
        </ng-template> 
    </wj-flex-grid-column>
    <wj-flex-grid-column header="ゴルフ場名" [binding]="'name'" [width]="150">
    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        <div style="text-align:center">ゴルフ場名</div>
    </ng-template>
    </wj-flex-grid-column> 
    <wj-flex-grid-column header="ゴルフ場名（略称）" [binding]="'name_short'" [width]="100">
    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        <div style="text-align:center">ゴルフ場名（略称）</div>
    </ng-template>
    </wj-flex-grid-column> 
    <wj-flex-grid-column header="作業区分" [binding]="'work_kbn_name'" [width]="'*'">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <div style="text-align:center">作業区分</div>
        </ng-template>
    </wj-flex-grid-column>
    <wj-flex-grid-column header="活動区分" [binding]="'active_name'" [width]="'*'">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <div style="text-align:center">活動区分</div>
        </ng-template>
    </wj-flex-grid-column>
    <wj-flex-grid-column header="最終更新日時" [binding]="'modified'" [width]="'3*'">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <div style="text-align:center">最終更新日時</div>
        </ng-template>
        </wj-flex-grid-column>
    
    <wj-flex-grid-column [width]="60" [isReadOnly]="true">
    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        <button type="button" class="btn btn-default" aria-label="Left Align" (click)="exportExcel()">
        <span class="oi oi-data-transfer-download"></span>
        </button>
    </ng-template>
    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
        <div>
        <button class="btn btn-sm btn-danger" (click)="delete(cell.item)"><span class="oi oi-trash"></span>削除</button>
        </div>
    </ng-template>
    </wj-flex-grid-column>
</wj-flex-grid>
