import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { RequestService } from '../service/request.service';
import { Request } from '../class/request';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-mod',
  templateUrl: './request-mod.component.html',
  styleUrls: ['./request-mod.component.css']
})
export class RequestModComponent implements OnInit {
  data: wjcCore.CollectionView;
  currentItem = new Request();
  public actives: any;
  public actives_cb = [];

  cnts = [-2, -1, 1,2,3,4,5,6,7,8,9,10];
  remarkss = ['', '変更', '競技', 'その他']

  constructor(
    private requestService: RequestService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    sessionStorage.setItem('screen_id', 'request-mod');
    this.requestService.initRequestMod(this.currentItem.golfwork_id).then(result => {
      
    })
  }

  regist() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.requestService.add(this.currentItem).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.currentItem.id = result.id;
        this.currentItem['user_id'] = result['user_id'];
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });
  }
/*
  update() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.requestService.update(this.currentItem).then(result => {
      if (result.msgcd=='S') {
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });
  }
  */
}
