export class Employee {
    id: number;
    name: string;
    name_short: string;
    user_id: number;
    employed: Date;
    retired: Date;
    birthday: Date;
    sex: string;
    tel: string;
    zip: string;
    address1: string;
    address2: string;
    active: string;
    user_flg: string;
    modified: string;
    constructor(){
        this.id = 0;
        this.name = '';
        this.name_short = '';
        this.user_id = 0;
        this.employed = null;
        this.retired = null;
        this.birthday = null;
        this.sex = '';
        this.tel = '';
        this.zip = '';
        this.address1 = '';
        this.address2 = '';
        this.active = '1';
        this.user_flg = '0';
        this.modified = '';    
    }
}
export class EmployeeUser extends Employee {
    username: string;
    password: string;
    userinfo_id: number;
    email: string;
    token: string;
    constructor(){
        super();
        this.username = '';
        this.password = '';
        this.userinfo_id = 0;
        this.email = '';
        this.token = '';
    }
}
