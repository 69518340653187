<div class="col-md-6 col-md-offset-3">
    <fieldset>
      <form  #f="ngForm">
        <div class="form-group" [ngClass]="{'has-danger':vpassword.errors && vpassword.dirty}">
            <label for="vpassword">パスワード</label>
            <input type="password" class="form-control" id="vpassword" name="vpassword" [(ngModel)]="password" #vpassword="ngModel" minlength="5" maxlength="20" required />
            <span style="color:red" [hidden]="!vpassword.dirty || (!vpassword.errors?.required && !vpassword.errors?.minlength)">5文字以上で入力してください</span>
            <span style="color:red" [hidden]="!vpassword.dirty || (!vpassword.errors?.maxlength)">20文字以内で入力してください</span>
        </div>
        <div class="form-group" [ngClass]="{'has-danger':vpwconfirm.errors && vpwconfirm.dirty}">
            <label for="vpwconfirm">パスワード（確認）</label>
            <input type="password" class="form-control" id="vpwconfirm" name="vpwconfirm" [(ngModel)]="pwconfirm" #vpwconfirm="ngModel" minlength="5" maxlength="20" required />
            <span style="color:red" [hidden]="!vpwconfirm.dirty || (!vpwconfirm.errors?.required && !vpwconfirm.errors?.minlength)">5文字以上で入力してください</span>
            <span style="color:red" [hidden]="!vpwconfirm.dirty || (!vpwconfirm.errors?.maxlength)">20文字以内で入力してください</span>
            <span style="color:red" [hidden]="!vpwconfirm.dirty || (password == pwconfirm)">パスワードが一致しません</span>
        </div>
        <div class="form-group row">
          <div class="col"></div>
          <div class="col">
            <button class="btn btn-primary" [disabled]="!f.form.valid || password!=pwconfirm" (click)="update()">更新</button>
          </div>
          <div class="col"></div>
        </div>
      </form>
    </fieldset>
</div>