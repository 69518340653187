import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { Golfwork } from '../class/golfwork';
import { User } from '../class/user';
import { UtilityService } from './utility.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class GolfworkService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  initGolfworkMod(golf_id): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('golf_id', golf_id)
    .set('screen_id', this.screen_id);
/*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        golfworks: [
          {id: 1, name: 'キャディ', name_short: '', type_cd: '10', remarks: '', active: 1, modified: '2020/11/22 18:34:00'},
          {id: 2, name: '作業', name_short: '', type_cd: '20', remarks: '', active: 1, modified: '2020/11/23 18:34:00'}
        ],
        type_cds: {'10': 'キャディ', '20': '作業'}
      };
      return resolve(result);
    });
*/
    return this.http.post(this.api_url + 'capi/golfwork/initGolfworkList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(golfwork:Golfwork): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('name', golfwork.name)
      .set('golf_id', String(golfwork.golf_id))
      .set('type_cd', golfwork.type_cd)
      .set('remarks', golfwork.remarks)
      .set('active', golfwork.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = 6;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golfwork/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(golfwork:Golfwork): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(golfwork.id))
      .set('name', golfwork.name)
      .set('remarks', golfwork.remarks)
      .set('active', golfwork.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', golfwork.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = golfwork.id;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golfwork/updateforng/'+ String(golfwork.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  delete(golfwork:Golfwork): Promise<Result> {
    this.before();
    let params = new HttpParams()
      .set('id', String(golfwork.id))
      .set('last_modified', golfwork.modified)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = golfwork.id;
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golfwork/deleteforng/' + String(golfwork.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }

}