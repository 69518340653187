import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { EmployeeService } from '../service/employee.service';
import { AuthenticationService } from '../service/authentication.service';
import { Employee, EmployeeUser } from '../class/employee';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { EmployeeModComponent } from '../employee-mod/employee-mod.component';


@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  public data: wjcCore.CollectionView;
  includeColumnHeader = true;
  currentItem: any;

  private actives: any;
  private actives_cb = [];
  loading = false;
  
  @ViewChild('filter') filter: wjcGridFilter.FlexGridFilter;
  // references FlexSheet named 'flexSheetIntro' in the view
  @ViewChild('flexGrid') flexGrid: wjcGrid.FlexGrid;
  @ViewChild('modalWait') modalWait: any;
  
  constructor(
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {
    this.currentItem = new Employee();
  }

  ngOnInit() {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'employee-list');
    this.authenticationService.setDispName('従業員情報管理');
//    const mw = this.modalService.open(this.modalWait, {backdrop: 'static', keyboard: false});
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.employeeService.initEmployeeList().then(result => {
      console.log(result);
      if (result.msgcd=='S') {
//        console.log(result['data']['employees'] as Employee[]);
        this.actives = result['data']['actives'];
        for (var x in this.actives) {
          this.actives_cb.push({cd:x, name:this.actives[x]});
        }
        result['data']['datas'].forEach(element => {
          element['active_name'] = this.actives[element.active];
        });
        this.data = new wjcCore.CollectionView(result['data']['datas'] as EmployeeUser[]);
        this.currentItem = this.data.currentItem;
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  add() {
    this.data.addNew();
    this.currentItem = this.data.currentItem;
    this.currentItem.id = 0;
    this.currentItem.userinfo_id = 0;
    this.currentItem.user_id = '0';
    this.currentItem.active = '1';
    this.currentItem.user_flg = '0';
    this.currentItem.token = '';
    this.currentItem.email = '';

    let modalRef = this.modalService.open(EmployeeModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as EmployeeModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.currentItem = this.currentItem;
    component.data = this.data;
    modalRef.result.then((result) => {
      console.log(result);
      this.currentItem = result;
      this.currentItem['active_name'] = this.actives[this.currentItem.active];

      this.data.commitNew();
      sessionStorage.setItem('screen_id', 'employee-list');
    }, (reason) => {
      this.data.cancelNew();
      sessionStorage.setItem('screen_id', 'employee-list');
    });
  }
  edit() {
    this.currentItem = this.data.currentItem;
    this.data.editItem(this.currentItem);
    let modalRef = this.modalService.open(EmployeeModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as EmployeeModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.currentItem = this.currentItem;
    component.data = this.data;
    modalRef.result.then((result) => {
      this.data.currentItem = result;
      this.currentItem['active_name'] = this.actives[this.currentItem.active];
      this.data.commitEdit();
      sessionStorage.setItem('screen_id', 'employee-list');
    }, (reason) => {
      this.data.cancelEdit();
      sessionStorage.setItem('screen_id', 'employee-list');
    });
  }
  delete(item: any) {
    let ms = this.modalService.open(ModalConfirmComponent);
    ms.componentInstance.title = '確認';
    ms.componentInstance.message = SysMsg.getMsg('com000_002');
    ms.result.then((result) => {
      this.loading = true;
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.employeeService.delete(item as EmployeeUser).then(result => {
        if (result.msgcd == 'S') {
          this.alertService.success(result.message, false);
          this.data.remove(item);
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    }, (reason) => {
    });
  }
/*
  openEmployeeworkMod (){
    let modalRef = this.modalService.open(EmployeeworkModComponent, {size: 'lg', backdrop: true, keyboard: false});
    let component = modalRef.componentInstance as EmployeeworkModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
//    component.currentItem = this.currentItem;
//    component.data = this.data;
    modalRef.result.then((result) => {
      this.data.currentItem = result;
      this.data.commitEdit();
      sessionStorage.setItem('screen_id', 'employee-list');
    }, (reason) => {
      this.data.cancelEdit();
      sessionStorage.setItem('screen_id', 'employee-list');
    });
  }
*/
  navLinkTo(id) {
    sessionStorage.setItem('employeeListEmployeeId', String(id));
    this.ngZone.run(() => {
      this.router.navigate(['/attendance-detail', id]);
    });
  }

  exportExcel() {
    wjcGridXlsx.FlexGridXlsxConverter.save(this.flexGrid, {
        includeColumnHeaders: this.includeColumnHeader,
        formatItem: function (args) {
        },
        includeCellStyles: false,
    }, '従業員情報.xlsx');
  }
}
  