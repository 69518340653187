import { WjInputModule } from '@grapecity/wijmo.angular2legacy.input';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UtilityService } from '../service/utility.service';
import { AttendanceService } from '../service/attendance.service';
import { AuthenticationService } from '../service/authentication.service';
import { Attendance } from '../class/attendance';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'

@Component({
  selector: 'app-attendance-view',
  templateUrl: './attendance-view.component.html',
  styleUrls: ['./attendance-view.component.css']
})
export class AttendanceViewComponent implements OnInit {
  employee_id = 0;
  employee_name = '';
  targetYmd = new Date();
  dispTargetYm = '';
  cntCady = 0;
  cntWork = 0;
  sumOuter = 0;
  sumBase = 0;
  cntAttend = 0;
  cntRest = 0;
  cntGolfworks = [];

  type_cds: any;
  type_cds_cb = [];

  weeks = [
    {week_cd: 'Mon', name: '月'},
    {week_cd: 'Tue', name: '火'},
    {week_cd: 'Wed', name: '水'},
    {week_cd: 'Thu', name: '木'},
    {week_cd: 'Fri', name: '金'},
    {week_cd: 'Sat', name: '土'},
    {week_cd: 'Sun', name: '日'},
  ];
  weeks2 = [];
  calendar = [
    [{}, {}, {}, {ymd:'2020/12/01'}, {ymd:'2020/12/02'}, {ymd:'2020/12/03'}, {ymd:'2020/12/04'}],
    [{ymd:'2020/12/05'}, {ymd:'2020/12/06'}, {ymd:'2020/12/07'}, {ymd:'2020/12/08'}, {ymd:'2020/12/09'}, {ymd:'2020/12/10'}, {ymd:'2020/12/11'}],
    [{ymd:'2020/12/12'}, {ymd:'2020/12/13'}, {ymd:'2020/12/14'}, {ymd:'2020/12/15'}, {ymd:'2020/12/16'}, {ymd:'2020/12/17'}, {ymd:'2020/12/18'}],
    [{ymd:'2020/12/19'}, {ymd:'2020/12/20'}, {ymd:'2020/12/21'}, {ymd:'2020/12/22'}, {ymd:'2020/12/23'}, {ymd:'2020/12/24'}, {ymd:'2020/12/25'}],
    [{ymd:'2020/12/26'}, {ymd:'2020/12/27'}, {ymd:'2020/12/28'}, {ymd:'2020/12/29'}, {ymd:'2020/12/30'}, {ymd:'2020/12/31'}, {}],
  ];
  attendances = [
    {id: 1, golfwork_id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: 'キャディ', started: '9:00', ended: '17:00', other: 0, rest: 1, remarks: ''}, 
    {id: 2, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 3, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 4, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 5, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 6, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 7, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 8, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 9, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 10, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 11, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 12, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 13, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 14, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 15, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 16, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 17, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 18, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 19, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 20, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 21, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 22, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 23, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 24, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 25, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 26, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 27, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 28, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 29, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 30, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
    {id: 31, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
  ];
  golfworks = [
    {id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: 'キャディ'},
    {id: 2, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '作業'},
    {id: 3, golf_name: 'サイプレイス', golf_name_short: 'サイ', name: 'キャディ'},
    {id: 4, golf_name: '花屋敷', golf_name_short: '花屋敷', name: 'ひろの'},
    {id: 5, golf_name: '花屋敷', golf_name_short: '花屋敷', name: 'よかわ'},
    {id: 6, golf_name: '小野', golf_name_short: '小野', name: 'キャディ'},
    {id: 7, golf_name: '加古川', golf_name_short: '加古川', name: 'キャディ'},
    {id: 8, golf_name: '三木', golf_name_short: '三木', name: 'キャディ'},
    {id: 9, golf_name: 'サンロイヤル', golf_name_short: 'サンロイヤル', name: 'キャディ'},
    {id: 10, golf_name: '東広野', golf_name_short: '東広野', name: 'キャディ'},
    {id: 11, golf_name: 'ゴールデン', golf_name_short: 'ゴールデン', name: 'キャディ'},
    {id: 12, golf_name: 'パイン', golf_name_short: 'パイン', name: 'キャディ'},
    {id: 13, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', name: 'キャディ'},
    {id: 14, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', name: 'マスター'},
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'attendance-view');
//    this.authenticationService.setDispName('スケジュール');
    this.targetYmd.setDate(1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.attendanceService.initAttendanceDetail(this.employee_id).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
//        this.employee = result['data']['employee'];
        this.type_cds = result['data']['type_cds'];
        for (var x in this.type_cds) {
          this.type_cds_cb.push({cd:x, name:this.type_cds[x]});
        }
        this.getAttendanceDetail();
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  getAttendanceDetail(): void {
    this.cntRest = 0;
    this.cntAttend = 0;
    this.sumOuter = 0;
    this.sumBase = 0;
    this.cntWork = 0;
    this.cntCady = 0;
    this.cntGolfworks = [];
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.attendanceService.getAttendanceDetail(this.employee_id, this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2)).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.calendar = result['data']['calendar'];
        this.attendances = result['data']['attendances'];
        this.golfworks = result['data']['golfworks'];
        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
        for(let i=0;i<this.attendances.length;i++) {
          let dt = new Date(this.dispTargetYm + '/' + (i+1));
          this.weeks2[i] = dt.getDay();
          this.attendances[i]['type_name'] = this.type_cds[this.attendances[i]['type_cd']];
          this.sumBase += Number(this.attendances[i]['base']);
          this.sumOuter += Number(this.attendances[i]['other']);
        }
        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
        for(let i=0;i<this.attendances.length;i++) {
          let dt = new Date(this.dispTargetYm + '/' + (i+1));
          this.weeks2[i] = dt.getDay();
          if (this.attendances[i]['golfwork_id'] > 0) {
            if(this.cntGolfworks[this.attendances[i]['golfwork_id']]) {
              this.cntGolfworks[this.attendances[i]['golfwork_id']]++;
            } else {
              this.cntGolfworks[this.attendances[i]['golfwork_id']] = 1;
            }
            if (this.attendances[i]['golfwork_id'] > 900) {
              this.cntWork++;
            } else {
              if (this.attendances[i]['golfwork_id'] != 14) {
                this.cntCady++;
              }
            }
            this.cntAttend++;
          } else {
            this.cntRest++;
          }
        }
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  prevYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() - 1);
//    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceDetail();

  }
  nextYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() + 1);
//    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceDetail();

  }

}
