import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UtilityService } from '../service/utility.service';
import { RequestService } from '../service/request.service';
import { AttendanceService } from '../service/attendance.service';
import { UserService } from '../service/user.service';
import { AuthenticationService } from '../service/authentication.service';
import { Request } from '../class/request';
import { Golf } from '../class/golf';
import { Golfwork } from '../class/golfwork';
import { User } from '../class/user';

import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { RequestModComponent } from "../request-mod/request-mod.component";
import { EmployeeSelectComponent } from "../employee-select/employee-select.component";
import { GolfEmployeeListComponent } from "../golf-employee-list/golf-employee-list.component";

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
})
export class RequestListComponent implements OnInit {
  golf_id = 0;
  golfwork_id = 0;
  day = '';
  currentItem = new Request();
  targetYmd = new Date();
  dispTargetYm = '';
  cntCady = 0;
  cntWork = 0;
  sumOuter = 0;
  cntAttend = 0;
  cntRest = 0;
  cntGolfworks = [];
  dds = [null, '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  pDd = '';

  actives = [];
  actives_cb = [];
  type_cds = {'10': 'キャディ', '20': '作業'};
  type_cds_cb = [];

  endInitProc = false;
  canUpdate = false;

  weeks = [
    {week_cd: 'Mon', name: '月'},
    {week_cd: 'Tue', name: '火'},
    {week_cd: 'Wed', name: '水'},
    {week_cd: 'Thu', name: '木'},
    {week_cd: 'Fri', name: '金'},
    {week_cd: 'Sat', name: '土'},
    {week_cd: 'Sun', name: '日'},
  ];
  weeks2 = [];
  calendar = [
/*    [{}, {}, {}, {ymd:'2020/12/01', d:'1'}, {ymd:'2020/12/02', d:'2'}, {ymd:'2020/12/03', d:'3'}, {ymd:'2020/12/04', d:'4'}],
    [{ymd:'2020/12/05', d:'5'}, {ymd:'2020/12/06', d:'6'}, {ymd:'2020/12/07', d:'7'}, {ymd:'2020/12/08', d:'8'}, {ymd:'2020/12/09', d:'9'}, {ymd:'2020/12/10', d:'10'}, {ymd:'2020/12/11', d:'11'}],
    [{ymd:'2020/12/12', d:'12'}, {ymd:'2020/12/13', d:'13'}, {ymd:'2020/12/14', d:'14'}, {ymd:'2020/12/15', d:'15'}, {ymd:'2020/12/16', d:'16'}, {ymd:'2020/12/17', d:'17'}, {ymd:'2020/12/18', d:'18'}],
    [{ymd:'2020/12/19', d:'19'}, {ymd:'2020/12/20', d:'20'}, {ymd:'2020/12/21', d:'21'}, {ymd:'2020/12/22', d:'22'}, {ymd:'2020/12/23', d:'23'}, {ymd:'2020/12/24', d:'24'}, {ymd:'2020/12/25', d:'25'}],
    [{ymd:'2020/12/26', d:'26'}, {ymd:'2020/12/27', d:'27'}, {ymd:'2020/12/28', d:'28'}, {ymd:'2020/12/29', d:'29'}, {ymd:'2020/12/30', d:'30'}, {ymd:'2020/12/31', d:'31'}, {}],*/
  ];
  capacity_sums = [];/*
  {
    1: {capa_all: 40, capa_caddy: 30}, 
    2: {capa_all: 20, capa_caddy: 10}, 
    3: {capa_all: 20, capa_caddy: 10}, 
    4: {capa_all: 20, capa_caddy: 10}, 
    5: {capa_all: 20, capa_caddy: 10}, 
    6: {capa_all: 20, capa_caddy: 10}, 
    7: {capa_all: 20, capa_caddy: 10}, 
    8: {capa_all: 20, capa_caddy: 10}, 
    9: {capa_all: 20, capa_caddy: 10}, 
   10: {capa_all: 20, capa_caddy: 10}, 
   11: {capa_all: 20, capa_caddy: 10}, 
   12: {capa_all: 20, capa_caddy: 10}, 
   13: {capa_all: 20, capa_caddy: 10}, 
   14: {capa_all: 20, capa_caddy: 10}, 
   15: {capa_all: 20, capa_caddy: 10}, 
   16: {capa_all: 20, capa_caddy: 10}, 
   17: {capa_all: 20, capa_caddy: 10}, 
   18: {capa_all: 20, capa_caddy: 10}, 
   19: {capa_all: 20, capa_caddy: 10}, 
   20: {capa_all: 20, capa_caddy: 10}, 
   21: {capa_all: 20, capa_caddy: 10}, 
   22: {capa_all: 20, capa_caddy: 10}, 
   23: {capa_all: 20, capa_caddy: 10}, 
   24: {capa_all: 20, capa_caddy: 10}, 
   25: {capa_all: 20, capa_caddy: 10}, 
   26: {capa_all: 20, capa_caddy: 10}, 
   27: {capa_all: 20, capa_caddy: 10}, 
   28: {capa_all: 20, capa_caddy: 10}, 
   29: {capa_all: 20, capa_caddy: 10}, 
   30: {capa_all: 20, capa_caddy: 10}, 
   31: {capa_all: 20, capa_caddy: 10}, 
  };*/
  request_sums = [];
  /*{
    1: {cnt: 2}, 
    2: {cnt: 2}, 
    3: {cnt: 4}, 
    4: {cnt: 0}, 
    5: {cnt: 0}, 
    6: {cnt: 0}, 
    7: {cnt: 0}, 
    8: {cnt: 0}, 
    9: {cnt: 0}, 
//   10: {cnt: 0}, 
   11: {cnt: 0}, 
   12: {cnt: 0}, 
   13: {cnt: 0}, 
   14: {cnt: 0}, 
   15: {cnt: 0}, 
   16: {cnt: 0}, 
   17: {cnt: 0}, 
   18: {cnt: 0}, 
   19: {cnt: 0}, 
   20: {cnt: 0}, 
   21: {cnt: 0}, 
   22: {cnt: 0}, 
   23: {cnt: 0}, 
   24: {cnt: 0}, 
   25: {cnt: 0}, 
   26: {cnt: 0}, 
   27: {cnt: 0}, 
   28: {cnt: 0}, 
   29: {cnt: 0}, 
   30: {cnt: 0}, 
   31: {cnt: 0}, 
  };*/
  work_sums = [];
  requests: Request[] = [];
  attendances = [];
  availableAttendances = [];
  golfworks = [
    {id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '城山キャディ'},
//    {id: 2, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '城山作業'},
  ];

  golfinfo = new Golf();
  cur_user_id = 0;
  cur_golf_user_id = 0;
  work_id = 0;
  user = new User();
  selectedEmployees = [];
  selectedGolfEmployees = [];

  targetYm = '';
  constructor(
    private authenticationService: AuthenticationService,
    private requestService: RequestService,
    private attendanceService: AttendanceService,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'request-list');
    this.authenticationService.setDispName('作業別依頼一覧');
    // let user = JSON.parse(sessionStorage.getItem('user'));
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.cur_user_id = this.user.id;

    if (this.golf_id==0) {
      this.route.params.subscribe(params => {
        this.golf_id = params['id'];
      });
    }
    this.targetYmd.setDate(1);
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.requestService.initRequestList(this.golf_id).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.golfworks = result['data']['golfworks'];
        this.golfwork_id = this.golfworks[0]['id'];
        this.getRequest();
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  getRequest(): void {
    this.canUpdate = false;
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.targetYm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.availableAttendances = []; //初期化
    this.requestService.getRequestList(this.golfwork_id, this.targetYm).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        console.log('suc');
        this.requests = result['data']['requests'] as Request[];
        this.calendar = result['data']['calendar'] as [];
        let cs = result['data']['capacity_sums'];
/*        
        for(let i=1;i<=31;i++) {
          console.log(i);
          cs[i]['capa_all'] = Number(cs[i]['capa_all']);
        }*/
        this.calendar.forEach(day => {
            if(day['d']) {
              cs[day['d']]['capa_all'] = Number(cs[day['d']]['capa_all']);
            }
            if(cs[day['d']]['capa_all']==0) {
              cs[day['d']]['capa_all'] = '';
            }
            if(cs[day['d']]['capa_caddy']==0) {
              cs[day['d']]['capa_caddy'] = '';
            }
        });
        this.capacity_sums = cs;
        this.request_sums = result['data']['request_sums'];
        this.work_sums = result['data']['work_sums'];
        this.golfinfo =  result['data']['golfinfo'];
        this.work_id = this.work_sums[1]?Number(this.golfinfo['id']) + 900: 0;
        this.cur_golf_user_id = this.golfinfo['user_id'];

        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);

        /*
        this.dds[0] = null;
        for(let i=1;i<=31;i++) {
          this.dds[i] = ('00' + i).slice(-2);
        }*/
        this.pDd = this.day;
/*
        for(let i=0;i<this.requests.length;i++) {
          let dt = new Date(this.dispTargetYm + '/' + (i+1));
          this.weeks2[i] = dt.getDay();
          if (this.requests[i]['golfwork_id'] > 0) {
            console.log(this.cntGolfworks.indexOf(this.requests[i]['golfwork_id']));
            if(this.cntGolfworks.indexOf(this.requests[i]['golfwork_id']) > -1) {
              this.cntGolfworks[this.requests[i]['golfwork_id']]++;
            } else {
              this.cntGolfworks[this.requests[i]['golfwork_id']] = 1;
              
            }
            console.log(this.cntGolfworks);
          } else {
            this.cntRest++;
          }
        }
*/
        this.attendances = result['data']['attendances'] as [];

        this. selectedEmployees = result['data']['employees'] as [];

        this.endInitProc = true;
        this.canUpdate = true;

        this.getSlectedAttendanceList(this.targetYm);
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  getSlectedAttendanceList(yyyymm: string) {
    this.requestService.getSlectedAttendanceList(this.golf_id, yyyymm).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.availableAttendances = result['data']['attendances'] as [];
        let employees = this.selectedEmployees.filter(employee => {
          return employee['golf_employee_id'] > 0;
        })
        
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });
  }
  prevYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() - 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getRequest();
//    this.getData(this.targetYmd.toLocaleDateString('ja-JP', UtilityService.localeOptions));

  }
  nextYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() + 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getRequest();
//    this.getData(this.targetYmd.toLocaleDateString('ja-JP', UtilityService.localeOptions));

  }
  changeGolfwork() {
    if (!this.endInitProc) {
      return;
    }
    this.getRequest();
  }
  add(ymd, work_flg) {
    if (!this.canUpdate) {
      return;
    }
    let pDate = new Date(ymd);
    let dd = ('00' + String(pDate.getDate())).slice(-2);
    this.currentItem = new Request();
    this.currentItem.id = 0;
    this.currentItem.yyyymm = this.dispTargetYm.replace('/', '');
    this.currentItem.dd = dd;
    this.currentItem['ymd'] = ymd;
    this.currentItem['golf_name'] = this.golfinfo['golf_name'];
    this.currentItem['notificate_flg'] = this.cur_golf_user_id==this.cur_user_id?'1':'';
    if (work_flg == '0') {
      this.currentItem['golfwork_id'] = this.golfwork_id;
      this.currentItem['golfwork_name'] = 'キャディ：' + this.golfinfo['golfwork_name'];
      this.currentItem.type_cd = '10'; //キャディ
    } else {
      this.currentItem['golfwork_id'] = 900 + Number(this.golf_id);
      this.currentItem['golfwork_name'] = '作業';
      this.currentItem.type_cd = '20'; //作業
    }
    this.currentItem.remarks = '';
    this.currentItem.active = '1';
    let modalRef = this.modalService.open(RequestModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as RequestModComponent;
//    component.actives = this.actives;
//    component.actives_cb = this.actives_cb
    component.currentItem = this.currentItem;
//    component.data = this.data;
    modalRef.result.then((result) => {
      console.log(result);
      this.currentItem = result;
      this.requests.push(this.currentItem);
      if (work_flg == '0') {
        this.request_sums[Number(this.currentItem['dd'])]['cnt'] = Number(this.request_sums[Number(this.currentItem['dd'])]['cnt']) + Number(this.currentItem['cnt']);
      } else {
        this.work_sums[Number(this.currentItem['dd'])]['cnt'] = Number(this.work_sums[Number(this.currentItem['dd'])]['cnt']) + Number(this.currentItem['cnt']);
      }
      sessionStorage.setItem('screen_id', 'request-list');
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'request-list');
    });
  }
  edit(target: Request) {
    if (!this.canUpdate) {
      return;
    }
    this.currentItem = target;
    let modalRef = this.modalService.open(RequestModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as RequestModComponent;
    component.actives = this.actives;
    component.actives_cb = this.actives_cb
    component.currentItem = this.currentItem;
//    component.data = this.data;
    modalRef.result.then((result) => {
      this.currentItem.yyyymm = result.yyyymm;
      this.currentItem.dd = result.dd;
      this.currentItem.cnt = result.cnt;
      this.currentItem.remarks = result.remarks;
      this.currentItem.modified = result.modified;
      sessionStorage.setItem('screen_id', 'request-list');
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'request-list');
    });
  }
  delete(item: any) {
    let ms = this.modalService.open(ModalConfirmComponent);
    ms.componentInstance.title = '確認';
    ms.componentInstance.message = SysMsg.getMsg('com000_002');
    ms.result.then((result) => {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.requestService.delete(item as Request).then(result => {
        if (result.msgcd == 'S') {
          this.alertService.success(result.message, false);
          for(let i=0;i<this.requests.length; i++) {
            if(this.requests[i]['id'] == item['id']) {
              this.requests.splice(i, 1);
              break;
            }
          }
          if (item['type_cd'] == '10') {
            this.request_sums[Number(item['dd'])]['cnt'] = Number(this.request_sums[Number(item['dd'])]['cnt']) - Number(item['cnt']);
          } else {
            this.work_sums[Number(item['dd'])]['cnt'] = Number(this.work_sums[Number(item['dd'])]['cnt']) - Number(item['cnt']);
          }
          
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    }, (reason) => {
    });
  }
  onChangeCapa(capacity) {
    if (!this.canUpdate) {
      return;
    }
    if (capacity.id > 0) {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.requestService.updateCapa(capacity).then(result => {
        if (result.msgcd=='S') {
          capacity.modified = result['modified'];
//          this.alertService.success(result.message, false);
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
    } else {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.requestService.addCapa(capacity).then(result => {
        console.log(result);
        if (result.msgcd=='S') {
          capacity.id = result.id;
          capacity.modified = result['modified'];
//          this.alertService.success(result.message, false);
        }else {
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
        mw.close();
      });
  
    }
  }
  delAttendance(employee, golfwork_id, ymd) {
    let day = (new Date(ymd)).getDate();
    console.log(employee);
    console.log(golfwork_id);
    console.log(day);
    console.log(this.attendances[golfwork_id][day]);
    for(let i=0;i<this.attendances[golfwork_id][day].length; i++) {
      if(this.attendances[golfwork_id][day][i]['id'] == employee['id']) {
        const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
        this.attendanceService.delete(this.attendances[golfwork_id][day][i]).then(result => {
          if (result.msgcd=='S') {
            let attendance4msg = this.attendances[golfwork_id][day][i];
            this.attendances[golfwork_id][day].splice(i, 1);
            let golfname = '';
            this.golfworks.forEach(golfwork => {
              if(golfwork.id == golfwork_id) {
                // golfwork.attendance_sum--;
                golfname = golfwork.golf_name_short + golfwork.name;
                return;
              }
            });
            // this.sumByDay[day]['attendance']--;
            // this.attendance_sum_all--;
            //            let existEmployee = false;
            // this.e_attendances.forEach(item => {
            //   if(item.employee_id==employee['employee_id']) {
            //     delete item[day];
            //     item['cnt']--;
            //     return;
            //   }
            // });
  
            // メッセージ送信
            console.log(attendance4msg);
            this.targetYmd.setDate(day);
            let message = UtilityService.getMessageDel(day, golfname, this.user, this.targetYmd);
            this.userService.sendMessageLine4Employee(employee.employee_id, message);
            this.getSlectedAttendanceList(this.targetYm);
          } else {
            this.alertService.error(result.message, true);
          }
          mw.close();
        }).catch(error => {
          mw.close();
          console.log(error);
          this.alertService.error(SysMsg.getMsg('com000_001'), true);
        });
        return;
      }
    }
  }
  openEmployeeSelect(attendances, ymd:string, golfwork_id) {
    let day = (new Date(ymd)).getDate();
    let modalRef = this.modalService.open(EmployeeSelectComponent, {size: 'lg', backdrop: 'static', keyboard: true});
    let component = modalRef.componentInstance as EmployeeSelectComponent;
    console.log(attendances);
    component.day = day;
    if (attendances[golfwork_id][day]) {
      component.attendances = attendances[golfwork_id][day];
    } else {
      component.attendances = [];
    }
    component.e_attendances = [];
    let employees = this.selectedEmployees.filter(employee => {
      return employee['golf_employee_id'] > 0;
    })
    component.employees = employees;
    component.request = this.request_sums[day]['cnt'];
    component.targetYmd = this.targetYmd;
    let golfwork = new Golfwork();
    golfwork.id = golfwork_id;
    this.golfworks.forEach(item => {
      if(item.id == golfwork_id) {
        golfwork.name = item.name;
      }
    });
    golfwork['golf_name'] = this.golfinfo['golf_name'];
    component.golfwork = golfwork;


    modalRef.result.then((result) => {
      console.log(result);
      sessionStorage.setItem('screen_id', 'attendance-list');
      this.attendances[golfwork.id][day] = component.attendances;

      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
      this.getSlectedAttendanceList(this.targetYm);
    }, (reason) => {
      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
      sessionStorage.setItem('screen_id', 'attendance-list');
    });
  }
  openGolfEmployeeList() {
    let modalRef = this.modalService.open(GolfEmployeeListComponent, {size: 'lg', backdrop: 'static', keyboard: true});
    let component = modalRef.componentInstance as GolfEmployeeListComponent;
    component.employees = this.selectedEmployees;
    // component.request = this.request_sums[day]['cnt'];
    let golfwork = new Golfwork();
    golfwork.id = this.golfwork_id;
    this.golfworks.forEach(item => {
      if(item.id == this.golfwork_id) {
        golfwork.name = item.name;
      }
    });
    golfwork['golf_name'] = this.golfinfo['golf_name'];
    golfwork['golf_id'] = this.golfinfo['id'];
    component.golfwork = golfwork;
    this.golfworks.forEach(target => {
      if(target.id == golfwork.id) {
        // component.attendance_sum = target.attendance_sum;
        return;
      }
    });
    // component.sumByDay = this.sumByDay[day]['attendance'];


//    component.day = ('00' + day).slice(-2);
    modalRef.result.then((result) => {
      console.log(result);
      sessionStorage.setItem('screen_id', 'attendance-list');
      // this.attendances[golfwork.id][day] = component.attendances;
      // this.sumByDay[day]['attendance'] = component.sumByDay;
      // this.attendance_sum_all += component.inc;
      this.golfworks.forEach(target => {
        if(target.id == golfwork.id) {
          // target.attendance_sum = component.attendance_sum;
          return;
        }
      });
      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
      this.getSlectedAttendanceList(this.targetYm);
    }, (reason) => {
      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
      sessionStorage.setItem('screen_id', 'attendance-list');
    });
  }

}
