<div class="modal-header">
    <h4>{{golfwork['golf_name']}}　配置可能従業員選択</h4>
  </div>
  <div class="modal-body">
    <fieldset>
        <div class="form-group row">
            <ng-container *ngFor="let item of employees">
              <button class="cbstyle" [ngClass]="{'btn-primary':item['golf_employee_id']>0}" (click)="chkMax(item)">
                {{item.name}}
              </button>
            <!-- <label >
                <input type="checkbox" style="display: none;" [(ngModel)]="item['checked']" >
            </label> -->
            </ng-container>
        </div>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="regist()"><span class="oi oi-cloud-upload"></span>閉じる</button>
</div>
