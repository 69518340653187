import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../service/authentication.service';
import { AlertService } from '../service/alert.service';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string = '/';

  constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private modalService: NgbModal
        ) { }

  ngOnInit() {
    // reset login status
    //this.authenticationService.logout();
    
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (sessionStorage.getItem('screens')) {
      this.router.navigate([this.returnUrl]);
    }
  }
  login() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.authenticationService.login(this.model.username, this.model.password)
      .then(
        data => {
          console.log(data);
          let returnUrl = this.returnUrl;
          let screens = data['screens'] as Screen[];
          let filtered = screens.filter(function (value,index,items) {
            return (returnUrl.indexOf(value['screen_id'])>-1)
          });
  
          if (filtered.length>0) {
            console.log(1);
            this.router.navigate([this.returnUrl]);
          } else {
            // TODO 権限を複数持っている場合はどうするか
            let role = data['user']['roles'].slice(1, -1).split(',');
            console.log(role);
            switch (role[0]) {
              case '1': // システム管理者
                this.router.navigate(['/golf-list']);
                break;
              case '2': // 管理者
                this.router.navigate(['/golf-list']);
                break;
              case '3': // 従業員
                let employee_id = data['user']['e_id'];
                this.router.navigate(['attendance-detail/' + employee_id]);
                break;
              case '4': // ゴルフ場
                let goflid = data['user']['g_id'];
                this.router.navigate(['request-list/' + goflid]);
                break;
              default:
                console.log('default');
                break;
            }
          }
  
//          this.router.navigate([this.returnUrl]);
          mw.close();
        },
        error => {
//          console.log(error);
          this.alertService.error('ログインに失敗しました');
          mw.close();
        });
  }

}
