import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { User } from '../class/user';

@Injectable()
export class UserService {
  user = JSON.parse(sessionStorage.getItem('user'));
  token = sessionStorage.getItem('token');
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }

  initUsr101(): Promise<Result> {
    let params = new HttpParams()
    .set('screen_id', this.screen_id)
    .set('token', this.token);
//    console.log(this.token);
  return this.http.post(this.api_url + 'users/initUsr101', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initUsr102(): Promise<Result> {
    let params = new HttpParams()
    .set('screen_id', this.screen_id)
    .set('token', this.token);
    return this.http.post(this.api_url + 'users/initUsr102', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initUsr103(id:number): Promise<Result> {
    let params = new HttpParams()
      .set('token', this.token)
      .set('screen_id', this.screen_id)
      .set('id', String(id));
    return this.http.post(this.api_url + 'users/initUsr103', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initUsr002(id:number): Promise<Result> {
    let params = new HttpParams()
      .set('token', this.token)
      .set('screen_id', this.screen_id)
      .set('id', String(id));
    return this.http.post(this.api_url + 'users/initUsr002', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(username, password, role): Promise<Result> {
/*    let params = new HttpParams()
      .set('username', user.username)
      .set('name', user.name)
      .set('password', user.password)
      .set('group_id', String(user.group_id))
      .set('email', user.email)
      .set('screen_id', this.screen_id)
      .set('token', this.token);
*/  
    let params = {
      username: username,
      password: password,
      roles : [role],
    }
    
    let headers = new HttpHeaders()
      .set('accept', 'application/ld+json')
      .set('Content-Type', 'application/ld+json');

    const body = {name: 'Brad'};
//    let body = JSON.stringify(user);
    console.log(params);

    return this.http.post(this.api_url + 'api/users', JSON.stringify(params), {headers: headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(user:User): Promise<Result> {
    let params = new HttpParams()
      .set('id', String(user.id))
      .set('username', user.username)
      .set('name', user.name)
//      .set('password', user.password)
      .set('group_id', String(user.group_id))
      .set('email', user.email)
      .set('screen_id', this.screen_id)
      .set('token', this.token);

      if (user.password != '********') {
        params = params.set('password', user.password);
      }

    return this.http.post(this.api_url + 'users/updateforng/'+ String(user.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  updatepass(user:User): Promise<Result> {
    let params = {
      password: user.password,
    }
    
    let headers = new HttpHeaders()
      .set('accept', 'application/ld+json')
      .set('Content-Type', 'application/merge-patch+json')
      .set('Authorization', 'Bearer ' + this.token);


    const body = {name: 'Brad'};
//    let body = JSON.stringify(user);
    console.log(params);

    return this.http.patch(this.api_url + 'api/users/' + String(user.id), JSON.stringify(params), {headers: headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
/*
    let params = new HttpParams()
      .set('id', String(user.id))
      .set('password', user.password)
      .set('screen_id', this.screen_id)
      .set('token', this.token);

    return this.http.post(this.api_url + 'users/updatepassforng/'+ String(user.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
*/
  }
  delete(user:User): Promise<Result> {
    let params = new HttpParams()
      .set('id', String(user.id))
      .set('screen_id', this.screen_id)
      .set('token', this.token);
    return this.http.post(this.api_url + 'users/deleteforng/' + String(user.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  sendMessageLine4Employee(employee_id, message): Promise<Result> {
    this.before();
    let params = new HttpParams()
      .set('employee_id', employee_id)
      .set('message', message)
      .set('screen_id', this.screen_id)
      .set('token', this.token);
    return this.http.post(this.api_url + 'capi/user/sendMessageLine4Employee', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }

}
