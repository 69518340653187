import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-wait-component',
  templateUrl: './modal-wait.component.html',
  styleUrls: ['./modal-wait.component.css']
})
export class ModalWaitComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
