import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
//import * as wjcPdf from 'wijmo/wijmo.pdf';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  doc;
  @ViewChild('prev') prev;
  private previewUrl: SafeResourceUrl;
  ngOnInit() {
  }
  constructor(
    private ref: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {
    const url = sessionStorage.getItem('reportUrl');
    console.log(url);
    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    sessionStorage.setItem('reportUrl', '');
  }
  displayPdfBlob(blob) {
    const url = URL.createObjectURL(blob);
    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
