import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { Result } from '../class/result';
import { Memo } from '../class/memo';
import { UtilityService } from './utility.service';

@Injectable()
export class MemoService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  private subject = new Subject<any>();
  constructor(
    private http: HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  init(datatype_cd: string, data_id: number): Promise<Result> {
    let params = new HttpParams()
    .set('datatype_cd', datatype_cd)
    .set('data_id', String(data_id))
    .set('screen_id', this.screen_id)
    .set('token', this.token);
//    console.log(this.token);
  return this.http.post(this.api_url + 'memos/init', params, {headers: this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(memo: Memo): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('yyyymm', memo.yyyymm)
      .set('dd', memo.dd)
      .set('detail', memo.detail)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id)
      .set('token', this.token);
      
    return this.http.post(this.api_url + 'capi/attendance/addMemoforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(memo:Memo): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(memo.id))
      .set('detail', memo.detail)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', memo.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id)
      .set('token', this.token);

    return this.http.post(this.api_url + 'capi/attendance/updateMemoforng/'+ String(memo.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  delete(memo: Memo): Promise<Result> {
    let params = new HttpParams()
      .set('id', String(memo.id))
      .set('active', '0')
      .set('screen_id', this.screen_id)
      .set('token', this.token);
    return this.http.post(this.api_url + 'memos/updateforng/' + String(memo.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  setMemos(datatype_cd: string, data_id: number) {
    this.init(datatype_cd, data_id).then((result) => {
      console.log(result);
      this.subject.next({datatype_cd: datatype_cd, data_id: data_id, memos: result['data']['datas']});
    }, (reason) => {
    });
  }
  getMemos(): Observable<any> {
    return this.subject.asObservable();
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
