<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div style="width: 100%; height: 600px;">
      <iframe *ngIf="previewUrl" [src]="previewUrl" style="width:100%; height:100%"></iframe>
  </div>
</div>
<div class="modal-footer">
</div>
