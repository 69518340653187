import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { Attendance } from '../class/attendance';
import { User } from '../class/user';
import { UtilityService } from './utility.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  initAttendanceDetail(employee_id): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('employee_id', employee_id)
    .set('screen_id', this.screen_id);
/*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        attendances: [
          {id: 1, name: '城山ゴルフ倶楽部', name_short: '城山', user_id: 0, active: 1, modified: '2020/11/22 18:34:00'},
          {id: 2, name: '花屋敷ゴルフ倶楽部', name_short: '花屋敷', user_id: 0, active: 1, modified: '2020/11/23 18:34:00'}
        ],
        actives: {'1': '1:活動', '0': '0:非活動'}
      };
      return resolve(result);
    });
    */
    return this.http.post(this.api_url + 'capi/attendance/initAttendanceDetail/' + employee_id, params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initEmployeeSelect(yyyymm, dd): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('yyyymm', yyyymm)
    .set('dd', dd)
    .set('screen_id', this.screen_id);
    return this.http.post(this.api_url + 'capi/attendance/initEmployeeSelect', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  getAttendanceDetail(employee_id, target_ym): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('target_ym', target_ym)
    .set('employee_id', employee_id)
    .set('screen_id', this.screen_id);
/*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        attendances: [
          {id: 1, golfwork_id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: 'キャディ', started: '9:00', ended: '17:00', other: 0, rest: 1, remarks: ''}, 
          {id: 2, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 3, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 4, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 5, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 6, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 7, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 8, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 9, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 10, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 11, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 12, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 13, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 14, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 15, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 16, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 17, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 18, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 19, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 20, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 21, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 22, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 23, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 24, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 25, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 26, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 27, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 28, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 29, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 30, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
          {id: 31, golfwork_id: 0, golf_name: '城山ゴルフ場', golf_name_short: '休', name: '休日', started: '', ended: '', other: 0, rest: 0, remarks: ''}, 
        ],
        actives: {'1': '1:活動', '0': '0:非活動'}
      };
      return resolve(result);
    });
    */
    return this.http.post(this.api_url + 'capi/attendance/getAttendanceDetail', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  initAttendanceList(): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('screen_id', this.screen_id);
    return this.http.post(this.api_url + 'capi/attendance/initAttendanceList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  getAttendanceList(target_ym): Promise<Result> {
    this.before();
    let params = new HttpParams()
    .set('target_ym', target_ym)
    .set('screen_id', this.screen_id);

    return this.http.post(this.api_url + 'capi/attendance/getAttendanceList', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }

  add(attendance:Attendance): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('golfwork_id', String(attendance.golfwork_id))
      .set('employee_id', String(attendance.employee_id))
      .set('yyyymm', attendance.yyyymm)
      .set('dd', attendance.dd)
      .set('available_flg', attendance.available_flg)
      .set('commit_flg', attendance.commit_flg)
//      .set('started', attendance.started)
//      .set('ended', attendance.ended)
      .set('started', '')
      .set('ended', '')
      .set('other', String(attendance.other))
      .set('base', String(attendance.base))
      .set('rest', String(attendance.rest))
      .set('remarks', attendance.remarks)
      .set('active', attendance.active)
//      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = 6;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/attendance/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(attendance:Attendance): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(attendance.id))
      .set('golfwork_id', String(attendance.golfwork_id))
      .set('employee_id', String(attendance.employee_id))
      .set('yyyymm', attendance.yyyymm)
      .set('dd', attendance.dd)
      .set('available_flg', attendance.available_flg)
      .set('commit_flg', attendance.commit_flg)
//      .set('started', attendance.started.toLocaleTimeString())
//      .set('ended', attendance.ended.toLocaleTimeString())
      .set('round', String(attendance.round))
      .set('other', String(attendance.other))
      .set('base', String(attendance.base))
      .set('rest', String(attendance.rest))
      .set('remarks', attendance.remarks)
      .set('active', attendance.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', attendance.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);

      if (attendance.started) {
        params = params.set('started', this.getTime(attendance.started));
      } else {
        params = params.set('started', '');
      }
      if (attendance.ended) {
        params = params.set('ended', this.getTime(attendance.ended));
      } else {
        params = params.set('ended', '');
      }

/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = attendance.id;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/attendance/updateforng/'+ String(attendance.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  getTime(target: Date): string {
    if(target) {
      return ('0' + target.getHours()).slice(-2) + ':' + ('0' + target.getMinutes()).slice(-2);
    }
    return '';
  }
  mergeforng(attendance:Attendance): Promise<Result> {
    this.before();
    let modified = new Date();
    let params = new HttpParams()
      .set('golfwork_id', String(attendance.golfwork_id))
      .set('employee_id', String(attendance.employee_id))
      .set('yyyymm', attendance.yyyymm)
      .set('dd', attendance.dd)
      .set('available_flg', attendance.available_flg)
      .set('commit_flg', attendance.commit_flg)
//      .set('started', attendance.started)
//      .set('ended', attendance.ended)
      .set('started', '')
      .set('ended', '')
      .set('other', String(attendance.other))
      .set('base', String(attendance.base))
      .set('rest', String(attendance.rest))
      .set('remarks', attendance.remarks)
      .set('active', attendance.active)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/attendance/mergeforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  delete(attendance:Attendance): Promise<Result> {
    this.before();
    let params = new HttpParams()
      .set('id', String(attendance.id))
      .set('last_modified', attendance.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
      return this.http.post(this.api_url + 'capi/attendance/deleteforng/' + String(attendance.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }

}