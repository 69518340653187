import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule, NgbCheckBox } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UserService } from '../service/user.service';
import { Golfwork } from '../class/golfwork';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '../service/request.service';
import { isTemplateExpression } from 'typescript';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-golf-employee-list',
  templateUrl: './golf-employee-list.component.html',
  styleUrls: ['./golf-employee-list.component.css']
})
export class GolfEmployeeListComponent implements OnInit {
  public day = 1;
  public request = 0;
  // public requests = [];
  public employees = [];
  public golfwork = new Golfwork;
  user = [];
  public inc = 0;

  constructor(
    private alertService: AlertService,
    private requestService: RequestService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('screen_id', 'employee-select');
    this.user = JSON.parse(sessionStorage.getItem('user'));

    // const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    // this.requestService.initGolfEmployeeList(this.golfwork.golf_id).then(result => {
    //   console.log(result);
    //   if (result.msgcd=='S') {
    //     let requestsToday = result['data']['requests'] as Request[];
    //     this.employees.forEach(employee => {
    //       employee['checked'] = false;
    //     });
    
    //   }else if (result.msgcd=='E') {
    //     this.alertService.error(result.message, true);
    //   }else {
    //     this.alertService.error(SysMsg.getMsg('com000_001'), true);
    //   }
    //   console.log(this.employees);
    //   mw.close();
    // }).catch(error => {
    //   if (error.status == 401) {
    //     console.log(error);
    //     this.authenticationService.loginAgain().then(result => {
    //       mw.close();
    //       this.ngOnInit();
    //     }).catch(error => {
    //       console.log(error);
    //       mw.close();
    //     });
    //   } else {
    //     mw.close();
    //     console.log(error);
    //     this.alertService.error(SysMsg.getMsg('com000_001'), true);
    //   }
    // });
    this.employees.forEach(item => {
      if(item.golf_employee_id>0) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    })
  }
  regist() {
    let filtered = this.employees.filter(function (value,index,items) {
      return (value.checked)
    });
    this.activeModal.close(filtered);

  }
  chkMax(item) {
    if (item.golf_employee_id==0) {
      this.add(item);
    } else {
      this.delete(item);
    }

  }
  async add(target) {
    await this.requestService.addGolfEmplyee(this.golfwork.golf_id,target.employee_id).then(result => {
      if (result.msgcd=='S') {
        target['golf_employee_id'] = result.id;

      } else {
        this.alertService.error(result.message, true);
      }
    }).catch(error => {
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });
  }
  async delete(target) {
    console.log(target);
    await this.requestService.deleteGolfEmployee(target['golf_employee_id']).then(result => {
      if (result.msgcd=='S') {
        target['golf_employee_id'] = 0;
      } else {
        this.alertService.error(result.message, true);
      }
    }).catch(error => {
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });

  }
}
