import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule, NgbCheckBox } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { EmployeeService } from '../service/employee.service';
import { UserService } from '../service/user.service';
import { Attendance } from '../class/attendance';
import { Golfwork } from '../class/golfwork';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceService } from '../service/attendance.service';
import { isTemplateExpression } from 'typescript';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-employee-select',
  templateUrl: './employee-select.component.html',
  styleUrls: ['./employee-select.component.css']
})
export class EmployeeSelectComponent implements OnInit {

  public day = 1;
  public request = 0;
  public attendances = [];
  public e_attendances = [];
  public employees = [];
  public golfwork = new Golfwork;
  public targetYmd = new Date();
  public attendance_sum = 0;
  public sumByDay = 0;
  public dispTargetYm = '';
  user = [];
  public inc = 0;

  constructor(
    private alertService: AlertService,
    private attendanceService: AttendanceService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('screen_id', 'employee-select');
    this.user = JSON.parse(sessionStorage.getItem('user'));

    console.log(this.golfwork);
    let yyyymm = String(this.targetYmd.getFullYear()) + ('00' + (this.targetYmd.getMonth()+1)).slice(-2);
    console.log(yyyymm);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2) + '/' + ('00' + this.day).slice(-2);
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.attendanceService.initEmployeeSelect(yyyymm, this.day).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        let attendancesToday = result['data']['attendances'] as Attendance[];
        console.log(this.employees.length);
        this.employees.forEach(employee => {
          employee['checked'] = false;
          this.attendances.forEach(attendance => {
            if (attendance.employee_id == employee.employee_id) {
              employee['checked'] = true;
              employee['commit_flg'] = attendance.commit_flg;
              employee['available_flg'] = attendance.available_flg;
              return;
            }
          });
          attendancesToday.forEach(attendance => {
            if (attendance.employee_id == employee.employee_id) {
              employee['available_flg'] = attendance.available_flg;
              if (this.golfwork.id == attendance.golfwork_id) {
                employee['golfwork_id'] = '0';
              } else {
                employee['golfwork_id'] = attendance.golfwork_id;
              } 
              return;
            }
          });
        });
    
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      console.log(this.employees);
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        console.log(error);
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  chkMax(item) {
    let filtered = this.employees.filter(function (value,index,items) {
      return (value.checked)
    });
    // 上限なしに設定
    /*
    console.log(filtered.length);
    console.log(item);
    if (this.request < filtered.length) {
      item.checked = false;
    }*/
    if (item.checked) {
      this.add(item);
    } else {
      let target = item;
      this.attendances.forEach(target => {
        if(item.employee_id==target.employee_id) {
          this.delete(target);
          return;
        }
      });
    }

  }
  regist() {
    let filtered = this.employees.filter(function (value,index,items) {
      return (value.checked)
    });
    this.activeModal.close(filtered);

  }
  async add(target) {
    console.log(target);
    let attendance = new Attendance();
    attendance.golfwork_id = this.golfwork.id;
    attendance.employee_id = target['employee_id'];
    attendance['name'] = target['name'];
    attendance['name_short'] = target['name_short'];
    attendance.yyyymm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    attendance.dd = String(this.day);
    await this.attendanceService.mergeforng(attendance).then(result => {
      if (result.msgcd=='S') {
        attendance['id'] = result.id;
        attendance['modified'] = result['modified'];
        this.attendances.push(attendance);
        this.attendance_sum++;
        this.sumByDay++;
        this.inc++;
        // 従業員別サマリー表に追加
        let existEmployee = false;
        this.e_attendances.forEach(item => {
          if(item.employee_id==attendance.employee_id) {
            item[this.day] = attendance;
            item['cnt']++;
            existEmployee = true;
            return;
          }
        });
        if (!existEmployee) {
          let ob = {
            name:attendance['name'],
            employee_id: attendance.employee_id,
            cnt: 1,
          };
          ob[this.day] = attendance;
          this.e_attendances.push(ob);
        }

        // メッセージ送信
        let message = this.getMessageDropEmploy(attendance, this.golfwork['golf_name']+this.golfwork.name);
        this.userService.sendMessageLine4Employee(attendance.employee_id, message);
      } else {
        this.alertService.error(result.message, true);
        target['checked'] = false;
      }
      console.log(this.attendances);
    }).catch(error => {
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });
  }
  async delete(target) {
    console.log(target);
    await this.attendanceService.delete(target).then(result => {
      if (result.msgcd=='S') {
        this.attendances.forEach((item, index) => {
          console.log(item);
          if(item==target) {

            this.attendances.splice(index, 1);
          }
        });
        this.attendance_sum--;
        this.sumByDay--;
        this.inc--;
        this.e_attendances.forEach(item => {
          if(item.employee_id==target['employee_id']) {
            delete item[this.day];
            item['cnt']--;
//                existEmployee = true;
            return;
          }
        });

        // メッセージ送信
        let message = this.getMessageDel(this.day, this.golfwork['golf_name']+this.golfwork.name);
        this.userService.sendMessageLine4Employee(target['employee_id'], message);
      } else {
        this.alertService.error(result.message, true);
      }
      console.log(this.attendances);
    }).catch(error => {
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
    });

  }
  getMessageDropEmploy(attendance: Attendance, golfname) {
    let ymd = attendance.yyyymm.slice(0,4) + '/' + attendance.yyyymm.slice(-2) + '/' + ('00' + attendance.dd).slice(-2);
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + golfname + ' ' + 'に設定';
  }
  getMessageDel(day, golfname) {
    let ymd = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2) + '/' + ('00' + day).slice(-2);
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + golfname + ' ' + 'を解除';
  }

}
