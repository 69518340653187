import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UtilityService } from '../service/utility.service';
import { AttendanceService } from '../service/attendance.service';
import { AuthenticationService } from '../service/authentication.service';
import { UserService } from '../service/user.service';
import { Attendance } from '../class/attendance';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { Employee } from '../class/employee';
import { AttendanceModComponent } from "../attendance-mod/attendance-mod.component";

@Component({
  selector: 'app-attendance-detail',
  templateUrl: './attendance-detail.component.html',
  styleUrls: ['./attendance-detail.component.css']
})
export class AttendanceDetailComponent implements OnInit {
  employee_id = 0;
  employee = new Employee();
  targetYmd = new Date();
  dispTargetYm = '';
  sumRound = 0;
  cntWork = 0;
  sumOuter = 0;
  sumBase = 0;
  cntAttend = 0;
  cntRest = 0;
  cntGolfworks = [];

  type_cds: any;
  type_cds_cb = [];

  user = [];
  weeks = [
    {week_cd: 'Mon', name: '月'},
    {week_cd: 'Tue', name: '火'},
    {week_cd: 'Wed', name: '水'},
    {week_cd: 'Thu', name: '木'},
    {week_cd: 'Fri', name: '金'},
    {week_cd: 'Sat', name: '土'},
    {week_cd: 'Sun', name: '日'},
  ];
  weeks2 = [];
  calendar = [
    [{}, {}, {}, {ymd:'2020/12/01'}, {ymd:'2020/12/02'}, {ymd:'2020/12/03'}, {ymd:'2020/12/04'}],
    [{ymd:'2020/12/05'}, {ymd:'2020/12/06'}, {ymd:'2020/12/07'}, {ymd:'2020/12/08'}, {ymd:'2020/12/09'}, {ymd:'2020/12/10'}, {ymd:'2020/12/11'}],
    [{ymd:'2020/12/12'}, {ymd:'2020/12/13'}, {ymd:'2020/12/14'}, {ymd:'2020/12/15'}, {ymd:'2020/12/16'}, {ymd:'2020/12/17'}, {ymd:'2020/12/18'}],
    [{ymd:'2020/12/19'}, {ymd:'2020/12/20'}, {ymd:'2020/12/21'}, {ymd:'2020/12/22'}, {ymd:'2020/12/23'}, {ymd:'2020/12/24'}, {ymd:'2020/12/25'}],
    [{ymd:'2020/12/26'}, {ymd:'2020/12/27'}, {ymd:'2020/12/28'}, {ymd:'2020/12/29'}, {ymd:'2020/12/30'}, {ymd:'2020/12/31'}, {}],
  ];
  attendances = [];
  golfworks = [
    {id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: 'キャディ'},
    {id: 2, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '作業'},
    {id: 3, golf_name: 'サイプレイス', golf_name_short: 'サイ', name: 'キャディ'},
    {id: 4, golf_name: '花屋敷', golf_name_short: '花屋敷', name: 'ひろの'},
    {id: 5, golf_name: '花屋敷', golf_name_short: '花屋敷', name: 'よかわ'},
    {id: 6, golf_name: '小野', golf_name_short: '小野', name: 'キャディ'},
    {id: 7, golf_name: '加古川', golf_name_short: '加古川', name: 'キャディ'},
    {id: 8, golf_name: '三木', golf_name_short: '三木', name: 'キャディ'},
    {id: 9, golf_name: 'サンロイヤル', golf_name_short: 'サンロイヤル', name: 'キャディ'},
    {id: 10, golf_name: '東広野', golf_name_short: '東広野', name: 'キャディ'},
    {id: 11, golf_name: 'ゴールデン', golf_name_short: 'ゴールデン', name: 'キャディ'},
    {id: 12, golf_name: 'パイン', golf_name_short: 'パイン', name: 'キャディ'},
    {id: 13, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', name: 'キャディ'},
    {id: 14, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', name: 'マスター'},
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private attendanceService: AttendanceService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'attendance-detail');
    this.authenticationService.setDispName('スケジュール');
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.route.params.subscribe(params => {
      this.employee_id = params['id'];
    });
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.targetYmd.setDate(1);
    this.attendanceService.initAttendanceDetail(this.employee_id).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.employee = result['data']['employee'];
        this.type_cds = result['data']['type_cds'];
        for (var x in this.type_cds) {
          this.type_cds_cb.push({cd:x, name:this.type_cds[x]});
        }
        this.getAttendanceDetail();
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  getAttendanceDetail(): void {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.cntRest = 0;
    this.cntAttend = 0;
    this.sumBase = 0;
    this.sumOuter = 0;
    this.cntWork = 0;
    this.sumRound = 0;
    this.cntGolfworks = [];
    this.attendanceService.getAttendanceDetail(this.employee_id, this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2)).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.calendar = result['data']['calendar'];
        this.attendances = result['data']['attendances'] as Attendance[];
        this.golfworks = result['data']['golfworks'];
        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
        for(let i=0;i<this.attendances.length;i++) {
          let dt = new Date(this.dispTargetYm + '/' + (i+1));
          this.weeks2[i] = dt.getDay();
          this.attendances[i]['type_name'] = this.type_cds[this.attendances[i]['type_cd']];
          if (this.attendances[i]['started']) {
            this.attendances[i]['started'] = new Date('2000/1/1 ' + this.attendances[i]['started']);
          }
          if (this.attendances[i]['ended']) {
            this.attendances[i]['ended'] = new Date('2000/1/1 ' + this.attendances[i]['ended']);
          }
          this.sumBase += Number(this.attendances[i]['base']);
          this.sumOuter += Number(this.attendances[i]['other']);
          this.sumRound += Number(this.attendances[i]['round']);
        }
        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
        for(let i=0;i<this.attendances.length;i++) {
          let dt = new Date(this.dispTargetYm + '/' + (i+1));
          this.weeks2[i] = dt.getDay();
          if (this.attendances[i]['golfwork_id'] > 0) {
            if(this.cntGolfworks[this.attendances[i]['golfwork_id']]) {
              this.cntGolfworks[this.attendances[i]['golfwork_id']]++;
            } else {
              this.cntGolfworks[this.attendances[i]['golfwork_id']] = 1;
            }
            if (this.attendances[i]['golfwork_id'] > 900) {
              this.cntWork++;
/*            } else {
              if (this.attendances[i]['golfwork_id'] != 14) {
                this.cntCady++;
              } */
            }
            this.cntAttend++;
          } else {
            this.cntRest++;
          }
        }
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  prevYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() - 1);
//    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceDetail();

  }
  nextYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() + 1);
//    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceDetail();

  }
  changeAvailableFlg(attendance: Attendance, event) {
    event.target.disabled = true;
    attendance.available_flg = attendance.available_flg=='1'?'0':'1';
    if (attendance.id == 0) {
      attendance['started'] = new Date('2000/1/1 00:00');
      attendance['ended'] = new Date('2000/1/1 00:00');
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.attendanceService.add(attendance).then(result => {
        console.log(result);
        if (result.msgcd=='S') {
          attendance.id = result.id;
          attendance.modified = result['modified'];
          let message = this.getMessageAvailableChange(attendance);
          this.userService.sendMessageLine4Employee(this.employee_id, message);
          this.alertService.success(result.message, false);
        }else {
          // エラーだった場合、可否を戻す
          attendance.available_flg = attendance.available_flg=='1'?'0':'1';
          this.alertService.error(result.message, false);
        }
        event.target.disabled = false;
        mw.close();
      }).catch(error => {
        event.target.disabled = false;
        mw.close();
      });
  
    } else {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.attendanceService.update(attendance).then(result => {
        if (result.msgcd=='S') {
          attendance.modified = result['modified'];
          this.alertService.success(result.message, false);
          let message = this.getMessageAvailableChange(attendance);
          this.userService.sendMessageLine4Employee(this.employee_id, message);
        }else {
          // エラーだった場合、可否を戻す
          attendance.available_flg = attendance.available_flg=='1'?'0':'1';
          this.alertService.error(result.message, false);
        }
        event.target.disabled = false;
        mw.close();
      }).catch(error => {
        event.target.disabled = false;
        mw.close();
      });
    }
  }
  changeCommitFlg(attendance: Attendance) {
    if (attendance.golfwork_id == 0) {
      return;
    }
    attendance.commit_flg = attendance.commit_flg=='1'?'0':'1';
    if (attendance.id == 0) {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.attendanceService.add(attendance).then(result => {
        console.log(result);
        if (result.msgcd=='S') {
          attendance.id = result.id;
          attendance.modified = result['modified'];
          this.alertService.success(result.message, false);
          let message = this.getMessageCommitChange(attendance);
          this.userService.sendMessageLine4Employee(this.employee_id, message);
        }else {
          // エラーだった場合、可否を戻す
          attendance.commit_flg = attendance.commit_flg=='1'?'0':'1';
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        mw.close();
      });
  
    } else {
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      this.attendanceService.update(attendance).then(result => {
        if (result.msgcd=='S') {
          attendance.modified = result['modified'];
          this.alertService.success(result.message, false);
          let message = this.getMessageCommitChange(attendance);
          this.userService.sendMessageLine4Employee(this.employee_id, message);
        }else {
          // エラーだった場合、可否を戻す
          attendance.commit_flg = attendance.commit_flg=='1'?'0':'1';
          this.alertService.error(result.message, false);
        }
        mw.close();
      }).catch(error => {
        mw.close();
      });
    }
  }
  getMessageCommitChange(attendance: Attendance) {
    let ymd = attendance.yyyymm.slice(0,4) + '/' + attendance.yyyymm.slice(-2) + '/' + ('00' + attendance.dd).slice(-2);
    let commit = attendance.commit_flg=='1'?'シフト確定':'シフト未確定';
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + attendance['golf_name'] + ' ' + commit + 'に変更';
  }
  getMessageAvailableChange(attendance: Attendance) {
    let ymd = attendance.yyyymm.slice(0,4) + '/' + attendance.yyyymm.slice(-2) + '/' + ('00' + attendance.dd).slice(-2);
    let commit = attendance.available_flg=='1'?'出勤可':'出勤不可';
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + attendance['golf_name'] + ' ' + commit + 'に変更';
  }
  openAttendanceMod (target: Attendance){
    if(target.golfwork_id == 0) {
      return;
    }
//    this.currentItem = this.data.currentItem;
    let modalRef = this.modalService.open(AttendanceModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as AttendanceModComponent;
    let attendance = new Attendance();
    attendance.id = target.id;
    attendance.employee_id = target.employee_id;
    attendance.golfwork_id = target.golfwork_id;
    attendance.golf_name = target.golf_name;
    attendance.yyyymm = target.yyyymm;
    attendance.modified = target.modified;
    attendance.dd = target.dd;
    attendance.started = target.started;
    if (attendance.started == new Date('2000/1/1 ' + '00:00:00')) {
      attendance.started = new Date('2000/1/1 ' + '06:30:00')
    }
    attendance.ended = target.ended;
    if (attendance.ended == new Date('2000/1/1 ' + '00:00:00')) {
      attendance.ended = new Date('2000/1/1 ' + '14:00:00')
    }
    attendance.round = Number(target.round);
    if (attendance.round == 0) {
      if (attendance.golfwork_id > 0 && attendance.golfwork_id < 900) {
        attendance.round = 1.0;
      } 
    }
    
    attendance.other = Number(target.other);
    attendance.rest = Number(target.rest);
    if (attendance.rest == 0) {
      attendance.rest = 1.0;
    }
    attendance.base = target.base;
    attendance.available_flg = target.available_flg;
    attendance.commit_flg = target.commit_flg;
    attendance.remarks = target.remarks;
    component.currentItem = attendance;
    component.calc();

    modalRef.result.then((result) => {
      let index = Number(result['dd']) - 1;
      this.attendances[index]['started'] = result['started'];
      this.attendances[index]['ended'] = result['ended'];
      this.attendances[index]['base'] = result['base'];
      this.attendances[index]['round'] = result['round'];
      this.attendances[index]['other'] = result['other'];
      this.attendances[index]['rest'] = result['rest'];
      this.attendances[index]['remarks'] = result['remarks'];
      this.attendances[index]['modified'] = result['modified'];
      sessionStorage.setItem('screen_id', 'golf-list');
      this.sumBase = 0;
      this.sumOuter = 0;
      this.sumRound = 0;
      for(let i=0;i<this.attendances.length;i++) {
        this.sumBase += Number(this.attendances[i]['base']);
        this.sumOuter += Number(this.attendances[i]['other']);
        this.sumRound += Number(this.attendances[i]['round']);      }
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'golf-list');
    });
  }

}
