<div class="modal-header">
  <p>{{title}}</p>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('OK click')">OK</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
</div>
