<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .spacer {
    flex: 1;
  }

  .toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #1976d2;
    color: white;
    font-weight: 600;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .toolbar #twitter-logo {
    height: 40px;
    margin: 0 16px;
  }

  .toolbar #twitter-logo:hover {
    opacity: 0.8;
  }

  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
  }

  .card {
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
  }

  .card-container .card:not(:last-child) {
    margin-right: 0;
  }

  .card.card-small {
    height: 16px;
    width: 168px;
  }

  .card-container .card:not(.highlight-card) {
    cursor: pointer;
  }

  .card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }

  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.highlight-card {
    background-color: #1976d2;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
  }

  svg#rocket-smoke {
    height: calc(100vh - 95px);
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
  }

  .terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
  }

  .terminal pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  .circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
  }

  .circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  .github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }

  .github-star-badge:hover {
    background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
    border-color: rgba(27,31,35,.35);
    background-position: -.5em;
  }

  .github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
  }


  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container > *:not(.circle-link) ,
    .terminal {
      width: 100%;
    }

    .card:not(.highlight-card) {
      height: 16px;
      margin: 8px 0;
    }

    .card.highlight-card span {
      margin-left: 72px;
    }

    svg#rocket-smoke {
      right: 120px;
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 575px) {
    svg#rocket-smoke {
      display: none;
      visibility: hidden;
    }
  }
  
</style>
<nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color:#62ac4f;color:#F2F2F2">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" style="padding-left: 30px;">g1japan - {{dispName}}</a>
  <div class="navbar-collapse">
<!--
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>-->
  </div>
</nav>
<!--
<nav class="navbar navbar-toggleable-md navbar-light" style="background-color:#62ac4f;color:#F2F2F2">
  <button class="navbar-toggler navbar-toggler-right" type="button" ngbDropdown >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#">g1japan</a>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="nav navbar-nav" style="top:1rem;position:absolute;">
      <li> - {{dispName}}</li>
    </ul>
    <ul class="nav navbar-nav" style="top:1rem;right: 1rem;position:absolute;">
      <li class="nav-item" ngbDropdown *ngIf="user!=null">
        <button class="btn" style="background-color:#10A96C;color:#F2F2F2;margin-top:-5px;" id="dropdownBasic1" ngbDropdownToggle><span class="oi oi-person"></span>{{user.name}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="font-size:smaller;">
          <button class="dropdown-item" (click)="navLinkTo('/usr002/'+user.id)">パスワード変更</button>
          <button class="dropdown-item" (click)="navLinkTo('/logout')">ログアウト</button>
        </div>
      </li>
    </ul>
  </div>
</nav>-->
<div class="container-fluid">

<!--
  <div class="col-2 side-color" *ngIf="items.length>0">
    <ngb-tabset>
      <ngb-tab style="background:#F2F2F2">
        <ng-template ngbTabTitle><span class="oi oi-menu"></span>メニュー</ng-template>
        <ng-template ngbTabContent >
          <wj-tree-view #tv (valueChanged)="itemClicked(selected)"
                [itemsSource]="items"
                [displayMemberPath]="'header'"
                [childItemsPath]="'items'"
                [isAnimated]="isAnimated"
                [autoCollapse]="autoCollapse"
                [expandOnClick]="expandOnClick"
                (itemClicked)="navTo(tv)">
          </wj-tree-view>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
-->
  <div class="row">
    <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" [(ngModel)]="chkSidebarMenu">
    <label *ngIf="user!=null" for="openSidebarMenu" class="sidebarIconToggle">
      <div class="spinner diagonal part-1"></div>
      <div class="spinner horizontal"></div>
      <div class="spinner diagonal part-2"></div>
    </label>
    <div id="sidebarMenu">
      <wj-tree-view #theTree
      [itemsSource]='items'
      [displayMemberPath]="'header'"
      [childItemsPath]="'items'"
      [isAnimated]="true"
      [isContentHtml]=true
      [autoCollapse]=true
      (itemClicked)="navTo(theTree)" class="accordion">
    </wj-tree-view>
    <!--
      <wj-tree-view #tv (valueChanged)="itemClicked(selected)"
        [itemsSource]="items"
        [displayMemberPath]="'header'"
        [childItemsPath]="'items'"
        [isAnimated]="isAnimated"
        [autoCollapse]="autoCollapse"
        [expandOnClick]="expandOnClick"
        (itemClicked)="navTo(tv)">
      </wj-tree-view>
      <ul class="sidebarMenuInner">
        <li>ログイン<span *ngIf="user!=null">{{user.name}}</span></li>
        <li><a href="https://vanila.io" target="_blank">Company</a></li>
        <li><a href="https://instagram.com/plavookac" target="_blank">Instagram</a></li>
        <li><a href="https://twitter.com/plavookac" target="_blank">Twitter</a></li>
        <li><a (click)="navLinkTo('/usr002/'+user.id)">パスワード変更</a></li>
        <li><a (click)="navLinkTo('/logout')">ログアウト</a></li>
      </ul>
-->
    </div>
    <main class="col-12" style="padding-top:10px">
      <alert></alert>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>