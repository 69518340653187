import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { UtilityService } from '../service/utility.service';
import { RequestService } from '../service/request.service';
import { AuthenticationService } from '../service/authentication.service';
import { Request } from '../class/request';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css']
})
export class RequestViewComponent implements OnInit {
  golf_id = 0;
  golfwork_id = 0;
  golfwork_name = '';
  day = '';
  currentItem = new Request();
  targetYmd = new Date();
  dispTargetYm = '';
  cntCady = 0;
  cntWork = 0;
  sumOuter = 0;
  cntAttend = 0;
  cntRest = 0;
  cntGolfworks = [];
  dds = [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  pDd = '';

  actives = [];
  actives_cb = [];
  type_cds = {'10': 'キャディ', '20': '作業'};
  type_cds_cb = [];

  endInitProc = false;
  weeks = [
    {week_cd: 'Mon', name: '月'},
    {week_cd: 'Tue', name: '火'},
    {week_cd: 'Wed', name: '水'},
    {week_cd: 'Thu', name: '木'},
    {week_cd: 'Fri', name: '金'},
    {week_cd: 'Sat', name: '土'},
    {week_cd: 'Sun', name: '日'},
  ];
  weeks2 = [];
  calendar = [
    [{}, {}, {}, {ymd:'2020/12/01', d:'1'}, {ymd:'2020/12/02', d:'2'}, {ymd:'2020/12/03', d:'3'}, {ymd:'2020/12/04', d:'4'}],
    [{ymd:'2020/12/05', d:'5'}, {ymd:'2020/12/06', d:'6'}, {ymd:'2020/12/07', d:'7'}, {ymd:'2020/12/08', d:'8'}, {ymd:'2020/12/09', d:'9'}, {ymd:'2020/12/10', d:'10'}, {ymd:'2020/12/11', d:'11'}],
    [{ymd:'2020/12/12', d:'12'}, {ymd:'2020/12/13', d:'13'}, {ymd:'2020/12/14', d:'14'}, {ymd:'2020/12/15', d:'15'}, {ymd:'2020/12/16', d:'16'}, {ymd:'2020/12/17', d:'17'}, {ymd:'2020/12/18', d:'18'}],
    [{ymd:'2020/12/19', d:'19'}, {ymd:'2020/12/20', d:'20'}, {ymd:'2020/12/21', d:'21'}, {ymd:'2020/12/22', d:'22'}, {ymd:'2020/12/23', d:'23'}, {ymd:'2020/12/24', d:'24'}, {ymd:'2020/12/25', d:'25'}],
    [{ymd:'2020/12/26', d:'26'}, {ymd:'2020/12/27', d:'27'}, {ymd:'2020/12/28', d:'28'}, {ymd:'2020/12/29', d:'29'}, {ymd:'2020/12/30', d:'30'}, {ymd:'2020/12/31', d:'31'}, {}],
  ];
  capacity_sums = {
    1: {capa_all: 40, capa_caddy: 30}, 
    2: {capa_all: 20, capa_caddy: 10}, 
    3: {capa_all: 20, capa_caddy: 10}, 
    4: {capa_all: 20, capa_caddy: 10}, 
    5: {capa_all: 20, capa_caddy: 10}, 
    6: {capa_all: 20, capa_caddy: 10}, 
    7: {capa_all: 20, capa_caddy: 10}, 
    8: {capa_all: 20, capa_caddy: 10}, 
    9: {capa_all: 20, capa_caddy: 10}, 
   10: {capa_all: 20, capa_caddy: 10}, 
   11: {capa_all: 20, capa_caddy: 10}, 
   12: {capa_all: 20, capa_caddy: 10}, 
   13: {capa_all: 20, capa_caddy: 10}, 
   14: {capa_all: 20, capa_caddy: 10}, 
   15: {capa_all: 20, capa_caddy: 10}, 
   16: {capa_all: 20, capa_caddy: 10}, 
   17: {capa_all: 20, capa_caddy: 10}, 
   18: {capa_all: 20, capa_caddy: 10}, 
   19: {capa_all: 20, capa_caddy: 10}, 
   20: {capa_all: 20, capa_caddy: 10}, 
   21: {capa_all: 20, capa_caddy: 10}, 
   22: {capa_all: 20, capa_caddy: 10}, 
   23: {capa_all: 20, capa_caddy: 10}, 
   24: {capa_all: 20, capa_caddy: 10}, 
   25: {capa_all: 20, capa_caddy: 10}, 
   26: {capa_all: 20, capa_caddy: 10}, 
   27: {capa_all: 20, capa_caddy: 10}, 
   28: {capa_all: 20, capa_caddy: 10}, 
   29: {capa_all: 20, capa_caddy: 10}, 
   30: {capa_all: 20, capa_caddy: 10}, 
   31: {capa_all: 20, capa_caddy: 10}, 
  };
  request_sums = {
    1: {cnt: 2}, 
    2: {cnt: 2}, 
    3: {cnt: 4}, 
    4: {cnt: 0}, 
    5: {cnt: 0}, 
    6: {cnt: 0}, 
    7: {cnt: 0}, 
    8: {cnt: 0}, 
    9: {cnt: 0}, 
//   10: {cnt: 0}, 
   11: {cnt: 0}, 
   12: {cnt: 0}, 
   13: {cnt: 0}, 
   14: {cnt: 0}, 
   15: {cnt: 0}, 
   16: {cnt: 0}, 
   17: {cnt: 0}, 
   18: {cnt: 0}, 
   19: {cnt: 0}, 
   20: {cnt: 0}, 
   21: {cnt: 0}, 
   22: {cnt: 0}, 
   23: {cnt: 0}, 
   24: {cnt: 0}, 
   25: {cnt: 0}, 
   26: {cnt: 0}, 
   27: {cnt: 0}, 
   28: {cnt: 0}, 
   29: {cnt: 0}, 
   30: {cnt: 0}, 
   31: {cnt: 0}, 
  };
  work_sums = {
    1: {cnt: 1}, 
    2: {cnt: 0}, 
    3: {cnt: 0}, 
    4: {cnt: 0}, 
    5: {cnt: 1}, 
    6: {cnt: 1}, 
    7: {cnt: 0}, 
    8: {cnt: 0}, 
    9: {cnt: 0}, 
   10: {cnt: 0}, 
   11: {cnt: 0}, 
   12: {cnt: 0}, 
   13: {cnt: 0}, 
   14: {cnt: 0}, 
   15: {cnt: 0}, 
   16: {cnt: 0}, 
   17: {cnt: 0}, 
   18: {cnt: 0}, 
   19: {cnt: 0}, 
   20: {cnt: 0}, 
   21: {cnt: 0}, 
   22: {cnt: 0}, 
   23: {cnt: 0}, 
   24: {cnt: 0}, 
   25: {cnt: 0}, 
   26: {cnt: 0}, 
   27: {cnt: 0}, 
   28: {cnt: 0}, 
   29: {cnt: 0}, 
   30: {cnt: 0}, 
   31: {cnt: 0}, 
  };
  requests: Request[] = [];
  golfworks = [
    {id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '城山キャディ'},
//    {id: 2, golf_name: '城山ゴルフ場', golf_name_short: '城山', name: '城山作業'},
  ];

  golfinfo = [];

  constructor(
    private authenticationService: AuthenticationService,
    private requestService: RequestService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'request-view');
    this.targetYmd.setDate(1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getRequest();
  }
  getRequest(): void {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    let targetYm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.requestService.getRequestList(this.golfwork_id, targetYm).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        console.log('suc');
        this.requests = result['data']['requests'] as Request[];
        this.calendar = result['data']['calendar'];
        this.capacity_sums = result['data']['capacity_sums'];
        this.request_sums = result['data']['request_sums'];
        this.work_sums = result['data']['work_sums'];
        this.golfinfo =  result['data']['golfinfo'];
        
        this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);

        this.pDd = this.day;
        this.endInitProc = true;
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      console.log(error);
      this.alertService.error(SysMsg.getMsg('com000_001'), true);
      mw.close();
    });
  }
  changeGolfwork() {
    if (!this.endInitProc) {
      return;
    }
    this.getRequest();
  }
  prevYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() - 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getRequest();

  }
  nextYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() + 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getRequest();

  }

}
