<!-- a dialog for editing item details -->
<div class="modal-header">
    <h4 class="modal-title">{{dispTargetYmd}} メモの<label>{{currentItem.id!=0?'編集':'登録'}}</label></h4>
  </div>
  <div class="modal-body">
    <fieldset>
      <form  #f="ngForm">
        <div class="form-group row" [ngClass]="{'has-danger':vdetail.errors && vdetail.dirty}">
          <div class="col-12">
            <textarea class="form-control" rows="10" id="vdetail" name="vdetail" [(ngModel)]="currentItem.detail" #vdetail="ngModel" (ngModelOptions)="{updateOn: 'blur'}" maxlength="300"></textarea>
            <span style="color:red" [hidden]="!vdetail.dirty || (!vdetail.errors?.maxlength)">300文字以内で入力してください</span>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button *ngIf="currentItem.id==0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="regist()"><span class="oi oi-cloud-upload"></span>登録</button>
    <button *ngIf="currentItem.id!=0" type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="update()"><span class="oi oi-cloud-upload"></span>更新</button>
    <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('Cross click')"><span class="oi oi-action-undo"></span>キャンセル</button>
  </div>
