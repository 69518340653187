<div class="modal-header">
    <h4 class="modal-title">ゴルフ場別コース一覧</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <form  #f="ngForm">
            <label class="col-2">コース名</label>
            <input class="col-3" type="text" id="vname" name="vname" [(ngModel)]="newItem.name" required>
            <label class="col-2">備考</label>
            <input class="col-3" type="text" id="vremarks" name="vremarks" [(ngModel)]="newItem.remarks">
            <button (click)="add()" class="btn btn-sm btn-primary" [disabled]="!f.form.valid"><span class="oi oi-plus"></span>追加
            </button>
        </form>
    </div>
    <label style="float:right">{{data?.items.length}}　件</label>
    <wj-flex-grid class="grid" #flexGrid [itemsSource]="data" [selectionMode]="'Row'" [isReadOnly]="true" style="height:360px;" >
    <!--      <wj-flex-grid-filter #filter [filterColumns]="['name', 'name_short']"></wj-flex-grid-filter>-->
        <wj-flex-grid-column [isReadOnly]="true" [width]="60">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <div>
            <button class="btn btn-sm btn-success" (click)="edit(content)"><span class="oi oi-pencil"></span>更新</button>
            </div>
        </ng-template> 
        </wj-flex-grid-column>
<!--        <wj-flex-grid-column header="最終更新日時" [binding]="'modified'" [width]="180">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <div style="text-align:center">最終更新日時</div>
        </ng-template>
        </wj-flex-grid-column>
        -->
        <wj-flex-grid-column header="作業名" [binding]="'name'" [width]="200">
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                <div style="text-align:center">コース名</div>
            </ng-template>
        </wj-flex-grid-column> 
    <!--
        <wj-flex-grid-column header="作業名（略称）" [binding]="'name_short'" [width]="'*'">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <div style="text-align:center">作業名（略称）</div>
        </ng-template>
        </wj-flex-grid-column> 
        <wj-flex-grid-column header="種類" [binding]="'type_name'" [width]="100">
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                <div style="text-align:center">種類</div>
            </ng-template>
        </wj-flex-grid-column> 
    -->
        <wj-flex-grid-column header="備考" [binding]="'remarks'" [width]="'*'">
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                <div style="text-align:center">備考</div>
            </ng-template>
        </wj-flex-grid-column> 
        <wj-flex-grid-column header="活動区分" [binding]="'active_name'" [width]="100">
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                <div style="text-align:center">活動区分</div>
            </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [width]="60" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            <button type="button" class="btn btn-default" aria-label="Left Align" (click)="exportExcel()">
            <span class="oi oi-data-transfer-download"></span>
            </button>
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <div>
            <button class="btn btn-sm btn-danger" [disabled]="loading" (click)="delete(cell.item)"><span class="oi oi-trash"></span>削除</button>
            </div>
        </ng-template>
        </wj-flex-grid-column>
    </wj-flex-grid>
</div>
<!-- a dialog for editing item details -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">ゴルフ場別コースの編集</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <fieldset>
        <form  #f="ngForm">
          <div class="form-group row">
            <label class="col-form-label col-2 lbl-bc">コース名</label>
            <div class="col-7">
              <input type="text" class="form-control col-12" id="vname" name="vname" [(ngModel)]="currentItem.name" #vname="ngModel" maxlength="20" required/>
              <span style="color:red" [hidden]="!vname.dirty || (!vname.errors?.maxlength)">20文字以内で入力してください</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-2 lbl-bc">備考</label>
            <div class="col-7">
              <input type="text" class="form-control col-12" id="vremarks" name="vremarks" [(ngModel)]="currentItem.remarks" #vremarks="ngModel" maxlength="50"/>
              <span style="color:red" [hidden]="!vremarks.dirty || (!vremarks.errors?.maxlength)">50文字以内で入力してください</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="vactive" class="col-form-label col-3 lbl-bc">活動区分</label>
            <div class="btn-group btn-group-toggle col-3" ngbRadioGroup name="radioBasic" [(ngModel)]="currentItem.active">
              <label ngbButtonLabel [ngClass]="{'btn-primary':currentItem.active==a.cd,'btn-outline-primary':currentItem.active!=a.cd}" *ngFor="let a of actives_cb">
                <input ngbButton type="radio" [value]="a.cd"> {{a.name}}
              </label>
            </div>
          </div>
        </form>
      </fieldset>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="!f.form.valid" (click)="c('OK click')"><span class="oi oi-cloud-upload"></span>更新</button>
      <button type="button" class="btn btn-warning" (click)="d('Cancel click')"><span class="oi oi-action-undo"></span>キャンセル</button>
    </div>
  </ng-template>
  