import * as wjcCore from '@grapecity/wijmo';

import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { EmployeeService } from '../service/employee.service';
import { UserService } from '../service/user.service';
import { Employee, EmployeeUser } from '../class/employee';
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employee-mod',
  templateUrl: './employee-mod.component.html',
  styleUrls: ['./employee-mod.component.css'],
  providers: [EmployeeService, UserService]
})
export class EmployeeModComponent implements OnInit {
  data: wjcCore.CollectionView;
  currentItem = new EmployeeUser();
  public actives: any;
  public actives_cb = [];
  public user_flgs_cb = [{cd: '0', name: '無'}, {cd: '1', name: '有'},];

  constructor(
    private employeeService: EmployeeService,
    private userService: UserService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    sessionStorage.setItem('screen_id', 'employee-mod');
    this.currentItem.active = '1';
  }

  async regist() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    let continued = true;
    if (this.currentItem.user_flg == '1') {
      // role 4　はゴルフ場
      await this.userService.add(this.currentItem.username, this.currentItem.password, 4).then(result => {
        console.log(result);
        this.currentItem.user_id = result['id'];
      }).catch(error => {
        this.alertService.error('ユーザー情報が登録できませんでした。ユーザー名を見直して下さい');
        console.log(error.status);
        continued = false;
      });
    }
    if(!continued) {
      mw.close();
      return;
    }
    this.employeeService.add(this.currentItem).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.currentItem.id = result.id;
        this.currentItem.userinfo_id = result['userinfo_id'];
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });
  }
/*  
  chkUnique() {
    let c = this.currentItem;
    let filtered = this.data.items.filter(function (value,index,items) {
      if (c==value) {
        return false;
      }
      return (value.employee_cd == c.employee_cd)
    });
    console.log(filtered.length);
    return (filtered.length == 0);
  }
*/
  async update() {
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    let continued = true;
    // ユーザー登録されていない
    if (this.currentItem.user_id == 0) {
      if (this.currentItem.user_flg == '1') {
        // role 3　は従業員
        await this.userService.add(this.currentItem.username, this.currentItem.password, 3).then(result => {
          console.log(result);
          this.currentItem.user_id = result['id'];
        }).catch(error => {
          this.alertService.error('ユーザー情報が登録できませんでした。ユーザー名を見直して下さい');
          console.log(error.status);
          continued = false;
        });
      }
    // ユーザー登録されている
    } else {
      if (this.currentItem.user_flg == '0') {
        //this.userService.delete(this.currentItem.user_id); // TODO ユーザー削除処理
        this.currentItem.user_id = 0;
      }
    }
    if(!continued) {
      mw.close();
      return;
    }
    this.employeeService.update(this.currentItem).then(result => {
      if (result.msgcd=='S') {
        this.currentItem.userinfo_id = result['userinfo_id'];
        this.currentItem.modified = result['modified'];
        this.alertService.success(result.message, false);
        this.activeModal.close(this.currentItem);
      }else {
        this.alertService.error(result.message, false);
      }
      mw.close();
    });

  }
}
