import { Injectable } from '@angular/core';
import { Screen } from '../class/screen'
import { Wareki } from '../class/wareki'
import { User } from '../class/user'

@Injectable()
export class UtilityService {
  static gengou = {'1': '明治', '2': '大正', '3': '昭和', '4': '平成', '5': '令和'};
  static gengouRyaku = {'1': 'M', '2': 'T', '3': 'S', '4': 'H', '5': 'R'};
  static ggStartDay = [
    {g: '1', yyyy: 1868, mm: 1, dd: 25}
    , {g: '2', yyyy: 1912, mm: 7, dd: 30}
    , {g: '3', yyyy: 1926, mm: 12, dd: 25}
    , {g: '4', yyyy: 1989, mm: 1, dd: 8}
    , {g: '5', yyyy: 2019, mm: 5, dd: 1}];
  static flgOff = '0';
  static flgOn = '1';
  static patternKana = '[､｡&ｦｧ-ﾝﾞﾟ0-9a-zA-Z\-\(\)\.\+･ ]*';
  static patternZen = '[^ -~｡-ﾟ]*';

  static changeZen2HanMap = {
    'ガ': 'ｶﾞ', 'ギ': 'ｷﾞ', 'グ': 'ｸﾞ', 'ゲ': 'ｹﾞ', 'ゴ': 'ｺﾞ',
    'ザ': 'ｻﾞ', 'ジ': 'ｼﾞ', 'ズ': 'ｽﾞ', 'ゼ': 'ｾﾞ', 'ゾ': 'ｿﾞ',
    'ダ': 'ﾀﾞ', 'ヂ': 'ﾁﾞ', 'ヅ': 'ﾂﾞ', 'デ': 'ﾃﾞ', 'ド': 'ﾄﾞ',
    'バ': 'ﾊﾞ', 'ビ': 'ﾋﾞ', 'ブ': 'ﾌﾞ', 'ベ': 'ﾍﾞ', 'ボ': 'ﾎﾞ',
    'パ': 'ﾊﾟ', 'ピ': 'ﾋﾟ', 'プ': 'ﾌﾟ', 'ペ': 'ﾍﾟ', 'ポ': 'ﾎﾟ',
    'ヴ': 'ｳﾞ', 'ヷ': 'ﾜﾞ', 'ヺ': 'ｦﾞ',
    'ア': 'ｱ', 'イ': 'ｲ', 'ウ': 'ｳ', 'エ': 'ｴ', 'オ': 'ｵ',
    'カ': 'ｶ', 'キ': 'ｷ', 'ク': 'ｸ', 'ケ': 'ｹ', 'コ': 'ｺ',
    'サ': 'ｻ', 'シ': 'ｼ', 'ス': 'ｽ', 'セ': 'ｾ', 'ソ': 'ｿ',
    'タ': 'ﾀ', 'チ': 'ﾁ', 'ツ': 'ﾂ', 'テ': 'ﾃ', 'ト': 'ﾄ',
    'ナ': 'ﾅ', 'ニ': 'ﾆ', 'ヌ': 'ﾇ', 'ネ': 'ﾈ', 'ノ': 'ﾉ',
    'ハ': 'ﾊ', 'ヒ': 'ﾋ', 'フ': 'ﾌ', 'ヘ': 'ﾍ', 'ホ': 'ﾎ',
    'マ': 'ﾏ', 'ミ': 'ﾐ', 'ム': 'ﾑ', 'メ': 'ﾒ', 'モ': 'ﾓ',
    'ヤ': 'ﾔ', 'ユ': 'ﾕ', 'ヨ': 'ﾖ',
    'ラ': 'ﾗ', 'リ': 'ﾘ', 'ル': 'ﾙ', 'レ': 'ﾚ', 'ロ': 'ﾛ',
    'ワ': 'ﾜ', 'ヲ': 'ｦ', 'ン': 'ﾝ',
    'ァ': 'ｧ', 'ィ': 'ｨ', 'ゥ': 'ｩ', 'ェ': 'ｪ', 'ォ': 'ｫ',
    'ッ': 'ｯ', 'ャ': 'ｬ', 'ュ': 'ｭ', 'ョ': 'ｮ',
    '。': '｡', '、': '､', 'ー': 'ｰ', '「': '｢', '」': '｣',  '・': '･',
    '（': '(', '）': ')', '－': 'ｰ', '　': ' ',
    'が': 'ｶﾞ', 'ぎ': 'ｷﾞ', 'ぐ': 'ｸﾞ', 'げ': 'ｹﾞ', 'ご': 'ｺﾞ',
    'ざ': 'ｻﾞ', 'じ': 'ｼﾞ', 'ず': 'ｽﾞ', 'ぜ': 'ｾﾞ', 'ぞ': 'ｿﾞ',
    'だ': 'ﾀﾞ', 'ぢ': 'ﾁﾞ', 'づ': 'ﾂﾞ', 'で': 'ﾃﾞ', 'ど': 'ﾄﾞ',
    'ば': 'ﾊﾞ', 'び': 'ﾋﾞ', 'ぶ': 'ﾌﾞ', 'べ': 'ﾍﾞ', 'ぼ': 'ﾎﾞ',
    'ぱ': 'ﾊﾟ', 'ぴ': 'ﾋﾟ', 'ぷ': 'ﾌﾟ', 'ぺ': 'ﾍﾟ', 'ぽ': 'ﾎﾟ',
    'ゔ': 'ｳﾞ',
    'あ': 'ｱ', 'い': 'ｲ', 'う': 'ｳ', 'え': 'ｴ', 'お': 'ｵ',
    'か': 'ｶ', 'き': 'ｷ', 'く': 'ｸ', 'け': 'ｹ', 'こ': 'ｺ',
    'さ': 'ｻ', 'し': 'ｼ', 'す': 'ｽ', 'せ': 'ｾ', 'そ': 'ｿ',
    'た': 'ﾀ', 'ち': 'ﾁ', 'つ': 'ﾂ', 'て': 'ﾃ', 'と': 'ﾄ',
    'な': 'ﾅ', 'に': 'ﾆ', 'ぬ': 'ﾇ', 'ね': 'ﾈ', 'の': 'ﾉ',
    'は': 'ﾊ', 'ひ': 'ﾋ', 'ふ': 'ﾌ', 'へ': 'ﾍ', 'ほ': 'ﾎ',
    'ま': 'ﾏ', 'み': 'ﾐ', 'む': 'ﾑ', 'め': 'ﾒ', 'も': 'ﾓ',
    'や': 'ﾔ', 'ゆ': 'ﾕ', 'よ': 'ﾖ',
    'ら': 'ﾗ', 'り': 'ﾘ', 'る': 'ﾙ', 'れ': 'ﾚ', 'ろ': 'ﾛ',
    'わ': 'ﾜ', 'を': 'ｦ', 'ん': 'ﾝ',
    'ぁ': 'ｧ', 'ぃ': 'ｨ', 'ぅ': 'ｩ', 'ぇ': 'ｪ', 'ぉ': 'ｫ',
    'っ': 'ｯ', 'ゃ': 'ｬ', 'ゅ': 'ｭ', 'ょ': 'ｮ',
    '１': '1', '２': '2', '３': '3', '４': '4', '５': '5', '６': '6', '７': '7', '８': '8', '９': '9', '０': '0',
    'Ａ': 'A', 'Ｂ': 'B', 'Ｃ': 'C', 'Ｄ': 'D', 'Ｅ': 'E', 'Ｆ': 'F', 'Ｇ': 'G', 'Ｈ': 'H', 'Ｉ': 'I', 'Ｊ': 'J', 'Ｋ': 'K',
    'Ｌ': 'L', 'Ｍ': 'M', 'Ｎ': 'N', 'Ｏ': 'O', 'Ｐ': 'P', 'Ｑ': 'Q', 'Ｒ': 'R', 'Ｓ': 'S', 'Ｔ': 'T', 'Ｕ': 'U', 'Ｖ': 'V',
    'Ｗ': 'W', 'Ｘ': 'X', 'Ｙ': 'Y', 'Ｚ': 'Z',
    'ａ': 'a', 'ｂ': 'b', 'ｃ': 'c', 'ｄ': 'd', 'ｅ': 'e', 'ｆ': 'f', 'ｇ': 'g', 'ｈ': 'h', 'ｉ': 'i', 'ｊ': 'j', 'ｋ': 'k',
    'ｌ': 'l', 'ｍ': 'm', 'ｎ': 'n', 'ｏ': 'o', 'ｐ': 'p', 'ｑ': 'q', 'ｒ': 'r', 'ｓ': 's', 'ｔ': 't', 'ｕ': 'u', 'ｖ': 'v',
    'ｗ': 'w', 'ｘ': 'x', 'ｙ': 'y', 'ｚ': 'z',
  };
  static changeHan2ZenMap = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・',
    '-': '－', ' ': '　',
    '1': '１', '2': '２', '3': '３', '4': '４', '5': '５', '6': '６', '7': '７', '8': '８', '9': '９', '0': '０',
    'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ', 'F': 'Ｆ', 'G': 'Ｇ', 'H': 'Ｈ', 'I': 'Ｉ', 'J': 'Ｊ', 'K': 'Ｋ',
    'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ', 'O': 'Ｏ', 'P': 'Ｐ', 'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ', 'U': 'Ｕ', 'V': 'Ｖ',
    'W': 'Ｗ', 'X': 'Ｘ', 'Y': 'Ｙ', 'Z': 'Ｚ',
    'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ', 'f': 'ｆ', 'g': 'ｇ', 'h': 'ｈ', 'i': 'ｉ', 'j': 'ｊ', 'k': 'ｋ',
    'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ', 'o': 'ｏ', 'p': 'ｐ', 'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ', 'u': 'ｕ', 'v': 'ｖ',
    'w': 'ｗ', 'x': 'ｘ', 'y': 'ｙ', 'z': 'ｚ',
  }
  static changeAlp2HanMap = {
    'ga': 'ｶﾞ', 'gi': 'ｷﾞ', 'gu': 'ｸﾞ', 'ge': 'ｹﾞ', 'go': 'ｺﾞ',
    'za': 'ｻﾞ', 'zi': 'ｼﾞ', 'zu': 'ｽﾞ', 'ze': 'ｾﾞ', 'zo': 'ｿﾞ',
    'da': 'ﾀﾞ', 'di': 'ﾁﾞ', 'du': 'ﾂﾞ', 'de': 'ﾃﾞ', 'do': 'ﾄﾞ',
    'ba': 'ﾊﾞ', 'bi': 'ﾋﾞ', 'bu': 'ﾌﾞ', 'be': 'ﾍﾞ', 'bo': 'ﾎﾞ',
    'pa': 'ﾊﾟ', 'pi': 'ﾋﾟ', 'pu': 'ﾌﾟ', 'pe': 'ﾍﾟ', 'po': 'ﾎﾟ',
    'va': 'ｳﾞｧ', 'vi': 'ｳﾞｨ', 'vu': 'ｳﾞ', 've': 'ｳﾞｪ', 'vo': 'ｳﾞｫ',
    'qa': 'ｸｧ', 'qi': 'ｸｨ', 'qu': 'ｸ', 'qe': 'ｸｪ', 'qo': 'ｸｫ',
    'fa': 'ﾌｧ', 'fi': 'ﾌｨ', 'fu': 'ﾌ', 'fe': 'ﾌｪ', 'fo': 'ﾌｫ',
    'kya': 'ｷｬ', 'kyi': 'ｷｨ', 'kyu': 'ｷｭ', 'kye': 'ｷｪ', 'kyo': 'ｷｮ',
    'gya': 'ｷﾞｬ', 'gyi': 'ｷﾞｨ', 'gyu': 'ｷﾞｭ', 'gye': 'ｷﾞｪ', 'gyo': 'ｷﾞｮ',
    'sya': 'ｼｬ', 'syi': 'ｼｨ', 'syu': 'ｼｭ', 'sye': 'ｼｪ', 'syo': 'ｼｮ',
    'sha': 'ｼｬ', 'shi': 'ｼ', 'shu': 'ｼｭ', 'she': 'ｼｪ', 'sho': 'ｼｮ',
    'zya': 'ｼﾞｬ', 'zyi': 'ｼﾞｨ', 'zyu': 'ｼﾞｭ', 'zye': 'ｼﾞｪ', 'zyo': 'ｼﾞｮ',
    'ja': 'ｼﾞｬ', 'ji': 'ｼﾞ', 'ju': 'ｼﾞｭ', 'je': 'ｼﾞｪ', 'jo': 'ｼﾞｮ',
    'jya': 'ｼﾞｬ', 'jyi': 'ｼﾞｨ', 'jyu': 'ｼﾞｭ', 'jye': 'ｼﾞｪ', 'jyo': 'ｼﾞｮ',
    'tya': 'ﾁｬ', 'tyi': 'ﾁｨ', 'tyu': 'ﾁｭ', 'tye': 'ﾁｪ', 'tyo': 'ﾁｮ',
    'cya': 'ﾁｬ', 'cyi': 'ﾁｨ', 'cyu': 'ﾁｭ', 'cye': 'ﾁｪ', 'cyo': 'ﾁｮ',
    'tha': 'ﾃｬ', 'thi': 'ﾃｨ', 'thu': 'ﾃｭ', 'the': 'ﾃｪ', 'tho': 'ﾃｮ',
    'dya': 'ﾁﾞｬ', 'dyi': 'ﾁﾞｨ', 'dyu': 'ﾁﾞｭ', 'dye': 'ﾁﾞｪ', 'dyo': 'ﾁﾞｮ',
    'dha': 'ﾃﾞｬ', 'dhi': 'ﾃﾞｨ', 'dhu': 'ﾃﾞｭ', 'dhe': 'ﾃﾞｪ', 'dho': 'ﾃﾞｮ',
    'nya': 'ﾆｬ', 'nyi': 'ﾆｨ', 'nyu': 'ﾆｭ', 'nye': 'ﾆｪ', 'nyo': 'ﾆｮ',
    'hya': 'ﾋｬ', 'hyi': 'ﾋｨ', 'hyu': 'ﾋｭ', 'hye': 'ﾋｪ', 'hyo': 'ﾋｮ',
    'fya': 'ﾌｬ', 'fyi': 'ﾌｨ', 'fyu': 'ﾌｭ', 'fye': 'ﾌｪ', 'fyo': 'ﾌｮ',
    'bya': 'ﾋﾞｬ', 'byi': 'ﾋﾞｨ', 'byu': 'ﾋﾞｭ', 'bye': 'ﾋﾞｪ', 'byo': 'ﾋﾞｮ',
    'pya': 'ﾋﾟｬ', 'pyi': 'ﾋﾟｨ', 'pyu': 'ﾋﾟｭ', 'pye': 'ﾋﾟｪ', 'pyo': 'ﾋﾟｮ',
    'mya': 'ﾐｬ', 'myi': 'ﾐｨ', 'myu': 'ﾐｭ', 'mye': 'ﾐｪ', 'myo': 'ﾐｮ',
    'rya': 'ﾘｬ', 'ryi': 'ﾘｨ', 'ryu': 'ﾘｭ', 'rye': 'ﾘｪ', 'ryo': 'ﾘｮ',
    'a': 'ｱ', 'i': 'ｲ', 'u': 'ｳ', 'e': 'ｴ', 'o': 'ｵ',
    'ka': 'ｶ', 'ki': 'ｷ', 'ku': 'ｸ', 'ke': 'ｹ', 'ko': 'ｺ',
    'ca': 'ｶ', 'ci': 'ｼ', 'cu': 'ｸ', 'ce': 'ｾ', 'co': 'ｺ',
    'sa': 'ｻ', 'si': 'ｼ', 'su': 'ｽ', 'se': 'ｾ', 'so': 'ｿ',
    'ta': 'ﾀ', 'ti': 'ﾁ', 'tu': 'ﾂ', 'te': 'ﾃ', 'to': 'ﾄ',
    'na': 'ﾅ', 'ni': 'ﾆ', 'nu': 'ﾇ', 'ne': 'ﾈ', 'no': 'ﾉ',
    'ha': 'ﾊ', 'hi': 'ﾋ', 'hu': 'ﾌ', 'he': 'ﾍ', 'ho': 'ﾎ',
    'ma': 'ﾏ', 'mi': 'ﾐ', 'mu': 'ﾑ', 'me': 'ﾒ', 'mo': 'ﾓ',
    'ya': 'ﾔ', 'yi': 'ｲ', 'yu': 'ﾕ', 'ye': 'ｲｪ', 'yo': 'ﾖ',
    'ra': 'ﾗ', 'ri': 'ﾘ', 'ru': 'ﾙ', 're': 'ﾚ', 'ro': 'ﾛ',
    'wa': 'ﾜ', 'wo': 'ｦ', 'nn': 'ﾝ',
    'la': 'ｧ', 'li': 'ｨ', 'lu': 'ｩ', 'le': 'ｪ', 'lo': 'ｫ',
    'xa': 'ｧ', 'xi': 'ｨ', 'xu': 'ｩ', 'xe': 'ｪ', 'xo': 'ｫ',
    'ltu': 'ｯ', 'lya': 'ｬ', 'lyu': 'ｭ', 'lyo': 'ｮ',
    '。': '｡', '、': '､', 'ー': 'ｰ', '「': '｢', '」': '｣',  '・': '･',
    '（': '(', '）': ')', '－': 'ｰ', '　': ' ',
    '１': '1', '２': '2', '３': '3', '４': '4', '５': '5', '６': '6', '７': '7', '８': '8', '９': '9', '０': '0',
  };
  static changeKey2Mark = {
    'Digit1': '1', 'Digit2': '2', 'Digit3': '3', 'Digit4': '4', 'Digit5': '5',
    'Digit6': '6', 'Digit7': '7', 'Digit8': '8', 'Digit9': '9', 'Digit0': '0',
    'Numpad1': '1', 'Numpad2': '2', 'Numpad3': '3', 'Numpad4': '4', 'Numpad5': '5',
    'Numpad6': '6', 'Numpad7': '7', 'Numpad8': '8', 'Numpad9': '9', 'Numpad0': '0',
    'NumpadDivide': '/', 'NumpadMultiply': '*', 'NumpadSubtract': '-', 'NumpadAdd': '+', 'NumpadDecimal': '.',
    'Minus': 'ｰ', 'Equal': '^', 'IntlYen': '\\',
    'BracketLeft': '@ﾞ', 'BracketRight': '｢',
    'Semicolon': ';', 'Quote': ':', 'Backslash': '｣',
    'Comma': '､', 'Period': '｡', 'Slash': '･', 'IntlRo': '\\',
  };
  static changeShiftKey2Mark = {
    'Digit1': '!', 'Digit2': '"', 'Digit3': '#', 'Digit4': '$', 'Digit5': '%',
    'Digit6': '&', 'Digit7': '\'', 'Digit8': '(', 'Digit9': ')', 'Digit0': '',
    'Numpad1': ' ', 'Numpad2': ' ', 'Numpad3': ' ', 'Numpad4': ' ', 'Numpad5': ' ',
    'Numpad6': ' ', 'Numpad7': ' ', 'Numpad8': ' ', 'Numpad9': ' ', 'Numpad0': ' ',
    'NumpadDivide': '/', 'NumpadMultiply': '*', 'NumpadSubtract': '-', 'NumpadAdd': '+', 'NumpadDecimal': '.',
    'Minus': '=', 'Equal': '~', 'IntlYen': '|',
    'BracketLeft': '`', 'BracketRight': '{',
    'Semicolon': '+', 'Quote': '*', 'Backslash': '}',
    'Comma': '<', 'Period': '>', 'Slash': '?', 'IntlRo': '_',
  };
  static changeKey2Alp = {
    'KeyA': 'a', 'KeyB': 'b', 'KeyC': 'c', 'KeyD': 'd', 'KeyE': 'e',
    'KeyF': 'f', 'KeyG': 'g', 'KeyH': 'h', 'KeyI': 'i', 'KeyJ': 'j',
    'KeyK': 'k', 'KeyL': 'l', 'KeyM': 'm', 'KeyN': 'n', 'KeyO': 'o',
    'KeyP': 'p', 'KeyQ': 'q', 'KeyR': 'r', 'KeyS': 's', 'KeyT': 't',
    'KeyU': 'u', 'KeyV': 'v', 'KeyW': 'w', 'KeyX': 'x', 'KeyY': 'y', 'KeyZ': 'z',
  };
  static changeShiftKey2Alp = {
    'KeyA': 'A', 'KeyB': 'B', 'KeyC': 'C', 'KeyD': 'D', 'KeyE': 'E',
    'KeyF': 'F', 'KeyG': 'G', 'KeyH': 'H', 'KeyI': 'I', 'KeyJ': 'J',
    'KeyK': 'K', 'KeyL': 'L', 'KeyM': 'M', 'KeyN': 'N', 'KeyO': 'O',
    'KeyP': 'P', 'KeyQ': 'Q', 'KeyR': 'R', 'KeyS': 'S', 'KeyT': 'T',
    'KeyU': 'U', 'KeyV': 'V', 'KeyW': 'W', 'KeyX': 'X', 'KeyY': 'Y', 'KeyZ': 'Z',
  };
  static changeInvalidChar2ZenMap = {
    '&': '＆', ' ': '　', '<': '＜', '[\+]': '＋'
  };

  static localeOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
  static localeOptionsYm = {year: 'numeric', month: '2-digit'};
  static localeOptionsWareki = {era: 'short', year: 'numeric', month: 'long', day: '2-digit'};
  // tslint:disable-next-line:max-line-length
  static gaiji = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];

  static getGengou(index: string) {
    return UtilityService.gengou[index];
  }
  static getGengouRyaku(index: string) {
    return UtilityService.gengouRyaku[index];
  }
  static getGandYear(yyyy: number, mm: number, dd: number) {
    let selected: any =  {g: '0', yyyy: 1, mm: 0, dd: 0};
//    console.log(yyyy);
//    console.log(mm);
//    console.log(dd);
    UtilityService.ggStartDay.forEach(function (value){
      if (yyyy > value.yyyy) {
        selected = value;
      }else if (yyyy === value.yyyy && (mm > value.mm || (mm == value.mm && dd >= value.dd))) {
        selected = value;
      }
    });
    return {gindex: selected.g, yy: ('00' + (yyyy - selected.yyyy + 1)).slice(-2)};
  }
  static getSeirekiDate(wareki: Wareki) {
//    console.log(wareki);
    let selected: any =  {g: '0', yyyy: 0, mm: 0, dd: 0};
    UtilityService.ggStartDay.forEach(function (value){
      if (wareki.g === value.g) {
        selected = value;
      }
    });
    const yyyy = Number(selected.yyyy) + Number(wareki.yy) - 1;
    return new Date(yyyy + '/' + wareki.mm + '/' + wareki.dd);
  }
  static getWareki(target: Date) {
    const wareki = new Wareki();
    wareki.dd = ('00' + target.getDate()).slice(-2);
    wareki.mm = ('00' + (target.getMonth() + 1)).slice(-2);
    const yyyy = target.getFullYear();
    const gandyear = UtilityService.getGandYear(yyyy, target.getMonth() + 1, target.getDate());
    wareki.g = gandyear.gindex;
    wareki.g_name = UtilityService.getGengou(wareki.g);
    wareki.yy = gandyear.yy;
    return wareki;
  }
  static getWarekiRyaku(target: Date) {
    const wareki = new Wareki();
    wareki.dd = ('00' + target.getDate()).slice(-2);
    wareki.mm = ('00' + (target.getMonth() + 1)).slice(-2);
    const yyyy = target.getFullYear();
    const gandyear = UtilityService.getGandYear(yyyy, target.getMonth() + 1, target.getDate());
    wareki.g = gandyear.gindex;
    wareki.g_name = UtilityService.getGengouRyaku(wareki.g);
    wareki.yy = gandyear.yy;
    return wareki;
  }
  static getWarekiStrSl(target: Date) {
    const wareki = UtilityService.getWareki(target);
    return wareki.g_name + ' ' + wareki.yy + '/' + wareki.mm + '/' + wareki.dd;
  }
  static getWarekiStrSlYm(target: Date) {
    const wareki = UtilityService.getWareki(target);
    return wareki.g_name + ' ' + wareki.yy + '/' + wareki.mm;
  }
  static getWarekiStr(target: Date) {
    const wareki = UtilityService.getWareki(target);
    return wareki.g_name + '' + wareki.yy + '年' + wareki.mm + '月' + wareki.dd + '日';
  }
  static getNulltoStringEmpty(target: any) {
    if (target === null || target === undefined) {
      return '';
    }
    return target;
  }
  static getGaiji(target: string) {
    let name = target;
    let gname = '';
    let index = -1;
    UtilityService.gaiji.some(element => {
      index = name.indexOf(element);
      if (index >= 0) {
        name = name.replace(element, '　');
        for (let i = 0 ; i < index; i++) {
          gname = gname + '';
        }
        gname = gname + element;
        return true;
      }
    });
    return {index: index, name: name, gname: gname}
  }
  static getPermission(screenId: string) {
    if (sessionStorage.getItem('screens')) {
      const screens = JSON.parse(sessionStorage.getItem('screens')) as Screen[];
      const filtered = screens.filter(function (value, index, items) {
        return (value.screen_id === screenId)
      });
      if (filtered.length > 0) {
        return true;
      }
    }
    return false;
  }
  constructor() { }
  changeZen2Han(str: string) {

    const reg = new RegExp('(' + Object.keys(UtilityService.changeZen2HanMap).join('|') + ')', 'g');
    return str
          .replace(reg, function (match) {
              return UtilityService.changeZen2HanMap[match];
          });
  }
  changeHan2Zen(str: string) {

    const reg = new RegExp('(' + Object.keys(UtilityService.changeHan2ZenMap).join('|') + ')', 'g');
    return str
          .replace(reg, function (match) {
              return UtilityService.changeHan2ZenMap[match];
          });
  }
  changeInvalidChar2Zen(str: string) {

    const reg = new RegExp('(' + Object.keys(UtilityService.changeInvalidChar2ZenMap).join('|') + ')', 'g');
    return str
          .replace(reg, function (match) {
              if (match == '+') {
                return '＋';
              }
              return UtilityService.changeInvalidChar2ZenMap[match];
          });
  }
  cBuf: string = '';
  sBuf: string = '';
  clearBufGetKana() {
    this.cBuf = '';
    this.sBuf = '';
  }
  getKanaFromKeydownEvent(event) {
    if (event.code === undefined) {
      return '';
    }
    if (event.ctrlKey === true) {
      return '';
    }
    switch (event.key) {
      case 'Shift':
      case 'Control':
      case 'Alt':
      case 'Home':
      case 'End':
      case 'PageUp':
      case 'PageDown':
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowDown':
      {
        return '';
      }
    }
    switch (event.code) {
      case 'Enter':
      case 'NumpadEnter': {
        const ret =  this.sBuf + this.cBuf;
        this.sBuf = '';
        this.cBuf = '';
        return ret;
      }
      case 'Backspace': {
        if (this.cBuf.length > 0) {
          this.cBuf = this.cBuf.slice(0, -1);
        }else if (this.sBuf.length > 0) {
          if (this.sBuf.slice(-1) === 'ﾞ') {
            this.sBuf = this.sBuf.slice(0, -2);
          } else {
            this.sBuf = this.sBuf.slice(0, -1);
          }
        }
        break;
      }
      case 'Space': {
        if (this.sBuf.length === 0) {
          return ' ';
        }
        break;
      }
      case 'Backquote':
      case 'Delete':
      case 'NumLock':
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
      case 'Escape':
      case 'CapsLock':
      case 'NonConvert':
      case 'Convert':
      case 'KanaMode':
      case 'ScrollLock':
      case 'Pause':
      case 'Insert':
      case 'Home':
      case 'End':
      case 'PageUp':
      case 'PageDown':
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Tab': {
        break;
      }
      default: {
        if (event.key === 'Process') {
          if (event.shiftKey) {
            if (UtilityService.changeShiftKey2Mark[event.code]) {
              this.sBuf += this.cBuf + UtilityService.changeShiftKey2Mark[event.code].replace(' ', '');
              this.cBuf = '';
            }
            if (UtilityService.changeShiftKey2Alp[event.code]) {
              this.sBuf += this.cBuf + UtilityService.changeShiftKey2Alp[event.code];
              this.cBuf = '';
            }
          } else {
            if (UtilityService.changeKey2Mark[event.code]) {
              this.sBuf += this.cBuf + UtilityService.changeKey2Mark[event.code].replace(' ', '');
              this.cBuf = '';
            }
            if (UtilityService.changeKey2Alp[event.code]) {
              this.cBuf += UtilityService.changeKey2Alp[event.code];
              for (let i = 0; i < this.cBuf.length; i++ ) {
                const temp = this.cBuf.slice(i);
//                console.log(i);
//                console.log(this.cBuf.length);
//                console.log(temp);
                if (UtilityService.changeAlp2HanMap[temp]) {
//                  console.log('through');
                  this.sBuf += this.cBuf.replace(temp, UtilityService.changeAlp2HanMap[temp]);
                  this.cBuf = '';
                }
              }
              if (this.cBuf.length > 1) {
                const fc = this.cBuf.slice(0, 1);
                const sc = this.cBuf.slice(1, 2);
//                console.log(fc);
//                console.log(sc);
                if (fc === sc) {
                  this.sBuf += 'ｯ';
                  this.cBuf = this.cBuf.slice(1);
                }
                if (fc === 'n' && sc !== 'y') {
                  this.sBuf += 'ﾝ';
                  this.cBuf = this.cBuf.slice(1);
                }

              }
            }
          }
        } else {
          if (this.sBuf.length > 0) {
            this.sBuf += event.key;
          }
          return event.key;
        }
//        console.log(this.cBuf);
//        console.log(this.sBuf);
        break;
      }
    }
    return '';
  }
  getEto(year: number) {
    const modYear = year % 12;
    let eto = '';
    switch (modYear) {
      case 0: {
        eto = '09'; // 申
        break;
      }
      case 1: {
        eto = '10'; // 酉
        break;
      }
      case 2: {
        eto = '11'; // 戌
        break;
      }
      case 3: {
        eto = '12'; // 亥
        break;
      }
      case 4: {
        eto = '01'; // 子
        break;
      }
      case 5: {
        eto = '02'; // 丑
        break;
      }
      case 6: {
        eto = '03'; // 虎
        break;
      }
      case 7: {
        eto = '04'; // 卯
        break;
      }
      case 8: {
        eto = '05'; // 辰
        break;
      }
      case 9: {
        eto = '06'; // 巳
        break;
      }
      case 10: {
        eto = '07'; // 午
        break;
      }
      case 11: {
        eto = '08'; // 未
        break;
      }
    }
    return eto;
  }
  static getMessageDel(day:number, golfname:string, user:User, targetYmd:Date) {
    let ymd = targetYmd.getFullYear() + '/' + ('00' + (targetYmd.getMonth() + 1)).slice(-2) + '/' + ('00' + day).slice(-2);
    return '変更者：' + user['name'] + '\n' + ymd + ' ' + golfname + ' ' + 'を解除';
  }

}
