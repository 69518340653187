import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Result } from '../class/result';
import { Golf, GolfUser } from '../class/golf';
import { User } from '../class/user';
import { UtilityService } from './utility.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class GolfService {
  token = sessionStorage.getItem('token');
  user = JSON.parse(sessionStorage.getItem('user'));
  screen_id = sessionStorage.getItem('screen_id');
  private headers = new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);
  constructor(
    private http:HttpClient,
    @Inject('ApiUrl') private api_url: string
  ) { }
  before() {
    this.token = sessionStorage.getItem('token');
    this.headers =  new HttpHeaders()
    .set('accept', 'application/ld+json')
    .set('Authorization', 'Bearer ' + this.token);

    this.screen_id = sessionStorage.getItem('screen_id');

  }
  initGolfList(): Promise<Result> {
    this.before();
    console.log('begin init');
    let params = new HttpParams()
      .set('screen_id', this.screen_id);

/*
    // DUMMY
    let result = new Result();
    return  new Promise(function(resolve) {
      result.msgcd = 'S';
      result.data = {
        golfs: [
          {id: 1, name: '城山ゴルフ倶楽部', name_short: '城山', bgcolor: '#dddddd', user_id: 0, work_kbn: '1', active: 1, modified: '2020/11/22 18:34:00'},
          {id: 2, name: '花屋敷ゴルフ倶楽部', name_short: '花屋敷', bgcolor: '#eeeeee', user_id: 0, work_kbn: '0', active: 1, modified: '2020/11/23 18:34:00'}
        ],
        actives: {'1': '1:活動', '0': '0:非活動'},
        work_kbns: {'1': '1:有', '0': '0:無'}
      };
      return resolve(result);
    });
*/
    return this.http.post(this.api_url + 'capi/golf/initGolfList', params, {headers: this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  add(golf:GolfUser): Promise<Result> {
    this.before();

    let modified = new Date();
    let params = new HttpParams()
      .set('name', golf.name)
      .set('name_short', golf.name_short)
      .set('bgcolor', golf.bgcolor)
      .set('work_kbn', golf.work_kbn)
      .set('user_id', String(golf.user_id))
      .set('userinfo_id', String(golf.userinfo_id))
      .set('user_flg', golf.user_flg)
      .set('email', golf.email)
      .set('token', golf.token)
//      .set('active', golf.active)
//      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = 6;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golf/addforng', params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  update(golf:GolfUser): Promise<Result> {
    this.before();

    let modified = new Date();
    let params = new HttpParams()
      .set('id', String(golf.id))
      .set('name', golf.name)
      .set('name_short', golf.name_short)
      .set('bgcolor', golf.bgcolor)
      .set('work_kbn', golf.work_kbn)
      .set('user_id', String(golf.user_id))
      .set('user_flg', golf.user_flg)
      .set('userinfo_id', String(golf.userinfo_id))
      .set('email', golf.email)
      .set('token', golf.token)
      .set('active', golf.active)
      .set('modified', modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString())
      .set('last_modified', golf.modified)
      .set('login_user_id', this.user.id)
      .set('screen_id', this.screen_id);

      /*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = golf.id;
        result['modified'] =  modified.toLocaleDateString('ja-JP', UtilityService.localeOptions) + ' ' + modified.toLocaleTimeString();
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golf/updateforng/'+ String(golf.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  delete(golf:GolfUser): Promise<Result> {
    let params = new HttpParams()
      .set('id', String(golf.id))
      .set('user_id', String(golf.user_id))
      .set('userinfo_id', String(golf.userinfo_id))
      .set('last_modified', golf.modified)
      .set('screen_id', this.screen_id)
      .set('token', this.token);
/*
      // DUMMY
      let result = new Result();
      return  new Promise(function(resolve) {
        result.msgcd = 'S';
        result.id = golf.id;
        return resolve(result);
      });
*/
      return this.http.post(this.api_url + 'capi/golf/deleteforng/' + String(golf.id), params, {headers:this.headers})
               .toPromise()
               .then(response => {
                 return response as Result}
                 )
               .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }

}