import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbRadioGroup, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';

import { AlertService } from '../service/alert.service';
import { SysMsg } from '../class/sysmsg';
import { AttendanceService } from '../service/attendance.service';
import { AuthenticationService } from '../service/authentication.service';
import { UserService } from '../service/user.service';
import { Attendance } from '../class/attendance';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { ModalWaitComponent } from '../modal-wait/modal-wait.component'
import { AttendanceViewComponent } from '../attendance-view/attendance-view.component';
import { RequestListComponent } from '../request-list/request-list.component';
import { EmployeeSelectComponent } from '../employee-select/employee-select.component';
import { MemoModComponent } from '../memo-mod/memo-mod.component';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.css']
})
export class AttendanceListComponent implements OnInit {
  targetYmd = new Date();
  dispTargetYm = '';
  user = [];

  days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  weeks = [];
  golfworks = [
    {id: 1, golf_name: '城山ゴルフ場', golf_name_short: '城山', bgcolor: '#dddddd', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 2, golf_name: '城山ゴルフ場', golf_name_short: '城山', bgcolor: '#dddddd', name: '作業', attendance_sum: 0, request_sum: 0},
    {id: 3, golf_name: 'サイプレイス', golf_name_short: 'サイ', bgcolor: '#cccccc', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 4, golf_name: '花屋敷', golf_name_short: '花屋敷', bgcolor: 'rgba(210,39,39,0.52)', name: 'ひろの', attendance_sum: 0, request_sum: 0},
    {id: 5, golf_name: '花屋敷', golf_name_short: '花屋敷', bgcolor: 'rgba(210,39,39,0.52)', name: 'よかわ', attendance_sum: 0, request_sum: 0},
    {id: 6, golf_name: '小野', golf_name_short: '小野', bgcolor: '#cccccc', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 7, golf_name: '加古川', golf_name_short: '加古川', bgcolor: '#bbbbbb', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 8, golf_name: '三木', golf_name_short: '三木', bgcolor: '#aaaaaa', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 9, golf_name: 'サンロイヤル', golf_name_short: 'サンロイヤル', bgcolor: '#999999', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 10, golf_name: '東広野', golf_name_short: '東広野', bgcolor: '#888888', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 11, golf_name: 'ゴールデン', golf_name_short: 'ゴールデン', bgcolor: '#dddddd', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 12, golf_name: 'パイン', golf_name_short: 'パイン', bgcolor: '#dddddd', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 13, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', bgcolor: '#dddddd', name: 'キャディ', attendance_sum: 0, request_sum: 0},
    {id: 14, golf_name: '関西ゴルフ', golf_name_short: '関ゴ', bgcolor: '#dddddd', name: 'マスター', attendance_sum: 0, request_sum: 0},
  ];
  requests = {
    1: {1: 2,2: 2,3: 4,4: 0,5: 0,6: 0,7: 0,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    2: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    3: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    4: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    5: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    6: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    7: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    8: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    9: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    10: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    11: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    12: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    13: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
    14: {1: 0,2: 0,3: 1,4: 0,5: 0,6: 0,7: 1,8: 0,9: 0,10: 0,11: 0,12: 0,13: 0,14: 0,15: 0,16: 0,17: 0,18: 0,19: 0,20: 0,21: 0,22: 0,23: 0,24: 0,25: 0,26: 0,27: 0,28: 0,29: 0,30: 0,31: 0},
  };
  capacitys = [];
  attendances = {
    1:
      {1: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
          ,5: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    2: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '1'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    3: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    4: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    5: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    6: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    7: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    8: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    9: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    10: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    11: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    12: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    13: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    14: 
      {3: [{id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'}]
      ,7: [{id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},]
      },
    };
  employees = [
    {id: 1, name: '井上　圭', nameFamily: '井上', position_cd: 10, commit_flg: '0'},
    {id: 2, name: '山田　太郎', nameFamily: '山田', position_cd: 10, commit_flg: '0'},
    {id: 3, name: '佐藤　次郎', nameFamily: '佐藤', position_cd: 20, commit_flg: '0'},
    {id: 4, name: '鈴木　四郎', nameFamily: '鈴木', position_cd: 30, commit_flg: '0'},
    {id: 5, name: '斎藤　圭', nameFamily: '斎藤', position_cd: 10, commit_flg: '0'},
    {id: 6, name: '田中　太郎', nameFamily: '田中', position_cd: 10, commit_flg: '0'},
    {id: 7, name: '加藤　次郎', nameFamily: '加藤', position_cd: 20, commit_flg: '0'},
    {id: 8, name: '尾張　四郎', nameFamily: '尾張', position_cd: 30, commit_flg: '0'},
  ];
  sumByDay = [];
  attendance_sum_all = 0;
  request_sum_all = 0;
  e_attendances = [];
  memos = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private attendanceService: AttendanceService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // 画面IDの設定
    sessionStorage.setItem('screen_id', 'attendance-list');
    this.authenticationService.setDispName('シフト状況一覧');
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.targetYmd.setDate(1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.attendanceService.initAttendanceList().then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.employees = result['data']['employees'];
/*        this.type_cds = result['data']['type_cds'];
        for (var x in this.type_cds) {
          this.type_cds_cb.push({cd:x, name:this.type_cds[x]});
        }
*/
        this.getAttendanceList();
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  getAttendanceList() {
    let targetYm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    let lastDay = this.getLastDay(this.targetYmd);
    this.days = [];
    for(let i=1;i<lastDay+1;i++) {
      this.days.push(i);
    }
    const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
    this.sumByDay = [];
    this.request_sum_all = 0;
    this.attendance_sum_all = 0;
console.log(targetYm);
    this.attendanceService.getAttendanceList(targetYm).then(result => {
      console.log(result);
      if (result.msgcd=='S') {
        this.employees = result['data']['employees'];
        this.golfworks = result['data']['golfworks'];
        this.attendances = result['data']['attendances'];
        this.e_attendances = result['data']['e_attendances'] as [];
        this.requests = result['data']['requests'];
        this.capacitys = result['data']['capacitys'];
        this.memos = result['data']['memos'];
        let lastDay = new Date(this.targetYmd.getFullYear(), this.targetYmd.getMonth() + 1, 0);
        this.golfworks.forEach(golfwork => {
          golfwork.request_sum = 0;
          golfwork.attendance_sum = 0;
          for(let i=1;i<=lastDay.getDate();i++) {
            let day = i;
            if (!this.attendances[String(golfwork.id)].hasOwnProperty(day)) {
              this.attendances[golfwork.id][day] = [];
            }
            let dt = new Date(this.targetYmd.getFullYear() + '-' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2) + '-' + day);
            this.weeks[day] = dt.getDay();
            golfwork.request_sum += Number(this.requests[golfwork.id][day]);
            golfwork.attendance_sum += Number(this.attendances[golfwork.id][day].length);
            this.request_sum_all += Number(this.requests[golfwork.id][day]);
            this.attendance_sum_all += Number(this.attendances[golfwork.id][day].length);
            if(this.sumByDay[day]) {
              this.sumByDay[day]['request'] += Number(this.requests[golfwork.id][day]);
              this.sumByDay[day]['attendance'] += Number(this.attendances[golfwork.id][day].length);
            } else {
              this.sumByDay[day] = {'request': Number(this.requests[golfwork.id][day]), 'attendance': Number(this.attendances[golfwork.id][day].length)}
            }
          }
        });
      }else if (result.msgcd=='E') {
        this.alertService.error(result.message, true);
      }else {
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
      mw.close();
    }).catch(error => {
      if (error.status == 401) {
        console.log(error);
        this.authenticationService.loginAgain().then(result => {
          mw.close();
          this.ngOnInit();
        }).catch(error => {
          console.log(error);
          mw.close();
        });
      } else {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      }
    });
  }
  getLastDay(targetYmd: Date) {
    let lastYmd = new Date(targetYmd.getFullYear(), targetYmd.getMonth() + 1, 0);
    return lastYmd.getDate();
  }
  prevYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() - 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceList();
//    this.getData(this.targetYmd.toLocaleDateString('ja-JP', UtilityService.localeOptions));

  }
  nextYm() {
    this.targetYmd.setMonth(this.targetYmd.getMonth() + 1);
    this.dispTargetYm = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
    this.getAttendanceList();
//    this.getData(this.targetYmd.toLocaleDateString('ja-JP', UtilityService.localeOptions));

  }
  getGolfName(golfwork_id){
    let filtered = this.golfworks.filter(function (value,index,items) {
      return (value.id == golfwork_id)
    });
    if(filtered.length > 0) {
      return filtered[0].golf_name_short;

    } else {
      return '';

    }
  }
  getGolfColor(golfwork_id){
    let filtered = this.golfworks.filter(function (value,index,items) {
      return (value.id == golfwork_id)
    });
    if(filtered.length > 0) {
      return filtered[0].bgcolor;

    } else {
      return '';

    }
  }
  drop(event: CdkDragDrop<string[]>, golfwork_id, day) {
    console.log(this.requests[golfwork_id][day]);
    let curSize = event.container.data.length;
    // 上限なしにする
/*    if (this.requests[golfwork_id][day] == curSize) {
      return;
    }
*/
    let id = event.previousContainer.data[event.previousIndex]['employee_id'];
    let chkDupl = false;
    event.container.data.forEach(item => {
      if (id == item['employee_id']) {
        chkDupl = true;
        return;
      }
    });
    if (event.previousContainer === event.container || chkDupl) {
      console.log('上');
      console.log(event);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log('下');
      console.log(event);
      const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
      let attendance = new Attendance();
      attendance.golfwork_id = golfwork_id;
      attendance.employee_id = id;
      attendance.yyyymm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
      attendance.dd = day;
      console.log(attendance);
      this.attendanceService.mergeforng(attendance).then(result => {
        if (result.msgcd=='S') {
          event.previousContainer.data[event.previousIndex]['id'] = result.id;
          event.previousContainer.data[event.previousIndex]['modified'] = result['modified'];
          let golfname = '';
          this.golfworks.forEach(golfwork => {
            if(golfwork.id == golfwork_id) {
              golfwork.attendance_sum++;
              golfname = golfwork.golf_name_short + golfwork.name;
              return;
            }
          });
          this.sumByDay[day]['attendance']++;
          this.attendance_sum_all++;
          copyArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
          attendance['name_short'] = event.previousContainer.data[event.previousIndex]['name_short'];
          let existEmployee = false;
          this.e_attendances.forEach(item => {
            if(item.employee_id==attendance.employee_id) {
              item[day] = attendance;
              item['cnt']++;
              existEmployee = true;
              return;
            }
          });
          if (!existEmployee) {
            let ob = {
              name:event.previousContainer.data[event.previousIndex]['name'],
              employee_id: attendance.employee_id,
              cnt: 1,
            };
            ob[day] = attendance;
            this.e_attendances.push(ob);
          }
          console.log(this.e_attendances);
          // メッセージ送信
          let message = this.getMessageDropEmploy(attendance, golfname);
          this.userService.sendMessageLine4Employee(id, message);
        } else {
          this.alertService.error(result.message, true);
        }
        mw.close();
      }).catch(error => {
        mw.close();
        this.alertService.error(SysMsg.getMsg('com000_001'), true);
      });
    }
  }
  getMessageDropEmploy(attendance: Attendance, golfname) {
    let ymd = attendance.yyyymm.slice(0,4) + '/' + attendance.yyyymm.slice(-2) + '/' + ('00' + attendance.dd).slice(-2);
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + golfname + ' ' + 'に設定';
  }
  getMessageDel(day, golfname) {
    let ymd = this.targetYmd.getFullYear() + '/' + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2) + '/' + ('00' + day).slice(-2);
    return '変更者：' + this.user['name'] + '\n' + ymd + ' ' + golfname + ' ' + 'を解除';
  }

  delAttendance(employee, golfwork_id, day) {
    console.log(employee);
    console.log(golfwork_id);
    console.log(day);
    console.log(this.attendances[golfwork_id][day]);
    for(let i=0;i<this.attendances[golfwork_id][day].length; i++) {
      if(this.attendances[golfwork_id][day][i]['id'] == employee['id']) {
        const mw = this.modalService.open(ModalWaitComponent, {backdrop: 'static', keyboard: false});
        this.attendanceService.delete(this.attendances[golfwork_id][day][i]).then(result => {
          if (result.msgcd=='S') {
            let attendance4msg = this.attendances[golfwork_id][day][i];
            this.attendances[golfwork_id][day].splice(i, 1);
            let golfname = '';
            this.golfworks.forEach(golfwork => {
              if(golfwork.id == golfwork_id) {
                golfwork.attendance_sum--;
                golfname = golfwork.golf_name_short + golfwork.name;
                return;
              }
            });
            this.sumByDay[day]['attendance']--;
            this.attendance_sum_all--;
            //            let existEmployee = false;
            this.e_attendances.forEach(item => {
              if(item.employee_id==employee['employee_id']) {
                delete item[day];
                item['cnt']--;
//                existEmployee = true;
                return;
              }
            });
  
            // メッセージ送信
            console.log(attendance4msg);
            let message = this.getMessageDel(day, golfname);
            this.userService.sendMessageLine4Employee(employee.employee_id, message);
          } else {
            this.alertService.error(result.message, true);
          }
          mw.close();
        }).catch(error => {
          mw.close();
          console.log(error);
          this.alertService.error(SysMsg.getMsg('com000_001'), true);
        });
        return;
      }
    }
  }
  openEmployee(target) {
    let modalRef = this.modalService.open(AttendanceViewComponent, {size: 'lg', backdrop: true, keyboard: true});
    let component = modalRef.componentInstance as AttendanceViewComponent;
    component.employee_id = target.employee_id;
    component.employee_name = target.name;
    component.targetYmd = this.targetYmd;
    modalRef.result.then((result) => {
      console.log(result);
      sessionStorage.setItem('screen_id', 'attendance-list');  
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'attendance-list');
    });
  }
  openRequest(golfwork, day) {
    let modalRef = this.modalService.open(RequestListComponent, {size: 'lg', backdrop: true, keyboard: true});
    let component = modalRef.componentInstance as RequestListComponent;
    console.log(golfwork);
    component.golf_id = golfwork.golf_id;
    component.golfwork_id = (golfwork.id>900?golfwork.id-900:golfwork.id);
//    component.golfwork_name = golfwork.golf_name_short + golfwork.name;
    component.day = ('00' + day).slice(-2);
    modalRef.result.then((result) => {
      console.log(result);
      sessionStorage.setItem('screen_id', 'attendance-list');
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'attendance-list');
    });
  }
  openEmployeeSelect(attendances, golfwork, request, day) {
    let modalRef = this.modalService.open(EmployeeSelectComponent, {size: 'lg', backdrop: 'static', keyboard: true});
    let component = modalRef.componentInstance as EmployeeSelectComponent;
    console.log(attendances);
    component.day = day;
    component.attendances = attendances;
    component.e_attendances = this.e_attendances;
    component.employees = this.employees;
    component.request = request;
    component.targetYmd = this.targetYmd;
    component.golfwork = golfwork;
    this.golfworks.forEach(target => {
      if(target.id == golfwork.id) {
        component.attendance_sum = target.attendance_sum;
        return;
      }
    });
    component.sumByDay = this.sumByDay[day]['attendance'];


//    component.day = ('00' + day).slice(-2);
    modalRef.result.then((result) => {
      console.log(result);
      sessionStorage.setItem('screen_id', 'attendance-list');
      this.attendances[golfwork.id][day] = component.attendances;
      this.sumByDay[day]['attendance'] = component.sumByDay;
      this.attendance_sum_all += component.inc;
      this.golfworks.forEach(target => {
        if(target.id == golfwork.id) {
          target.attendance_sum = component.attendance_sum;
          return;
        }
      });
        /*
      // 削除
      for(let i=0;i<this.attendances[golfwork.id][day].length; i++) {
        let isExist = false;
        for(let j=0;j<result.length;j++) {
          if(this.attendances[golfwork.id][day][i]['employee_id'] == result[j]['employee_id']) {
            isExist = true;
            break;
          }
        }
        // 存在しない場合削除
        if(!isExist) {
          this.attendanceService.delete(this.attendances[golfwork.id][day][i]).then(result2 => {
            if (result2.msgcd=='S') {
              this.attendances[golfwork.id][day].splice(i, 1);
              this.golfworks.forEach(target => {
                if(target.id == golfwork.id) {
                  target.attendance_sum--;
                  return;
                }
              });
              this.sumByDay[day]['attendance']--;
            } else {
              this.alertService.error(result2.message, true);
            }
          }).catch(error => {
            this.alertService.error(SysMsg.getMsg('com000_001'), true);
          });
          return;
        }
      }
      // 追加
      for(let i=0;i<result.length;i++) {
        let isExist = false;
        for(let j=0;j<this.attendances[golfwork.id][day].length; j++) {
          if(this.attendances[golfwork.id][day][j]['employee_id'] == result[i]['employee_id']) {
            isExist = true;
            break;
          }
        }
        // 既に存在したら次
        if (isExist) {
          continue;
        }
        let attendance = new Attendance();
        attendance.golfwork_id = golfwork.id;
        attendance.employee_id = result[i]['employee_id'];
        attendance['name'] = result[i]['name'];
        attendance['name_short'] = result[i]['name_short'];
        attendance.yyyymm = this.targetYmd.getFullYear() + ('00' + (this.targetYmd.getMonth() + 1)).slice(-2);
        attendance.dd = day;
        this.attendanceService.mergeforng(attendance).then(result2 => {
          if (result2.msgcd=='S') {
            attendance['id'] = result2.id;
            attendance['modified'] = result2['modified'];
            this.attendances[golfwork.id][day].push(attendance);
            this.golfworks.forEach(target => {
              if(target.id == golfwork.id) {
                target.attendance_sum++;
                return;
              }
            });
            this.sumByDay[day]['attendance']++;
          } else {
            this.alertService.error(result2.message, true);
          }
        }).catch(error => {
          this.alertService.error(SysMsg.getMsg('com000_001'), true);
        });
      }
      */
      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
    }, (reason) => {
      component.employees.forEach(employee => {
        employee['available_flg'] = '1';
        employee['golfwork_id'] = 0;
      })
      sessionStorage.setItem('screen_id', 'attendance-list');
    });
  }
  editMemo(target) {
    let modalRef = this.modalService.open(MemoModComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    let component = modalRef.componentInstance as MemoModComponent;
    component.dispTargetYmd = this.dispTargetYm + '/' + target.dd;
    component.currentItem = target;
    modalRef.result.then((result) => {
      target = result;
      sessionStorage.setItem('screen_id', 'golf-list');
    }, (reason) => {
      sessionStorage.setItem('screen_id', 'golf-list');
    });
  }
}
