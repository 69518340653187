export class SysMsg {
    static db = {
        'com000_001': '初期化エラー、ログインして下さい',
        'com000_002': '削除してよろしいですか',
        'com000_003': '並び順を保存してよろしいですか',
        'com000_004': '登録してよろしいですか',
        'com000_005': '更新してよろしいですか',
        'com000_006': 'エラーが発生しました。再読み込みしますか',
        'com000_007': 'パスワードが変更されました',
        'mst001_001': 'マスターコード, コードの値が重複しています',
        'mst002_001': '医師会コードの値が重複しています',
        'mst003_001': '所属コードの値が重複しています',
        'mst004_001': '大学コードの値が重複しています',
        'mst005_001': '診療科コードの値が重複しています',
        'mst006_001': '学会コードの値が重複しています',
        'mst007_001': '主催者コードの値が重複しています',
        'mem506_001': '研修会場コードの値が重複しています',
        'mem001_001': 'KCS会員種別を取得できませんでした',
        'mem201_001': '接続日を更新してよろしいですか',
        'mem201_002': '接続日をクリアしてよろしいですか',
        'mcp001_001': '保健所コードの値が重複しています',
        'mcp003_001': '医療機関コードの値が重複しています',
        'mcp005_001': '指定医コードの値が重複しています',
        'mcp006_001': '仮登録データを本登録に更新しますか',
        'mcp101_001': '指定医が選択されていません',
        'mcp011_001': '指定医番号を入力してください',
        'mli001_001': '会員情報が取得できませんでした',
        'mli001_002': '補償対象施設が重複しているため、追加できませんでした',
        'mli001_003': '補償対象施設はこれ以上追加できません',
        'mli001_004': '補償対象施設を追加してください',
        'mli001_005': '掛金の計算に必要な情報がありません',
        'mli001_006': '医籍登録番号が重複しています',
        'mli005_001': '更新してよろしいですか（この内容はこの請求にのみ反映されます）',
        'mli005_002': '請求データを一括作成してよろしいですか（以前に同条件で作成したデータは上書きされます）',
        'mli006_001': 'チェック処理を実施してよろしいですか（以前に作成したデータは削除されます）',
    };
    static getMsg(id: string) {
        return SysMsg.db[id];
    }
}
