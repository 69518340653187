<div class="row">
    <div class="col-12 col-xl-6">
        <div style="text-align: center;">
            <label class="" style="font-size: xx-large;">{{golfinfo['golf_name']}}</label>
            <button class="btn btn-success" style="float: right;" (click)="openGolfEmployeeList()"><span class="oi oi-people"></span></button>
        </div>
        <div style="text-align: center;">
            <button class="btn btn-primary" style="float: left;" (click)="prevYm()"><span class="oi oi-arrow-left"></span></button>
            <label class="" style="font-size: xx-large;">{{dispTargetYm}}</label>
            <button class="btn btn-primary" style="float: right;" (click)="nextYm()"><span class="oi oi-arrow-right"></span></button>
        </div>
        <div class="table-responsive-lg" style="overflow-x: scroll;">
            <wj-combo-box id="vgolfwork_id" name="vgolfwork_id" [displayMemberPath]="'name'" [selectedValuePath]="'id'"  [(ngModel)]="golfwork_id" #vgolfwork_id="ngModel" [itemsSource]="golfworks" [isEditable]="false" (selectedIndexChanged)="changeGolfwork()">
            </wj-combo-box>
            <table style="border: 2px solid black;min-width :430px;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <!-- <th></th>
                        <th></th> -->
                        <th></th>
                        <th colspan="3">キャディー</th>
                        <th colspan="3" *ngIf="golfinfo.work_kbn=='1'">作業</th>
                        <th>配置可能</th>
                        <th></th>
                        <th *ngIf="golfinfo.work_kbn=='1'"></th>
                    </tr>
                    <tr>
                        <!-- <th style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" [class.btn-primary]="week.week_cd=='Sat'" [class.btn-danger]="week.week_cd=='Sun'" *ngFor="let week of weeks">{{week.name}}</th> -->
                        <th style="width: 2%;">日</th>
                        <!-- <th style="width: 17%;" *ngIf="golfinfo.work_kbn=='1'">全組数</th>
                        <th style="width: 17%;">キャディー組数</th> -->
                        <th style="width: 10%;">オファー</th>
                        <th style="width: 7%;">配置</th>
                        <th style="width: 15%;"></th>
                        <th style="width: 10%;" *ngIf="golfinfo.work_kbn=='1'">オファー</th>
                        <th style="width: 7%;" *ngIf="golfinfo.work_kbn=='1'">配置</th>
                        <th style="width: 15%;"*ngIf="golfinfo.work_kbn=='1'"></th>
                        <th style="width: 15%;">従業員</th>
                        <th style="width: 13%;">キャディー組数</th>
                        <th style="width: 13%;" *ngIf="golfinfo.work_kbn=='1'">全組数</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let target of calendar">
                            <td class="" [class.btn-primary]="target.week_cd=='Sat'" [class.btn-danger]="target.week_cd=='Sun'" (click)="add(target.ymd, '0')">
                                {{target.ymd | date:'dd'}}
                            </td>
                            <!-- <td class="btn-light " *ngIf="work_sums[(target.ymd | date:'d')]">
                                <input style="width: 60%;text-align: right;" [class.bg-danger]="capacity_sums[(target.d)]['capa_all']>=30" type="text" [(ngModel)]="capacity_sums[(target.d)]['capa_all']" pattern="[0-9]+" (change)="onChangeCapa(capacity_sums[(target.d)])">組
                            </td>
                            <td class="btn-light " *ngIf="capacity_sums[(target.ymd | date:'d')]">
                                <input style="width: 60%;text-align: right;" [class.bg-danger]="capacity_sums[(target.d)]['capa_caddy']>=30" type="text" [(ngModel)]="capacity_sums[(target.d)]['capa_caddy']" pattern="[0-9]+" (change)="onChangeCapa(capacity_sums[(target.d)])">組
                            </td> -->
                            <td 
                              class=""
                              style="text-align: center;"
                              [class.btn-warning]="request_sums[(target.ymd | date:'d')]['cnt']>=1" 
                              [class.btn-secondary]="request_sums[(target.ymd | date:'d')]['cnt']==0" 
                              (click)="add(target.ymd, '0')"
                            >
                                <button class="btn btn-success btn-sm" style="font-size: xx-small;" *ngIf="request_sums[(target.ymd | date:'d')]['user_id']==cur_golf_user_id">!</button>{{request_sums[(target.ymd | date:'d')]['cnt']}}
                            </td>
                            <td 
                              class="btn-light "
                              style="text-align: center;color: red;font-weight: bold;"
                              (click)="openEmployeeSelect(attendances, target.ymd, golfwork_id)"
                            >
                            {{attendances[golfwork_id][(target.ymd | date:'d')]?.length}}
                            </td>
                            <td>
                                <div class="example-box" *ngFor="let item of attendances[golfwork_id][(target.ymd | date:'d')]"
                                  data-toggle="tooltip" data-placement="top" title="{{item.name + '\n' + item.remarks}}"
                                  [class.commit]="item.commit_flg=='1'" 
                                  [class.no-commit]="item.commit_flg=='0'" 
                                  [class.remarks]="item.remarks!=''"
                                  (dblclick)="openEmployee(item)"
                                >
                                    {{item.name_short}}<span style="background-color: brown;color: white;font-weight: bold;padding: 0 3px 0 3px;border-radius: 5px;" ><p style="margin-bottom: 0;" (click)="delAttendance(item, golfwork_id, target.ymd)">×</p></span>
                                </div>
                                <!-- <div class="btn-light " *ngFor="let item of attendances[golfwork_id][(target.ymd | date:'d')]">{{item['name_short']}}</div> -->
                            </td>
                            <td 
                              class=""
                              style="text-align: center;"
                              [class.btn-warning]="work_sums[(target.ymd | date:'d')]['cnt']>=1" 
                              [class.btn-secondary]="work_sums[(target.ymd | date:'d')]['cnt']==0" 
                              (click)="add(target.ymd, '1')"
                              *ngIf="golfinfo.work_kbn=='1'"
                            >
                                <button class="btn btn-success btn-sm" style="font-size: xx-small;" *ngIf="work_sums[(target.ymd | date:'d')]['user_id']==cur_golf_user_id">!</button>{{work_sums[(target.ymd | date:'d')]['cnt']}}
                            </td>
                            <td class="btn-light "
                                style="text-align: center;color: red;font-weight: bold;"
                                *ngIf="golfinfo.work_kbn=='1'"
                                (click)="openEmployeeSelect(attendances, target.ymd, work_id)"
                            >
                                {{attendances[work_id][(target.ymd | date:'d')]?.length}}
                            </td>
                            <td *ngIf="golfinfo.work_kbn=='1'">
                                <div class="example-box" *ngFor="let item of attendances[work_id][(target.ymd | date:'d')]"
                                  data-toggle="tooltip" data-placement="top" title="{{item.name + '\n' + item.remarks}}"
                                  [class.commit]="item.commit_flg=='1'" 
                                  [class.no-commit]="item.commit_flg=='0'" 
                                  [class.remarks]="item.remarks!=''"
                                  (dblclick)="openEmployee(item)"
                                >
                                    {{item.name_short}}<span style="background-color: brown;color: white;font-weight: bold;padding: 0 3px 0 3px;border-radius: 5px;" ><p style="margin-bottom: 0;" (click)="delAttendance(item, work_id, target.ymd)">×</p></span>
                                </div>
                                <!-- <div class="btn-light " *ngFor="let item of attendances[work_id][(target.ymd | date:'d')]">{{item['name']}}</div> -->
                            </td>
                            <td>
                                <ng-container *ngFor="let item of availableAttendances[(target.ymd | date:'d')]">
                                <div class="available-box" *ngIf="item.golfwork_id==0 && item.available_flg=='1'"
                                  data-toggle="tooltip" data-placement="top" title="{{item.name + '\n' + item.remarks}}"
                                  [class.remarks]="item.remarks!=''"
                                >
                                    {{item.name_short}}
                                </div>
                                </ng-container>
                            </td>
                            <td class="btn-light " *ngIf="work_sums[(target.ymd | date:'d')]">
                                <input style="width: 60%;text-align: right;" [class.bg-danger]="capacity_sums[(target.d)]['capa_all']>=30" type="number" [(ngModel)]="capacity_sums[(target.d)]['capa_all']" pattern="[0-9]+" (change)="onChangeCapa(capacity_sums[(target.d)])">
                            </td>
                            <td class="btn-light " *ngIf="capacity_sums[(target.ymd | date:'d')]">
                                <input style="width: 60%;text-align: right;" [class.bg-danger]="capacity_sums[(target.d)]['capa_caddy']>=30" type="number" [(ngModel)]="capacity_sums[(target.d)]['capa_caddy']" pattern="[0-9]+" (change)="onChangeCapa(capacity_sums[(target.d)])">
                            </td>
                    </tr>
                </tbody>
            </table>
<!--
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" [class.btn-primary]="week.week_cd=='Sat'" [class.btn-danger]="week.week_cd=='Sun'" *ngFor="let week of weeks">{{week.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let targets of calendar">
                        <td style="border-color: black;text-align: center;background-color: black;" *ngFor="let target of targets">
                            <div class="w-100" [class.btn-secondary]="target.week_cd!='Sat'&&target.week_cd!='Sun'" [class.btn-primary]="target.week_cd=='Sat'" [class.btn-danger]="target.week_cd=='Sun'" >{{target.ymd | date:'dd'}}</div>
                            <div class="btn-light w-100" *ngFor="let item of attendances[golfwork_id][(target.ymd | date:'d')]">{{item['name']}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
-->
        </div>
    </div>
    <div class="col-12 col-xl-6">
        <div class="table-responsive-lg">
            <label>絞込【依頼日】</label><wj-combo-box id="vpDd" name="vpDd" [(ngModel)]="pDd" #vpDd="ngModel" [itemsSource]="dds" [isEditable]="false"></wj-combo-box>
            <table style="width: 100%;" class="table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="bg-info" colspan="9" style="width: 14%;vertical-align: middle;border-color: black;text-align: center;" scope="col" >依頼一覧</th>
                    </tr>
                    <tr>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            受付日
                        </th>
                        <th class="btn-secondary" style="width: 10%;vertical-align: middle;border-color: black;text-align: center;">
                            依頼日
                        </th>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                            種類
                        </th>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                            依頼数
                        </th>
                        <th class="btn-secondary" style="width: 20%;vertical-align: middle;border-color: black;text-align: center;">
                            備考
                        </th>
                        <th class="btn-secondary" style="width: 5%;vertical-align: middle;border-color: black;text-align: center;">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let target of requests | filter:'dd': pDd;">
                        <td style="border-color: black;text-align: center;">
                            {{target.modified}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.yyyymm.slice(0,4)}}/{{target.yyyymm.slice(4)}}/{{target.dd}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{type_cds[target.type_cd]}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.cnt}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            {{target.remarks}}
                        </td>
                        <td style="border-color: black;text-align: center;">
                            <button class="btn btn-sm btn-danger" [disabled]="cur_user_id!=target.user_id" (click)="delete(target)"><span class="oi oi-trash"></span>削除</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
