import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { WjGridModule } from '@grapecity/wijmo.angular2legacy.grid';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2legacy.grid.filter';
import { WjInputModule } from '@grapecity/wijmo.angular2legacy.input';
import { WjNavModule, WjTreeView } from '@grapecity/wijmo.angular2legacy.nav';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AuthenticationService } from './service/authentication.service';
import { UtilityService } from './service/utility.service';
import { AlertService } from './service/alert.service';
import { AclGuard } from './guard/acl.guard';

import { AlertComponent } from './alert/alert.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalWaitComponent } from './modal-wait/modal-wait.component';
import { PreviewComponent } from './preview/preview.component';
import { GolfListComponent } from './golf-list/golf-list.component';
import { GolfModComponent } from './golf-mod/golf-mod.component';

import * as wjcCore from '@grapecity/wijmo';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { AttendanceDetailComponent } from './attendance-detail/attendance-detail.component';
import { RequestListComponent } from './request-list/request-list.component';
import { ListFilterPipe } from './list-filter.pipe';
import { GolfworkModComponent } from './golfwork-mod/golfwork-mod.component';
import { RequestViewComponent } from './request-view/request-view.component';
import { RequestModComponent } from './request-mod/request-mod.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeModComponent } from './employee-mod/employee-mod.component';
import { AttendanceViewComponent } from './attendance-view/attendance-view.component';
import { AttendanceModComponent } from './attendance-mod/attendance-mod.component';
import { PasschgComponent } from './passchg/passchg.component';
import { EmployeeSelectComponent } from './employee-select/employee-select.component';
import { UserService } from './service/user.service';
import { MemoModComponent } from './memo-mod/memo-mod.component';
import { GolfEmployeeListComponent } from './golf-employee-list/golf-employee-list.component';
// ライセンス設定
wjcCore.setLicenseKey('localhost|pre.g1japan.com|pre.g1japan.jp|system.g1japan.com|system.g1japan.jp|systest.g1japan.com,545696511864537#B0jDtCLiczM5QjN8ETM5YTO6UDN5IiOiQWSiwSfdtlOicGbmJCLiIjdzIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBlldl9EOwYESwsWNzNkQZFzSlZkRQp5YYhDcuJTWoVEUxMnUwQXTvJjSOJkRYNDOtxmNrMnayEFO4gUUtVnQ05kdx9GSRhjd62GMGdlQUtUTkVmTzYndHFDSaB7Q4VUaYZUUxxEZGF7NjdWUrljeENnauZ5KPNWb7QzSS5EcMBjQPJ6VyVXdyhWS9sEStJWQXJVZqxWavEzRTNENmFXerZTUpFjMpVVZnVWUEFWQJZzNyMURXllMMhEMkF6Q6kDRiBlc5lVbQd6bzATRrVDRxVERSZUcRNlT9YmY7FXeyYFNIl4UrpWQaJ5QBtWdYpUbWBTVJh4T8R7NJFWd03WOWVVajZkZ8YUU9UDOaBXZUtWTyV6dOBlTHNUY7FGUIBTS9dDMk3WZYtCRFlzcQVlRuZWatlkSr2UZ6k4dygGWYVFbDVmWidmeGt6ajlEcJlkbHJWOrplZiojITJCLiATQ6ETMFR4NiojIIJCL5ITN6MTO5czN0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIyQzMyIDMgATMxADNyAjMiojI4J7QiwiIt36Yu8WYwFmaxcmL4NXZ4NXezxCcq9ibhBXYqFzZu4WZ4NXezxSbvNmLuFGchpWMn9SblR7c9NHLwpmLuFGchpWMn9SZyBHLt36Yu8WYwFmaxcmLlJHcsQ7cvhGbhN6bsJiOiMXbEJCLiorgjD0gjb8gjnrgjfrgjb9gjPqgjb8gj/qgjn0gjj8gjLqgj1LpnrJvk/IvlrKomLieiom');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    ModalConfirmComponent,
    LogoutComponent,
    GolfListComponent,
    GolfModComponent,
    AttendanceListComponent,
    AttendanceDetailComponent,
    RequestListComponent,
    ListFilterPipe,
    GolfworkModComponent,
    RequestViewComponent,
    RequestModComponent,
    EmployeeListComponent,
    EmployeeModComponent,
    AttendanceViewComponent,
    AttendanceModComponent,
    PasschgComponent,
    EmployeeSelectComponent,
    MemoModComponent,
    GolfEmployeeListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    WjGridModule,
    WjGridFilterModule,
    WjNavModule,
    WjInputModule,
    NgbModule,
    DragDropModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    AclGuard,
    AuthenticationService,
    UserService,
    UtilityService,
    AlertService,
    // ng build --output-path=./dist/shift --base-href=/shift/ --output-hashing=all
    // ng build --output-hashing=all
    // {provide: 'ApiUrl', useValue: 'http://localhost:8080/'},
    // {provide: 'ApiUrl', useValue: 'https://pre.g1japan.jp/'},
    {provide: 'ApiUrl', useValue: 'https://pre.g1japan.com/'},
  ],
  entryComponents: [ModalConfirmComponent, ModalWaitComponent, PreviewComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
